import {
  REQUEST_CABINES, RECEIVE_CABINES, REQUEST_ALL_CABINES, RECEIVE_ALL_CABINES, REQUEST_CABINE, RECEIVE_CABINE,
  RESET_ALL_CABINE, RESET_CABINE, REQUEST_SAVE_CABINE, RECEIVE_SAVE_CABINE
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItem: {
    isLoading: false,
    receivedAt: null,
  },
}

export default function TecnicoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_CABINES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_CABINES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ALL_CABINES:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_CABINES:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CABINE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_CABINE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CABINE:
    case RECEIVE_SAVE_CABINE:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_CABINE:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_CABINE:
      return INITIAL_STATE
    default:
      return state
  }
}
