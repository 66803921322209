import {
  REQUEST_EMPRESAS, RECEIVE_EMPRESAS, REQUEST_SAVE_EMPRESA, RECEIVE_SAVE_EMPRESA, RESET_ALL_EMPRESA
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
}

export default function EmpresaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_EMPRESAS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_EMPRESAS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_EMPRESA:
    case RECEIVE_SAVE_EMPRESA:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_EMPRESA:
      return INITIAL_STATE
    default:
      return state
  }
}
