// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_TELECONSULTAS, RECEIVE_TELECONSULTAS, REQUEST_TELECONSULTA, RECEIVE_TELECONSULTA, REQUEST_SAVE_TELECONSULTA,
  RECEIVE_SAVE_TELECONSULTA, START_TELECONSULTA, FINISH_TELECONSULTA, RESET_TELECONSULTA, REQUEST_PACIENTE_TEM_RETORNO,
  RECEIVE_PACIENTE_TEM_RETORNO, RESET_PACIENTE_TEM_RETORNO, REQUEST_SAVE_NOTA_MEDICA, RECEIVE_SAVE_NOTA_MEDICA,
  REQUEST_TELECONSULTAS_CHART, RECEIVE_TELECONSULTAS_CHART, REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO,
  RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO, REQUEST_TELECONSULTA_CALL_LEGS, RECEIVE_TELECONSULTA_CALL_LEGS,
  REQUEST_TELECONSULTA_CONFIRMATIONS, RECEIVE_TELECONSULTA_CONFIRMATIONS, REQUEST_SAVE_REEMBOLSO,
  RECEIVE_SAVE_REEMBOLSO, REQUEST_CONFIRM_PRESENCE, RECEIVE_CONFIRM_PRESENCE, REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS,
  RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS, REQUEST_LINK_RES_UNIMED, RECEIVE_LINK_RES_UNIMED, RESET_LINK_RES_UNIMED,
  REQUEST_ALL_TELECONSULTA_TIPO, RECEIVE_ALL_TELECONSULTA_TIPO, REQUEST_DEVOLVER_PLANTAO_FILA,
  RECEIVE_DEVOLVER_PLANTAO_FILA, REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION,
  RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION, RESET_ALL_TELECONSULTA
} from '../constants/ActionTypes'
// actions
import { fetchEntityInformacoesAdicionais } from './AuthActions'
import { addToast } from './ToastActions'
import { showRequestError } from './AppActions'

function requestTeleconsultas(silent) {
  return {
    type: REQUEST_TELECONSULTAS.type,
    isLoading: !silent,
    isSilent: silent,
  }
}

function receiveTeleconsultas(data) {
  return {
    type: RECEIVE_TELECONSULTAS.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultas(params = { page: 1, limit: 50 }, silent = false, callback = () => {}) {
  return (dispatch, getState) => {
    let state = getState()
    let appOnline = state.app.appOnline
    if (appOnline) {
      dispatch(requestTeleconsultas(silent))
      request.get(`${API_HOST}/teleconsulta`, params).then(json => {
        dispatch(receiveTeleconsultas(json))
        callback(true)
      }).catch(err => {
        if (!silent) dispatch(showRequestError(err))
        callback(false)
      })
    } else {
      console.log('DISCARD fetchTeleconsultas')
    }
  }
}

export function fetchTeleconsultasCSVInfo(callback = () => {}) {
  return dispatch => {
    request.get(`${API_HOST}/teleconsulta/export/csv-info`).then(json => {
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function fetchTeleconsultasExportCSV(params, callback = () => {}) {
  return dispatch => {
    request.get(`${API_HOST}/teleconsulta/export/csv`, params).then(json => {
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Abre nova guia com nota para impressão.
 * Não altera estado da aplicação.
 *
 * @param {Number} idNfse
 */
export function fetchDownloadExportCSV() {
  return (dispatch, getState) => {
    let state = getState()
    let jwt = state.auth.jwt
    let url = `${API_HOST}/teleconsulta/export/csv-download?jwt=${jwt}`
    window.open(url, '_blank')
    dispatch(addToast('success', 'Download iniciado.', null, true))
  }
}

function requestTeleconsulta() {
  return {
    type: REQUEST_TELECONSULTA.type,
    isLoading: true,
  }
}

function receiveTeleconsulta(data) {
  return {
    type: RECEIVE_TELECONSULTA.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsulta(id, callback = () => {}) {
  return async dispatch => {
    dispatch(requestTeleconsulta())
    request.get(`${API_HOST}/teleconsulta/${id}`).then(json => {
      dispatch(receiveTeleconsulta(json))
      callback(json)
    }).catch(err => {
      dispatch(receiveTeleconsulta())
      dispatch(showRequestError(err))
      callback()
    })
  }
}

function requestSaveTeleconsulta() {
  return {
    type: REQUEST_SAVE_TELECONSULTA.type,
    isLoading: true,
  }
}

function receiveSaveTeleconsulta() {
  return {
    type: RECEIVE_SAVE_TELECONSULTA.type,
    isLoading: false,
  }
}

export function saveTeleconsulta(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveTeleconsulta())
    const url = (data.id)
      ? `${API_HOST}/teleconsulta/${data.id}`
      : `${API_HOST}/teleconsulta`
    const method = (data.id) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data)).then(() => {
      dispatch(receiveSaveTeleconsulta())
      dispatch(addToast('success', 'Teleconsulta salva com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

function requestDevolverPlantaoFila() {
  return {
    type: REQUEST_DEVOLVER_PLANTAO_FILA,
    isLoading: true,
  }
}

function receiveDevolverPlantaoFila() {
  return {
    type: RECEIVE_DEVOLVER_PLANTAO_FILA,
    isLoading: false,
  }
}

/**
 * Devolve plantão unico para a fila.
 *
 * @param {Object} data
 * @param {Function} callback
 */
export function saveDevolverPlantaoFila(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestDevolverPlantaoFila())
    const url = `${API_HOST}/teleconsulta/${data.id}/plantao-unico/devolver-fila`
    request.put(url, {}, JSON.stringify(data)).then(() => {
      dispatch(receiveDevolverPlantaoFila())
      dispatch(addToast('success', 'Devolvido para fila com sucesso.', null, true))
      callback()
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveDevolverPlantaoFila())
      callback(err)
    })
  }
}

function requestSaveReembolso() {
  return {
    type: REQUEST_SAVE_REEMBOLSO.type,
    isLoading: true,
  }
}

function receiveSaveReembolso() {
  return {
    type: RECEIVE_SAVE_REEMBOLSO.type,
    isLoading: false,
  }
}

/**
 * Persiste detalhes do reembolso na teleconsulta e altera status pagamento para 5 (Reembolsado)
 *
 * @param {Object} data
 * @param {Function} callback
 */
export function saveReembolso(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveReembolso())
    const url = `${API_HOST}/teleconsulta/${data.id}/refound`
    request.put(url, {}, JSON.stringify(data)).then(() => {
      dispatch(receiveSaveReembolso())
      dispatch(addToast('success', 'Reembolso informado com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveSaveReembolso())
      callback(false)
    })
  }
}

function requestConfirmPresence() {
  return {
    type: REQUEST_CONFIRM_PRESENCE.type,
    isLoading: true,
  }
}

function receiveConfirmPresence() {
  return {
    type: RECEIVE_CONFIRM_PRESENCE.type,
    isLoading: false,
  }
}

/**
 * Medico confirma presença em determinada consulta pendente.
 *
 * @param {Number} idTeleconsulta
 * @param {Function} callback
 */
export function medicoConfirmPresence(idTeleconsulta, callback = () => {}) {
  return dispatch => {
    dispatch(requestConfirmPresence())
    const url = `${API_HOST}/teleconsulta/${idTeleconsulta}/confirm-presence`
    request.post(url).then(() => {
      dispatch(receiveConfirmPresence())
      dispatch(addToast('success', 'Presença confirmada.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveConfirmPresence())
      callback(false)
    })
  }
}

/**
 * Confirma presença / chegada do paciente na clinica em atendimentos presenciais.
 *
 * @param {Number} idTeleconsulta
 * @param {Function} callback
 */
export function checkinPaciente(idTeleconsulta, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/teleconsulta/${idTeleconsulta}/checkin-paciente`
    request.post(url).then(() => {
      dispatch(addToast('success', 'Presença confirmada.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

function startTeleconsulta(idTeleconsulta) {
  return {
    type: START_TELECONSULTA.type,
    idTeleconsulta,
  }
}

export function finishTeleconsulta() {
  return {
    type: FINISH_TELECONSULTA.type,
  }
}

export function fetchIniciarTeleconsulta(idTeleconsulta, callback = () => {}) {
  return dispatch => {
    // dispatch(resetAllExame())
    // dispatch(resetAllTeleconsulta())
    const url = `${API_HOST}/teleconsulta/${idTeleconsulta}/iniciar`
    request.put(url, {}, '').then(json => {
      dispatch(startTeleconsulta(idTeleconsulta))
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        setTimeout(() => {
          window.router.push(`/teleconsulta/${idTeleconsulta}/consultar`)
        }, 200)
      }
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(fetchEntityInformacoesAdicionais())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push('/')
      }
      callback(false)
    })
  }
}

export function fetchPrintAnexo(type, idAnexo) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/teleconsulta/${type}/${idAnexo}/pdf?jwt=${jwt}`
    window.open(url, '_blank')
  }
}

export function fetchCallLinkMedico(idTeleconsulta, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/call/link-medico`
    request.get(url).then(json => {
      let jwt = getState().auth.jwt
      callback({
        link: json.link, //+ '&jwt=' + jwt,
        jwt // de autenticação (quando médico é verificado se está autenticado para não compartilhar link com o paciente)
      })
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

export function fetchCallLinkPaciente(idTeleconsulta, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/call/link-paciente`
    request.get(url).then(json => {
      let jwt = getState().auth.jwt
      callback({
        link: json.link, //+ '&jwt=' + jwt,
        jwt // de autenticação (quando médico é verificado se está autenticado para não compartilhar link com o paciente)
      })
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

export function fetchCallInvite(idTeleconsulta, email, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/call-invite`
    request.post(url, {}, JSON.stringify({ email })).then(json => {
      dispatch(addToast('success', `Convite enviado para ${email}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

export function fetchCallInviteWhatsapp(idTeleconsulta, phone, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/call-invite-whatsapp`
    request.post(url, {}, JSON.stringify({ phone })).then(json => {
      dispatch(addToast('success', `Convite enviado para ${phone}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

export function fetchCallInviteSMS(idTeleconsulta, phone, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/call-invite-sms`
    request.post(url, {}, JSON.stringify({ phone })).then(json => {
      dispatch(addToast('success', `Convite enviado para ${phone}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

/**
 * Obtem link para pagamento (apenas aguardando pagamento e meio_pagamento='pagarme_link')
 */
export function fetchPayLinkPaciente(idTeleconsulta, callback) {
  return (dispatch, getState) => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/pay/link-paciente`
    request.get(url).then(json => {
      let jwt = getState().auth.jwt
      callback({
        link: json.link, //+ '&jwt=' + jwt,
        jwt // de autenticação (quando médico é verificado se está autenticado para não compartilhar link com o paciente)
      })
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

export function fetchPayLinkPacienteSendMail(idTeleconsulta, email) {
  return dispatch => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/pay/link-send-mail`
    request.post(url, {}, JSON.stringify({ email })).then(json => {
      dispatch(addToast('success', `Link enviado para ${email}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

export function fetchPayLinkPacienteSendWhatsapp(idTeleconsulta, phone) {
  return dispatch => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/pay/link-send-whatsapp`
    request.post(url, {}, JSON.stringify({ phone })).then(json => {
      dispatch(addToast('success', `Link enviado para ${phone}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

export function fetchPayLinkPacienteSendSMS(idTeleconsulta, phone) {
  return dispatch => {
    let url = `${API_HOST}/teleconsulta/${idTeleconsulta}/pay/link-send-sms`
    request.post(url, {}, JSON.stringify({ phone })).then(json => {
      dispatch(addToast('success', `Link enviado para ${phone}`, null, true))
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
    })
  }
}

function requestPacienteTemRetorno() {
  return {
    type: REQUEST_PACIENTE_TEM_RETORNO.type,
    isLoading: true,
  }
}

function receivePacienteTemRetorno(data) {
  return {
    type: RECEIVE_PACIENTE_TEM_RETORNO.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Verifica se paciente tem direito a retorno com um determinado médico em data
 * pretendida para agendamento.
 *
 * @param {Object} data { id_paciente, id_medico, agendamento }
 */
export function fetchPacienteTemRetorno(data) {
  return async dispatch => {
    dispatch(requestPacienteTemRetorno())
    request.post(`${API_HOST}/teleconsulta/paciente-tem-retorno`, {}, JSON.stringify(data)).then(json => {
      dispatch(receivePacienteTemRetorno(json.data))
    }).catch(err => {
      dispatch(receivePacienteTemRetorno(null))
      dispatch(showRequestError(err))
    })
  }
}

export function fetchSolicitarNotaMedica(idTeleconsulta, callback = () => {}) {
  return async dispatch => {
    dispatch(requestPacienteTemRetorno())
    request.put(`${API_HOST}/teleconsulta/${idTeleconsulta}/solicitar-nota-medica`).then(json => {
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestSaveNotaMedica() {
  return {
    type: REQUEST_SAVE_NOTA_MEDICA.type,
    isLoading: true,
  }
}

function receiveSaveNotaMedica() {
  return {
    type: RECEIVE_SAVE_NOTA_MEDICA.type,
    isLoading: false,
  }
}

export function fetchSaveNotaMedicaPaciente(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveNotaMedica())
    const url = `${API_HOST}/teleconsulta/${data.id}/nota-medica`
    request.post(url, {}, JSON.stringify(data)).then(() => {
      dispatch(receiveSaveNotaMedica())
      dispatch(addToast('success', 'Nota enviada com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(false)
    })
  }
}

export function resetPacienteTemRetorno() {
  return {
    type: RESET_PACIENTE_TEM_RETORNO.type,
  }
}

export function resetTeleconsulta() {
  return {
    type: RESET_TELECONSULTA.type,
  }
}

function requestTeleconsultasChart() {
  return {
    type: REQUEST_TELECONSULTAS_CHART.type,
    isLoading: true,
  }
}

function receiveTeleconsultasChart(data) {
  return {
    type: RECEIVE_TELECONSULTAS_CHART.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultasChart(idEspecialidade) {
  return async dispatch => {
    dispatch(requestTeleconsultasChart())
    request.get(`${API_HOST}/teleconsulta/chart/${idEspecialidade}`).then(json => {
      dispatch(receiveTeleconsultasChart(json))
    }).catch(err => {
      dispatch(receiveTeleconsultasChart())
      dispatch(showRequestError(err))
    })
  }
}

function requestTeleconsultasTempoAtendimento() {
  return {
    type: REQUEST_TELECONSULTAS_TEMPO_ATENDIMENTO.type,
    isLoading: true,
  }
}

function receiveTeleconsultasTempoAtendimento(data) {
  return {
    type: RECEIVE_TELECONSULTAS_TEMPO_ATENDIMENTO.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultasTempoAtendimento(days, idEspecialidade) {
  return async dispatch => {
    dispatch(requestTeleconsultasTempoAtendimento())
    request.get(`${API_HOST}/teleconsulta/tempo-atendimento/${days}/${idEspecialidade}`).then(json => {
      dispatch(receiveTeleconsultasTempoAtendimento(json))
    }).catch(err => {
      dispatch(receiveTeleconsultasTempoAtendimento())
      dispatch(showRequestError(err))
    })
  }
}

function requestTeleconsultaCallLegs() {
  return {
    type: REQUEST_TELECONSULTA_CALL_LEGS.type,
    isLoading: true,
    data: [],
    receivedAt: null,
  }
}

function receiveTeleconsultaCallLegs(data) {
  return {
    type: RECEIVE_TELECONSULTA_CALL_LEGS.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultaCallLegs(idTeleconsulta) {
  return async dispatch => {
    dispatch(requestTeleconsultaCallLegs())
    request.get(`${API_HOST}/teleconsulta/${idTeleconsulta}/call/legs`).then(json => {
      dispatch(receiveTeleconsultaCallLegs(json))
    }).catch(err => {
      dispatch(receiveTeleconsultaCallLegs([]))
      dispatch(showRequestError(err))
    })
  }
}

function requestTeleconsultaCallLegsHasIntersection() {
  return {
    type: REQUEST_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION,
    isLoading: true,
    data: {},
    receivedAt: null,
  }
}

function receiveTeleconsultaCallLegsHasIntersection(data) {
  return {
    type: RECEIVE_TELECONSULTA_CALL_LEGS_HAS_INTERSECTION,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultaCallLegsHasIntersection(idTeleconsulta, callback = () => {}) {
  return async dispatch => {
    dispatch(requestTeleconsultaCallLegsHasIntersection())
    request.get(`${API_HOST}/teleconsulta/${idTeleconsulta}/call/legs-has-intersection`).then(json => {
      dispatch(receiveTeleconsultaCallLegsHasIntersection(json))
      callback(null, json)
    }).catch(err => {
      dispatch(receiveTeleconsultaCallLegsHasIntersection([]))
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestTeleconsultaConfirmations() {
  return {
    type: REQUEST_TELECONSULTA_CONFIRMATIONS.type,
    isLoading: true,
    data: [],
    receivedAt: null,
  }
}

function receiveTeleconsultaConfirmations(data) {
  return {
    type: RECEIVE_TELECONSULTA_CONFIRMATIONS.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchTeleconsultaConfirmations(idTeleconsulta) {
  return async dispatch => {
    dispatch(requestTeleconsultaConfirmations())
    request.get(`${API_HOST}/teleconsulta/${idTeleconsulta}/confirmations`).then(json => {
      console.log('JSON', json)
      dispatch(receiveTeleconsultaConfirmations(json))
    }).catch(err => {
      dispatch(receiveTeleconsultaConfirmations([]))
      dispatch(showRequestError(err))
    })
  }
}

function requestSaveTeleconsultaProcedimentos() {
  return {
    type: REQUEST_SAVE_TELECONSULTA_PROCEDIMENTOS,
    isLoading: true,
  }
}

function receiveSaveTeleconsultaProcedimentos() {
  return {
    type: RECEIVE_SAVE_TELECONSULTA_PROCEDIMENTOS,
    isLoading: false,
  }
}

/**
 * Persiste lista de procedimentos. Sempre PUT pois a criação inicial é no
 * agendamento.
 */
 export function saveTeleconsultaProcedimentos(idTeleconsulta, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveTeleconsultaProcedimentos())
    const url = `${API_HOST}/teleconsulta/${idTeleconsulta}/procedimentos`
    request.put(url, {}, JSON.stringify(data)).then(() => {
      dispatch(receiveSaveTeleconsultaProcedimentos())
      dispatch(addToast('success', 'Procedimentos salvos com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveSaveTeleconsultaProcedimentos())
      callback(false)
    })
  }
}

function requestLinkRESUnimed() {
  return {
    type: REQUEST_LINK_RES_UNIMED,
    isLoading: true,
  }
}

function receiveLinkRESUnimed(data) {
  return {
    type: RECEIVE_LINK_RES_UNIMED,
    isLoading: false,
    data
  }
}

/**
 * Obtem link de acesso ao RES do paciente Unimed Ceara.
 */
 export function fetchLinkRESUnimed(data, callback = () => {}) {
  return dispatch => {
    dispatch(requestLinkRESUnimed())
    const url = `${API_HOST}/teleconsulta/link-res-unimed-ceara`
    request.post(url, {}, JSON.stringify(data)).then((json) => {
      dispatch(receiveLinkRESUnimed(json))
      callback(null, json)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      dispatch(receiveLinkRESUnimed())
      callback(err)
    })
  }
}

export function resetLinkRESUnimed() {
  return {
    type: RESET_LINK_RES_UNIMED,
  }
}

function requestAllTeleconsultaTipo() {
  return {
    type: REQUEST_ALL_TELECONSULTA_TIPO,
    isLoading: true,
  }
}

function receiveAllTeleconsultaTipo(data) {
  return {
    type: RECEIVE_ALL_TELECONSULTA_TIPO,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAllTeleconsultaTipo(callback = () => {}) {
  return (dispatch, getState) => {
    const isLoaded = (getState().teleconsulta.allTipo.receivedAt !== null)
    // carrega apenas uma vez
    if (isLoaded) return callback(true)
    dispatch(requestAllTeleconsultaTipo())
    request.get(`${API_HOST}/teleconsulta/all-tipo`).then(json => {
      dispatch(receiveAllTeleconsultaTipo(json))
      callback(true)
    }).catch(err => {
      dispatch(receiveAllTeleconsultaTipo())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

export function resetAllTeleconsulta() {
  return {
    type: RESET_ALL_TELECONSULTA.type,
  }
}
