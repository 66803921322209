// constants
import { REQUEST_APP } from '../constants/ActionTypes'
import { API_HOST } from '../constants/EnvTypes'
// actions
import { fetchApp } from '../actions/AppActions'
// import { fetchTelemedicina } from '../actions/AuthActions'

/**
 * Middleware responsável por realizar o despacho de requisição de recursos globais
 * da aplicação caso o login ja tenha sido realizado e os dados ainda não tenham sido carregados.
 */
export const bootstrap = ({dispatch, getState}) => next => action => {

  let state = getState()

  // carrega telemedicina primeira vez
  // ---------------------------------------------------------------------------
  if (action.type === 'RECEIVE_TELEMEDICINA') {
    const telemedicina = action.data
    if (telemedicina && Object.keys(telemedicina).length) {
      const root = document.documentElement
      root.style.setProperty("--md-primary-color", telemedicina.theme_color_primary)
      // root.style.setProperty("--md-secondary-color", telemedicina.theme_color_secondary)
      // root.style.setProperty("--bg-color-menu", telemedicina.theme_bg_menu)
      // root.style.setProperty("--color-menu", telemedicina.theme_color_menu)
      document.title = telemedicina.nome

      // cria favicon
      var headID = document.getElementsByTagName('head')[0]
      var link = document.createElement('link')
      link.rel = 'shortcut icon'
      link.sizes = '32x32'
      headID.appendChild(link)
      link.href = `${API_HOST}/cdn/favicons/${telemedicina.theme_favicon}?v=1.0.0`
    }
  }

  // carrega tudo para aplicação funcionar
  // ---------------------------------------------------------------------------
  let isLogged = state.auth.isLogged
  // let idUser = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.entityId
  let loginType = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.type
  let loginProfile = (Object.keys(state.auth.jwtPayload).length === 0) ? null : state.auth.jwtPayload.user.profile
  let isAppDispatched = state.app.dispatched

  if (isLogged && /*idUser &&*/ ! isAppDispatched && action && 'type' in action && action.type != REQUEST_APP) {
    dispatch(fetchApp(loginType, loginProfile))
  }

  next(action)
}