import {
  REQUEST_TEMPLATE_PRONTUARIO_MEDICO, RECEIVE_TEMPLATE_PRONTUARIO_MEDICO, REQUEST_SAVE_PRONTUARIO_SECTION_INFO,
  RECEIVE_SAVE_PRONTUARIO_SECTION_INFO, REQUEST_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO,
  RECEIVE_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO, REQUEST_SAVE_PRONTUARIO_PRESCRICAO, RECEIVE_SAVE_PRONTUARIO_PRESCRICAO,
  REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO, RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO,
  REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES, RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES,
  REQUEST_SAVE_PRONTUARIO_ANEXOS, RECEIVE_SAVE_PRONTUARIO_ANEXOS, REQUEST_DOCUMENTO_ATESTADO_MODELOS,
  REQUEST_DOCUMENTO_ORIENTACOES_MODELOS, REQUEST_REMOVE_PRONTUARIO_ANEXOS, RECEIVE_REMOVE_PRONTUARIO_ANEXOS,
  RECEIVE_DOCUMENTO_ATESTADO_MODELOS, REQUEST_DOCUMENTO_ATESTADO_MODELO, RECEIVE_DOCUMENTO_ORIENTACOES_MODELOS,
  REQUEST_DOCUMENTO_ORIENTACOES_MODELO, RECEIVE_DOCUMENTO_ATESTADO_MODELO, RECEIVE_DOCUMENTO_ORIENTACOES_MODELO,
  RESET_DOCUMENTO_ATESTADO_MODELO, RESET_DOCUMENTO_ORIENTACOES_MODELO, REQUEST_SAVE_DOCUMENTO_ATESTADO_MODELO,
  REQUEST_SAVE_DOCUMENTO_ORIENTACOES_MODELO, RECEIVE_SAVE_DOCUMENTO_ATESTADO_MODELO,
  RECEIVE_SAVE_DOCUMENTO_ORIENTACOES_MODELO, REQUEST_ALL_DOCUMENTO_ATESTADO_MODELO,
  REQUEST_ALL_DOCUMENTO_ORIENTACOES_MODELO, RECEIVE_ALL_DOCUMENTO_ATESTADO_MODELO,
  RECEIVE_ALL_DOCUMENTO_ORIENTACOES_MODELO, REQUEST_PRESCRICAO_MODELOS, RECEIVE_PRESCRICAO_MODELOS,
  REQUEST_PRESCRICAO_MODELO, RECEIVE_PRESCRICAO_MODELO, RESET_PRESCRICAO_MODELO, REQUEST_SAVE_PRESCRICAO_MODELO,
  RECEIVE_SAVE_PRESCRICAO_MODELO, REQUEST_ALL_PRESCRICAO_MODELO, RECEIVE_ALL_PRESCRICAO_MODELO,
  REQUEST_EXAME_PROCEDIMENTO_MODELOS, RECEIVE_EXAME_PROCEDIMENTO_MODELOS, REQUEST_EXAME_PROCEDIMENTO_MODELO,
  RECEIVE_EXAME_PROCEDIMENTO_MODELO, RESET_EXAME_PROCEDIMENTO_MODELO, REQUEST_SAVE_EXAME_PROCEDIMENTO_MODELO,
  RECEIVE_SAVE_EXAME_PROCEDIMENTO_MODELO, REQUEST_ALL_EXAME_PROCEDIMENTO_MODELO, RECEIVE_ALL_EXAME_PROCEDIMENTO_MODELO,
  REQUEST_SECAO_GRUPOS, RECEIVE_SECAO_GRUPOS, REQUEST_SECAO_GRUPO, RECEIVE_SECAO_GRUPO, REQUEST_REMOVE_SECAO_GRUPO,
  RECEIVE_REMOVE_SECAO_GRUPO, REQUEST_COPY_SECAO_GRUPO, RECEIVE_COPY_SECAO_GRUPO, REQUEST_SAVE_SECAO_GRUPO,
  RECEIVE_SAVE_SECAO_GRUPO, REQUEST_SAVE_PRONTUARIO_ASO_ANAMNESE, RECEIVE_SAVE_PRONTUARIO_ASO_ANAMNESE,
  REQUEST_SAVE_PRONTUARIO_ASO, RECEIVE_SAVE_PRONTUARIO_ASO, RESET_ALL_PRONTUARIO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  template: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveSectionInfo: {
    isLoading: false,
  },
  saveExameProcedimento: {
    isLoading: false,
  },
  savePrescricao: {
    isLoading: false,
  },
  saveDocumentoAtestado: {
    isLoading: false,
  },
  saveDocumentoOrientacoes: {
    isLoading: false,
  },
  saveAnexos: {
    isLoading: false,
  },
  removeAnexos: {
    isLoading: false,
  },
  // modelo documento_atestado
  docuentoAtestadoModelos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  docuentoAtestadoModelo: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveDocuentoAtestadoModelo: {
    isLoading: false,
  },
  allDocuentoAtestadoModelo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  // modelo documento_orientacoes
  docuentoOrientacoesModelos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  docuentoOrientacoesModelo: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveDocuentoOrientacoesModelo: {
    isLoading: false,
  },
  allDocuentoOrientacoesModelo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  // modelos prescricao
  prescricaoModelos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  prescricaoModelo: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  savePrescricaoModelo: {
    isLoading: false,
  },
  allPrescricaoModelo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  // modelos exame_procedimento
  exameProcedimentoModelos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  exameProcedimentoModelo: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveExameProcedimentoModelo: {
    isLoading: false,
  },
  allExameProcedimentoModelo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  // prontuario grupos, seções e campos
  listSecaoGrupos: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemSecaoGrupo: {
    isLoading: false,
    data: {},
  },
  removeSecaoGrupo: {
    isLoading: false,
  },
  copySecaoGrupo: {
    isLoading: false,
  },
  saveSecaoGrupo: {
    isLoading: false,
  },
  // aso anamnese
  saveAsoAnamnese: {
    isLoading: false,
  },
  // aso
  saveAso: {
    isLoading: false,
  },
}

export default function TeleconsultaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_TEMPLATE_PRONTUARIO_MEDICO.type:
      return {
        ...state,
        itemplatetem: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.template.data,
        }
      }
    case RECEIVE_TEMPLATE_PRONTUARIO_MEDICO.type:
      return {
        ...state,
        template: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_SECTION_INFO.type:
    case RECEIVE_SAVE_PRONTUARIO_SECTION_INFO.type:
      return {
        ...state,
        saveSectionInfo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO.type:
    case RECEIVE_SAVE_PRONTUARIO_EXAME_PROCEDIMENTO.type:
      return {
        ...state,
        saveExameProcedimento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_PRESCRICAO.type:
    case RECEIVE_SAVE_PRONTUARIO_PRESCRICAO.type:
      return {
        ...state,
        savePrescricao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO.type:
    case RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ATESTADO.type:
      return {
        ...state,
        saveDocumentoAtestado: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES.type:
    case RECEIVE_SAVE_PRONTUARIO_DOCUMENTO_ORIENTACOES.type:
      return {
        ...state,
        saveDocumentoAtestado: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_ANEXOS.type:
    case RECEIVE_SAVE_PRONTUARIO_ANEXOS.type:
      return {
        ...state,
        saveAnexos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_PRONTUARIO_ANEXOS.type:
    case RECEIVE_REMOVE_PRONTUARIO_ANEXOS.type:
      return {
        ...state,
        removeAnexos: {
          isLoading: action.isLoading,
        }
      }
    // modelo documento_atestado
    // -------------------------------------------------------------------------
    case REQUEST_DOCUMENTO_ATESTADO_MODELOS.type:
      return {
        ...state,
        docuentoAtestadoModelos: {
          isLoading: action.isLoading,
          params: action.params,
          data: INITIAL_STATE.docuentoAtestadoModelos.data,
        }
      }
    case RECEIVE_DOCUMENTO_ATESTADO_MODELOS.type:
      return {
        ...state,
        docuentoAtestadoModelos: {
          isLoading: action.isLoading,
          params: state.docuentoAtestadoModelos.params,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        docuentoAtestadoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.docuentoAtestadoModelo.data,
        }
      }
    case RECEIVE_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        docuentoAtestadoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        docuentoAtestadoModelo: INITIAL_STATE.docuentoAtestadoModelo
      }
    case REQUEST_SAVE_DOCUMENTO_ATESTADO_MODELO.type:
    case RECEIVE_SAVE_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        saveDocuentoAtestadoModelo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        allDocuentoAtestadoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allDocuentoAtestadoModelo.data,
        }
      }
    case RECEIVE_ALL_DOCUMENTO_ATESTADO_MODELO.type:
      return {
        ...state,
        allDocuentoAtestadoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    // modelo documento_orientacoes
    // -------------------------------------------------------------------------
    case REQUEST_DOCUMENTO_ORIENTACOES_MODELOS.type:
      return {
        ...state,
        docuentoOrientacoesModelos: {
          isLoading: action.isLoading,
          params: action.params,
          data: INITIAL_STATE.docuentoOrientacoesModelos.data,
        }
      }
    case RECEIVE_DOCUMENTO_ORIENTACOES_MODELOS.type:
      return {
        ...state,
        docuentoOrientacoesModelos: {
          isLoading: action.isLoading,
          params: state.docuentoOrientacoesModelos.params,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        docuentoOrientacoesModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.docuentoOrientacoesModelo.data,
        }
      }
    case RECEIVE_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        docuentoOrientacoesModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        docuentoOrientacoesModelo: INITIAL_STATE.docuentoOrientacoesModelo
      }
    case REQUEST_SAVE_DOCUMENTO_ORIENTACOES_MODELO.type:
    case RECEIVE_SAVE_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        saveDocuentoOrientacoesModelo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        allDocuentoOrientacoesModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allDocuentoOrientacoesModelo.data,
        }
      }
    case RECEIVE_ALL_DOCUMENTO_ORIENTACOES_MODELO.type:
      return {
        ...state,
        allDocuentoOrientacoesModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    // modelo prescricao
    // -------------------------------------------------------------------------
    case REQUEST_PRESCRICAO_MODELOS.type:
      return {
        ...state,
        prescricaoModelos: {
          isLoading: action.isLoading,
          params: action.params,
          data: INITIAL_STATE.prescricaoModelos.data,
        }
      }
    case RECEIVE_PRESCRICAO_MODELOS.type:
      return {
        ...state,
        prescricaoModelos: {
          isLoading: action.isLoading,
          params: state.prescricaoModelos.params,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PRESCRICAO_MODELO.type:
      return {
        ...state,
        prescricaoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.prescricaoModelo.data,
        }
      }
    case RECEIVE_PRESCRICAO_MODELO.type:
      return {
        ...state,
        prescricaoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_PRESCRICAO_MODELO.type:
      return {
        ...state,
        prescricaoModelo: INITIAL_STATE.prescricaoModelo
      }
    case REQUEST_SAVE_PRESCRICAO_MODELO.type:
    case RECEIVE_SAVE_PRESCRICAO_MODELO.type:
      return {
        ...state,
        savePrescricaoModelo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_PRESCRICAO_MODELO.type:
      return {
        ...state,
        allPrescricaoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allPrescricaoModelo.data,
        }
      }
    case RECEIVE_ALL_PRESCRICAO_MODELO.type:
      return {
        ...state,
        allPrescricaoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    // modelo exame_procedimento
    // -------------------------------------------------------------------------
    case REQUEST_EXAME_PROCEDIMENTO_MODELOS.type:
      return {
        ...state,
        exameProcedimentoModelos: {
          isLoading: action.isLoading,
          params: action.params,
          data: INITIAL_STATE.exameProcedimentoModelos.data,
        }
      }
    case RECEIVE_EXAME_PROCEDIMENTO_MODELOS.type:
      return {
        ...state,
        exameProcedimentoModelos: {
          isLoading: action.isLoading,
          params: state.exameProcedimentoModelos.params,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        exameProcedimentoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.exameProcedimentoModelo.data,
        }
      }
    case RECEIVE_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        exameProcedimentoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        exameProcedimentoModelo: INITIAL_STATE.exameProcedimentoModelo
      }
    case REQUEST_SAVE_EXAME_PROCEDIMENTO_MODELO.type:
    case RECEIVE_SAVE_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        saveExameProcedimentoModelo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        allExameProcedimentoModelo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.allExameProcedimentoModelo.data,
        }
      }
    case RECEIVE_ALL_EXAME_PROCEDIMENTO_MODELO.type:
      return {
        ...state,
        allExameProcedimentoModelo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    // -------------------------------------------------------------------------
    case REQUEST_SECAO_GRUPOS.type:
      return {
        ...state,
        listSecaoGrupos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listSecaoGrupos.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SECAO_GRUPOS.type:
      return {
        ...state,
        listSecaoGrupos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SECAO_GRUPO.type:
      return {
        ...state,
        itemSecaoGrupo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemSecaoGrupo.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SECAO_GRUPO.type:
      return {
        ...state,
        itemSecaoGrupo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_SECAO_GRUPO.type:
    case RECEIVE_REMOVE_SECAO_GRUPO.type:
      return {
        ...state,
        removeSecaoGrupo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_COPY_SECAO_GRUPO.type:
    case RECEIVE_COPY_SECAO_GRUPO.type:
      return {
        ...state,
        copySecaoGrupo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_SECAO_GRUPO.type:
    case RECEIVE_SAVE_SECAO_GRUPO.type:
      return {
        ...state,
        saveSecaoGrupo: {
          isLoading: action.isLoading,
        }
      }
    // -------------------------------------------------------------------------
    case REQUEST_SAVE_PRONTUARIO_ASO_ANAMNESE:
    case RECEIVE_SAVE_PRONTUARIO_ASO_ANAMNESE:
      return {
        ...state,
        saveAsoAnamnese: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PRONTUARIO_ASO:
    case RECEIVE_SAVE_PRONTUARIO_ASO:
      return {
        ...state,
        saveAso: {
          isLoading: action.isLoading,
        }
      }
    // -------------------------------------------------------------------------
    case RESET_ALL_PRONTUARIO.type:
      return INITIAL_STATE
    default:
      return state
  }
}
