import { CHANGE_NOTIFICATION_FLAGS } from '../constants/ActionTypes'

const INITIAL_STATE = {
  permission: null,
}

export default function DesktopNotificationReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CHANGE_NOTIFICATION_FLAGS:
      return {
        ...state,
        permission: action.permission
      }
    default:
      return state
  }
}