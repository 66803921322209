import {
  REQUEST_CONFIG, RECEIVE_CONFIG, REQUEST_FERIADOS, RECEIVE_FERIADOS, REQUEST_SAVE_FERIADO, RECEIVE_SAVE_FERIADO,
  REQUEST_IMPOSTOS, RECEIVE_IMPOSTOS, REQUEST_SAVE_IMPOSTOS, RECEIVE_SAVE_IMPOSTOS, REQUEST_CERTIFICADO,
  RECEIVE_CERTIFICADO, REQUEST_SAVE_CERTIFICADO, RECEIVE_SAVE_CERTIFICADO, REQUEST_CONVENIOS, RECEIVE_CONVENIOS,
  REQUEST_CONVENIO, RECEIVE_CONVENIO, REQUEST_SAVE_CONVENIO, RECEIVE_SAVE_CONVENIO, REQUEST_RECEBIMENTO,
  RECEIVE_RECEBIMENTO, REQUEST_SAVE_RECEBIMENTO, RECEIVE_SAVE_RECEBIMENTO, RESET_ALL_CONFIG
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  config: {
    data: {},
    isLoading: false,
  },
  feriados: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveFeriado: {
    isLoading: false,
  },
  impostos: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveImpostos: {
    isLoading: false,
  },
  certificado: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveCertificado: {
    isLoading: false,
  },
  convenios: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  convenio: {
    data: {},
    isLoading: false,
  },
  saveConvenio: {
    isLoading: false,
  },
  recebimento: {
    data: {},
    isLoading: false,
  },
  saveRecebimento: {
    isLoading: false,
  },
}

export default function ConfigReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_CONFIG:
      return {
        ...state,
        config: {
          isLoading: action.isLoading,
          data: state.config.data,
        }
      }
    case RECEIVE_CONFIG:
      return {
        ...state,
        config: {
          isLoading: action.isLoading,
          data: {
            ...state.config.data,
            [action.key]: action.value
          },
        }
      }
    case REQUEST_FERIADOS:
      return {
        ...state,
        feriados: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.feriados.data,
        }
      }
    case RECEIVE_FERIADOS:
      return {
        ...state,
        feriados: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.feriados.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_FERIADO:
    case RECEIVE_SAVE_FERIADO:
      return {
        ...state,
        saveFeriado: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_IMPOSTOS.type:
      return {
        ...state,
        impostos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.impostos.data,
        }
      }
    case RECEIVE_IMPOSTOS.type:
      return {
        ...state,
        impostos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_IMPOSTOS.type:
      return {
        ...state,
        saveImpostos: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_IMPOSTOS.type:
      return {
        ...state,
        saveImpostos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CERTIFICADO.type:
      return {
        ...state,
        certificado: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.certificado.data,
        }
      }
    case RECEIVE_CERTIFICADO.type:
      return {
        ...state,
        certificado: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CERTIFICADO.type:
      return {
        ...state,
        saveCertificado: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_CERTIFICADO.type:
      return {
        ...state,
        saveCertificado: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CONVENIOS:
      return {
        ...state,
        convenios: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.convenios.data,
        }
      }
    case RECEIVE_CONVENIOS:
      return {
        ...state,
        convenios: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.convenios.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CONVENIO:
      return {
        ...state,
        convenio: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.convenio.data,
        }
      }
    case RECEIVE_CONVENIO:
      return {
        ...state,
        convenio: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.convenio.data,
        }
      }
    case REQUEST_SAVE_CONVENIO:
    case RECEIVE_SAVE_CONVENIO:
      return {
        ...state,
        saveConvenio: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_RECEBIMENTO:
      return {
        ...state,
        recebimento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.recebimento.data,
        }
      }
    case RECEIVE_RECEBIMENTO:
      return {
        ...state,
        recebimento: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.recebimento.data,
        }
      }
    case REQUEST_SAVE_RECEBIMENTO:
    case RECEIVE_SAVE_RECEBIMENTO:
      return {
        ...state,
        saveCertificado: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_CONFIG.type:
      return INITIAL_STATE
    default:
      return state
  }
}
