import {
  REQUEST_RELATORIO_COMISSAO, RECEIVE_RELATORIO_COMISSAO, REQUEST_RELATORIO_PRODUTIVIDADE,
  RECEIVE_RELATORIO_PRODUTIVIDADE, REQUEST_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS,
  RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS, REQUEST_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS,
  RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS, /*REQUEST_RELATORIO_NOTAS_MEDICAS_PENDENTES,
  RECEIVE_RELATORIO_NOTAS_MEDICAS_PENDENTES,*/ REQUEST_RELATORIO_CONTAS_PAGAR, RECEIVE_RELATORIO_CONTAS_PAGAR,
  REQUEST_ENVIAR_COMPROVANTE_CONTA_PAGAR, RECEIVE_ENVIAR_COMPROVANTE_CONTA_PAGAR,
  REQUEST_REMOVER_COMPROVANTE_CONTA_PAGAR, RECEIVE_REMOVER_COMPROVANTE_CONTA_PAGAR,
  REQUEST_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES, RECEIVE_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES,
  REQUEST_RELATORIO_PRODUTIVIDADE_CLINICAS, RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICAS,
  REQUEST_RELATORIO_PRODUTIVIDADE_MEDICOS, RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICOS,
  REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES, RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES,
  REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES, RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES,
  REQUEST_MEIOS_PAGAMENTO, RECEIVE_MEIOS_PAGAMENTO, RESET_ALL_RELATORIO
} from '../constants/ActionTypes'


const INITIAL_STATE = {
  comissao: {
    isLoading: false,
    data: {
      valores: 0
    },
    receivedAt: null,
  },
  produtividade: {
    isLoading: false,
    data: {
      items: [],
      total: 0
    },
    receivedAt: null,
  },
  produtividadeClinicaAtendimentos: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
      sumTeleconsulta: {
        valor: 0,
        valor_clinica: 0,
        valor_medico: 0
      },
    },
    receivedAt: null,
  },
  produtividadeMedicoAtendimentos: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
      sumTeleconsulta: {
        valor: 0,
        valor_clinica: 0,
        valor_medico: 0
      },
    },
    receivedAt: null,
  },
  notasMedicasPendentes: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  produtividadeEspecialidades: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  produtividadeClinicas: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  produtividadeMedicos: {
    isLoading: false,
    data: {},
    mes: null,
    ano: null,
    receivedAt: null,
  },
  contasPagar: {
    isLoading: false,
    tipo: null, // medico, clinica, etc
    data: [],
    receivedAt: null,
  },
  registrarPagamento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  removerComprovante: {
    isLoading: false,
  },
  produtividadeClinicaTecnicoGraficoExames: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  produtividadeClinicaTecnicoListaExames: {
    isLoading: false,
    data: {
      rows: [],
      bonus: {},
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  meiosPagamento: {
    isLoading: false,
    data: {
      faturas: [],
      receitas: [],
      teleconsultas: [],
    },
    receivedAt: null,
  }
}

export default function RelatorioReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_RELATORIO_COMISSAO:
      return {
        ...state,
        comissao: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.comissao.data,
          receivedAt: INITIAL_STATE.comissao.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_COMISSAO:
      return {
        ...state,
        comissao: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE:
      return {
        ...state,
        produtividade: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividade.data,
          receivedAt: INITIAL_STATE.produtividade.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE:
      return {
        ...state,
        produtividade: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS:
      return {
        ...state,
        produtividadeClinicaAtendimentos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeClinicaAtendimentos.data,
          receivedAt: INITIAL_STATE.produtividadeClinicaAtendimentos.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICA_ATENDIMENTOS:
      return {
        ...state,
        produtividadeClinicaAtendimentos: {
          isLoading: action.isLoading,
          data: (action.data) ? action.data : state.produtividadeClinicaAtendimentos.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS:
      return {
        ...state,
        produtividadeMedicoAtendimentos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeMedicoAtendimentos.data,
          receivedAt: INITIAL_STATE.produtividadeMedicoAtendimentos.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICO_ATENDIMENTOS:
      return {
        ...state,
        produtividadeMedicoAtendimentos: {
          isLoading: action.isLoading,
          data: (action.data) ? action.data : state.produtividadeMedicoAtendimentos.data,
          receivedAt: action.receivedAt,
        }
      }
    /*case REQUEST_RELATORIO_NOTAS_MEDICAS_PENDENTES:
      return {
        ...state,
        notasMedicasPendentes: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.notasMedicasPendentes.data,
          receivedAt: INITIAL_STATE.notasMedicasPendentes.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_NOTAS_MEDICAS_PENDENTES:
      return {
        ...state,
        notasMedicasPendentes: {
          isLoading: action.isLoading,
          data: (action.data) ? action.data : state.notasMedicasPendentes.data,
          receivedAt: action.receivedAt,
        }
      }*/
    case REQUEST_RELATORIO_CONTAS_PAGAR.type:
      return {
        ...state,
        contasPagar: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.contasPagar.data,
          tipo: INITIAL_STATE.contasPagar.tipo,
          receivedAt: INITIAL_STATE.contasPagar.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_CONTAS_PAGAR.type:
      return {
        ...state,
        contasPagar: {
          isLoading: action.isLoading,
          data: action.data,
          tipo: action.tipo,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENVIAR_COMPROVANTE_CONTA_PAGAR.type:
      return {
        ...state,
        registrarPagamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.registrarPagamento.data,
        }
      }
    case RECEIVE_ENVIAR_COMPROVANTE_CONTA_PAGAR.type:
      return {
        ...state,
        registrarPagamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVER_COMPROVANTE_CONTA_PAGAR:
    case RECEIVE_REMOVER_COMPROVANTE_CONTA_PAGAR:
      return {
        ...state,
        removerComprovante: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES:
      return {
        ...state,
        produtividadeEspecialidades: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeEspecialidades.data,
          receivedAt: INITIAL_STATE.produtividadeEspecialidades.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE_ESPECIALIDADES:
      return {
        ...state,
        produtividadeEspecialidades: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE_CLINICAS:
      return {
        ...state,
        produtividadeClinicas: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeClinicas.data,
          receivedAt: INITIAL_STATE.produtividadeClinicas.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE_CLINICAS:
      return {
        ...state,
        produtividadeClinicas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_PRODUTIVIDADE_MEDICOS:
      return {
        ...state,
        produtividadeMedicos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeMedicos.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.produtividadeMedicos.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_PRODUTIVIDADE_MEDICOS:
      return {
        ...state,
        produtividadeMedicos: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES:
      return {
        ...state,
        produtividadeClinicaTecnicoGraficoExames: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeClinicaTecnicoGraficoExames.data,
          receivedAt: INITIAL_STATE.produtividadeClinicaTecnicoGraficoExames.receivedAt,
        }
      }
    case RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_GRAFICO_EXAMES:
      return {
        ...state,
        produtividadeClinicaTecnicoGraficoExames: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES:
      return {
        ...state,
        produtividadeClinicaTecnicoListaExames: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.produtividadeClinicaTecnicoListaExames.data,
          receivedAt: INITIAL_STATE.produtividadeClinicaTecnicoListaExames.receivedAt,
        }
      }
    case RECEIVE_PRODUTIVIDADE_CLINICA_TECNICO_LISTA_EXAMES:
      return {
        ...state,
        produtividadeClinicaTecnicoListaExames: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MEIOS_PAGAMENTO:
      return {
        ...state,
        meiosPagamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.meiosPagamento.data,
          receivedAt: INITIAL_STATE.meiosPagamento.receivedAt,
        }
      }
    case RECEIVE_MEIOS_PAGAMENTO:
      return {
        ...state,
        meiosPagamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_RELATORIO:
      return INITIAL_STATE
    default:
      return state
  }
}
