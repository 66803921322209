import {
  REQUEST_MAILS, RECEIVE_MAILS, REQUEST_MAIL, RECEIVE_MAIL, REQUEST_MAILS_BLACK_LIST, RECEIVE_MAILS_BLACK_LIST,
  REQUEST_BLACK_LIST_MARK_READ, RECEIVE_BLACK_LIST_MARK_READ, RESET_ALL_MAIL
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  blackList: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      countUnread: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  markRead: {
    isLoading: false,
  },
}

export default function MailReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_MAILS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_MAILS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MAIL:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_MAIL:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MAILS_BLACK_LIST:
      return {
        ...state,
        blackList: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.blackList.data,
          receivedAt: state.blackList.receivedAt,
        }
      }
    case RECEIVE_MAILS_BLACK_LIST:
      return {
        ...state,
        blackList: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_BLACK_LIST_MARK_READ:
    case RECEIVE_BLACK_LIST_MARK_READ:
      return {
        ...state,
        markRead: {
          isLoading: action.isLoading,
        }
      }

    case RESET_ALL_MAIL:
      return {
        ...state,
        listMatriz: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listMatriz.data,
        }
      }
    case RESET_ALL_MAIL:
      return INITIAL_STATE
    default:
      return state
  }
}
