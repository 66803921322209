import {
  REQUEST_SMSS, RECEIVE_SMSS, REQUEST_RELATORIO_SMS_CLINICA, RECEIVE_RELATORIO_SMS_CLINICA, REQUEST_SMS_CONFIG,
  RECEIVE_SMS_CONFIG, REQUEST_SAVE_SMS_CONFIG, RECEIVE_SAVE_SMS_CONFIG, REQUEST_RESET_SMS_CONFIG,
  RECEIVE_RESET_SMS_CONFIG, RESET_ALL_SMS
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  relatorioClinica: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
      sumResult: {
        amount: 0,
      },
    },
    receivedAt: null,
  },
  config: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveConfig: {
    isLoading: false,
  },
  resetConfig: {
    isLoading: false,
  }
}

export default function SMSReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_SMSS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_SMSS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RELATORIO_SMS_CLINICA:
      return {
        ...state,
        relatorioClinica: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.relatorioClinica.data,
          receivedAt: state.relatorioClinica.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_SMS_CLINICA:
      return {
        ...state,
        relatorioClinica: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SMS_CONFIG:
      return {
        ...state,
        config: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.config.data,
          receivedAt: state.config.receivedAt,
        }
      }
    case RECEIVE_SMS_CONFIG:
      return {
        ...state,
        config: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_SMS_CONFIG:
    case RECEIVE_SAVE_SMS_CONFIG:
      return {
        ...state,
        saveConfig: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_RESET_SMS_CONFIG:
    case RECEIVE_RESET_SMS_CONFIG:
      return {
        ...state,
        resetConfig: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_SMS:
      return INITIAL_STATE
    default:
      return state
  }
}
