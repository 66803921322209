import {
  REQUEST_APP, RESET_ALL_APP, TOGGLE_SIDEBAR_VISIBLE, DISMISS_VERIFICAR_PAGAMENTO, DISMISS_CERTIFICATE_EXPIRE,
  APP_ONLINE, WINDOW_ACTIVE, WINDOW_VISIBLE, TOGGLE_CHAT_VISIBLE, TOGGLE_AVISOS_VISIBLE, UPDATE_NEW_CHAT_MESSAGES,
  REQUEST_CHAT_BROADCAST_MESSAGE, RECEIVE_CHAT_BROADCAST_MESSAGE, REQUEST_EMAIL_BROADCAST_MESSAGE,
  RECEIVE_EMAIL_BROADCAST_MESSAGE, SHOW_MESSAGE_MODAL, HIDE_MESSAGE_MODAL, CHANGE_WIZARD, REQUEST_WIZARD,
  RECEIVE_WIZARD, REQUEST_SAVE_WIZARD, RECEIVE_SAVE_WIZARD, DISMISS_EXAMES_AGUARDANDO_ENVIO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  dispatched: false,
  dismissedEnviarExamesAguardandoEnvio: false,
  dismissedVerificarPagamentos: false,
  dismissedCertificateExpire: false,
  appOnline: true, // network connection status,
  windowActive: false, // indica que a janela/tab está ativa (focada)
  windowVisible: 'visible', // indica que a janela/tab está visível para o usuário: hidden ou visible
  sidebarVisible: true,
  chatVisible: false,
  avisosVisible: false,
  chatNewMessages: 0, // quantidade de novas mensagens service-chat
  isLoadinBroadcastMessage: false, // mesnagens em loto sendo enviadas para o chat
  modalMessage: {
    title: null,
    content: '',
    visible: false,
  },
  wizard: {
    show: false,
    step: null
  },
  wizardData: {
    isLoading: false,
    data: null,
    invite: null,
    status: null,
  },
  wizardSave: {
    isLoading: false,
  }
}

export default function AppReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_APP:
      return {
        ...state,
        dispatched: action.dispatched
      }
    case DISMISS_EXAMES_AGUARDANDO_ENVIO:
        return {
          ...state,
          dismissedEnviarExamesAguardandoEnvio: true,
      }
    case DISMISS_VERIFICAR_PAGAMENTO:
      return {
        ...state,
        dismissedVerificarPagamentos: action.data,
      }
    case DISMISS_CERTIFICATE_EXPIRE:
      return {
        ...state,
        dismissedCertificateExpire: true,
      }
    case APP_ONLINE:
      return {
        ...state,
        appOnline: action.appOnline
      }
    case WINDOW_ACTIVE:
      return {
        ...state,
        windowActive: action.active
      }
    case WINDOW_VISIBLE:
      return {
        ...state,
        windowVisible: action.visible
      }
    case TOGGLE_SIDEBAR_VISIBLE:
      return {
        ...state,
        sidebarVisible: action.sidebarVisible
      }
    case TOGGLE_CHAT_VISIBLE:
      return {
        ...state,
        chatVisible: action.chatVisible
      }
    case TOGGLE_AVISOS_VISIBLE:
      return {
        ...state,
        avisosVisible: action.avisosVisible
      }
    case UPDATE_NEW_CHAT_MESSAGES:
      return {
        ...state,
        chatNewMessages: action.chatNewMessages
      }
    case REQUEST_CHAT_BROADCAST_MESSAGE:
    case RECEIVE_CHAT_BROADCAST_MESSAGE:
      return {
        ...state,
        isLoadinBroadcastMessage: action.isLoading
      }
    case REQUEST_EMAIL_BROADCAST_MESSAGE:
    case RECEIVE_EMAIL_BROADCAST_MESSAGE:
      return {
        ...state,
        isLoadinBroadcastMessage: action.isLoading
      }
    case SHOW_MESSAGE_MODAL:
      return {
        ...state,
        modalMessage: {
          title: action.title,
          content: action.content,
          visible: true,
        }
      }
    case HIDE_MESSAGE_MODAL:
      return {
        ...state,
        modalMessage: INITIAL_STATE.modalMessage
      }
    case CHANGE_WIZARD:
      return {
        ...state,
        wizard: {
          ...state.wizard,
          ...action.payload,
        }
      }
    case REQUEST_WIZARD:
    case RECEIVE_WIZARD:
      return {
        ...state,
        wizardData: {
          isLoading: action.isLoading,
          data: action.data,
          invite: action.invite,
          status: action.status,
        }
      }
    case REQUEST_SAVE_WIZARD:
    case RECEIVE_SAVE_WIZARD:
      return {
        ...state,
        wizardSave: {
          isLoading: action.isLoading,
        }
      }
    case 'CANCEL_ACTION':
      return state
    case RESET_ALL_APP:
      return INITIAL_STATE
    default:
      return state
  }
}