import {
  REQUEST_CREDIT_CARDS, RECEIVE_CREDIT_CARDS, REQUEST_CREDIT_CARD, RECEIVE_CREDIT_CARD, REQUEST_SAVE_CREDIT_CARD,
  RECEIVE_SAVE_CREDIT_CARD, RESET_CREDIT_CARD, REQUEST_REMOVE_CREDIT_CARD, RECEIVE_REMOVE_CREDIT_CARD,
  REQUEST_URL_PAGAMENTO, RECEIVE_URL_PAGAMENTO, REQUEST_EXECUTE_PAGAMENTO, RECEIVE_EXECUTE_PAGAMENTO,
  REQUEST_SAVE_PAYPAL_REMEMBER_CARD, RECEIVE_SAVE_PAYPAL_REMEMBER_CARD, REQUEST_CONFIRM_PAGAMENTO,
  RECEIVE_CONFIRM_PAGAMENTO, RESET_ALL_PAGAMENTO
 } from '../constants/ActionTypes'

const INITIAL_STATE = {
  creditCards: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  creditCard: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveCreditCard: {
    isLoading: false,
  },
  removeCreditCard: {
    isLoading: false,
  },
  urlPagamento: {
    isLoading: false,
  },
  executePagamento: {
    isLoading: false,
  },
  saveRememberCard: {
    isLoading: false,
  },
  confirmPagamento: {
    isLoading: false,
  },
}

export default function PagamentoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_CREDIT_CARDS:
      return {
        ...state,
        creditCards: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.creditCards.data,
          receivedAt: state.creditCards.receivedAt,
        }
      }
    case RECEIVE_CREDIT_CARDS:
      return {
        ...state,
        creditCards: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CREDIT_CARD:
      return {
        ...state,
        creditCard: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.creditCard.data,
        }
      }
    case RECEIVE_CREDIT_CARD:
      return {
        ...state,
        creditCard: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CREDIT_CARD:
    case RECEIVE_SAVE_CREDIT_CARD:
      return {
        ...state,
        saveCreditCard: {
          isLoading: action.isLoading,
        }
      }
    case RESET_CREDIT_CARD:
      return {
        ...state,
        creditCard: INITIAL_STATE.creditCard
      }
    case REQUEST_REMOVE_CREDIT_CARD:
    case RECEIVE_REMOVE_CREDIT_CARD:
      return {
        ...state,
        removeCreditCard: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_URL_PAGAMENTO.type:
    case RECEIVE_URL_PAGAMENTO.type:
      return {
        ...state,
        urlPagamento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_EXECUTE_PAGAMENTO.type:
    case RECEIVE_EXECUTE_PAGAMENTO.type:
      return {
        ...state,
        executePagamento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PAYPAL_REMEMBER_CARD.type:
    case RECEIVE_SAVE_PAYPAL_REMEMBER_CARD.type:
      return {
        ...state,
        saveRememberCard: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CONFIRM_PAGAMENTO:
    case RECEIVE_CONFIRM_PAGAMENTO:
      return {
        ...state,
        confirmPagamento: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_PAGAMENTO.type:
      return INITIAL_STATE
    default:
      return state
  }
}
