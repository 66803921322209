import {
  REQUEST_PLANTAO_QUEUE_INFO, RECEIVE_PLANTAO_QUEUE_INFO, REQUEST_CAPTURAR_PLANTAO_UNICO, RECEIVE_CAPTURAR_PLANTAO_UNICO,
  RESET_ALL_PLANTAO_UNICO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  queueInfo: {
    isLoading: false,
    data: {
      plantaoUnico: {},
      fila: []
    },
    receivedAt: null,
  },
  capturarPlantao: {
    isLoading: false,
  }
}

export default function PlantaoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_PLANTAO_QUEUE_INFO:
      return {
        ...state,
        queueInfo: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.queueInfo.data : INITIAL_STATE.queueInfo.data,
        }
      }
    case RECEIVE_PLANTAO_QUEUE_INFO:
      return {
        ...state,
        queueInfo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CAPTURAR_PLANTAO_UNICO:
    case RECEIVE_CAPTURAR_PLANTAO_UNICO:
      return {
        ...state,
        capturarPlantao: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_PLANTAO_UNICO:
      return INITIAL_STATE
    default:
      return state
  }
}