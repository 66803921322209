import {
  REQUEST_EXAMES, RECEIVE_EXAMES, REQUEST_EXAME, RECEIVE_EXAME, REQUEST_EXAMES_BY_PACIENTE,
  RECEIVE_EXAMES_BY_PACIENTE, REQUEST_SAVE_EXAME, RECEIVE_SAVE_EXAME, /*REQUEST_SAVE_PRE_LAUDO, RECEIVE_SAVE_PRE_LAUDO,*/
  SHOW_CONFIRM_LAUDAR, HIDE_CONFIRM_LAUDAR, REQUEST_CHANGE_ESPECIALIDADE_EXAME, RECEIVE_CHANGE_ESPECIALIDADE_EXAME,
  REQUEST_ORIENTAR_EXAME, RECEIVE_ORIENTAR_EXAME, START_LAUDAR, FINISH_LAUDAR, UPDATE_MEDICO_AVAILABLE_EXAMES,
  RESET_EXAME, REQUEST_REMOVE_EXAME, RECEIVE_REMOVE_EXAME, REQUEST_PARTES_CORPO, RECEIVE_PARTES_CORPO, RESET_ALL_EXAME,
  REQUEST_PDF_HEADER_FOOTER, RECEIVE_PDF_HEADER_FOOTER, REQUEST_DICOM_RESOURCES, RECEIVE_DICOM_RESOURCES,
  RESET_DICOM_RESOURCES, REQUEST_SOLICITAR_REVISAO, RECEIVE_SOLICITAR_REVISAO, REQUEST_ENCAMINHAR_REVISAO,
  RECEIVE_ENCAMINHAR_REVISAO, REQUEST_JUSTIFICAR_REVISAO, RECEIVE_JUSTIFICAR_REVISAO, REQUEST_REMOVER_REVISAO,
  RECEIVE_REMOVER_REVISAO, REQUEST_LAUDOS_ANTERIORES, RECEIVE_LAUDOS_ANTERIORES, REQUEST_REMOVE_EXAME_ANEXO,
  RECEIVE_REMOVE_EXAME_ANEXO, REQUEST_ADD_EXAME_ANEXOS, RECEIVE_ADD_EXAME_ANEXOS, REQUEST_REPLACE_EXAME_ANEXO,
  REQUEST_ACCEPT_EXAME_ANEXO, RECEIVE_ACCEPT_EXAME_ANEXO, RECEIVE_REPLACE_EXAME_ANEXO, REQUEST_EXAME_ANEXOS_ANALISE,
  RECEIVE_EXAME_ANEXOS_ANALISE, REQUEST_SAVE_EXAME_ANEXO_ANALISE, RECEIVE_SAVE_EXAME_ANEXO_ANALISE,
  REQUEST_EXAME_ANEXOS_ESTATISTICAS, RECEIVE_EXAME_ANEXOS_ESTATISTICAS, REQUEST_SEARCH_PCMSO_AUTOCOMPLETE,
  RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE, REQUEST_CARGOS_APROVACAO, RECEIVE_CARGOS_APROVACAO,  REQUEST_ASO_LABELS_CLINICA,
  RECEIVE_ASO_LABELS_CLINICA
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      bonus: {},
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  removeItem: {
    isLoading: false,
  },
  listByPaciente: {
    pacienteId: null,
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  changeEspecialidade: {
    isLoading: false,
  },
  orientar: {
    isLoading: false,
  },
  laudar: {
    showConfirmDialog: false,
    confirmMessage: '',
    idExame: null,
  },
  // indica que o médico entro no form de laudo por açao fetchLaudar
  // serve para não premitir acesso caso chegue a esta página por navegação (history back)
  launderingExameId: null,
  medicoAvailableExames: {
    ids: [],
    lastNotification: null, // timestamp
  },
  partesCorpo: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 1000,
    },
    receivedAt: null,
  },
  asoLabelsClinica: {
    isLoading: false,
    data: {
      label_riscos_01: 'Físico',
      label_riscos_02: 'Químico',
      label_riscos_03: 'Biológico',
      label_riscos_04: 'Ergonômico',
      label_riscos_06: 'Mecânico / Acidente',
      label_riscos_05: 'Não há riscos ocupacionais específicos',
    },
    receivedAt: null,
  },
  pdfHeaderFoooter: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  dicomResources: {
    idExame: null,
    isLoading: false,
    data: [],
    study: {},
    receivedAt: null,
  },
  solicitarRevisao: {
    isLoading: false,
  },
  encaminharRevisao: {
    isLoading: false,
  },
  justificarRevisao: {
    isLoading: false,
  },
  removerRevisao: {
    isLoading: false,
  },
  laudosAnteriores: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  removeAnexo: {
    isLoading: false,
  },
  saveAnexo: {
    isLoading: false,
  },
  listAnexosAnalise: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 30,
    },
    receivedAt: null,
  },
  saveAnexoAnalise: {
    isLoading: false,
  },
  exameAnexosEstatisticas: {
    isLoading: false,
    data: {},
  },
  searchPcmsoAutocomplete: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 1000,
      table: '',
      field: '',
      search: '',
    },
    receivedAt: null,
  },
  cargosAprovacao: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  }
}

export default function ExameReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_EXAMES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.list.data : INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_EXAMES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EXAME.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.item.data : INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_EXAME.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EXAMES_BY_PACIENTE.type:
      return {
        ...state,
        listByPaciente: {
          isLoading: action.isLoading,
          idPaciente: action.idPaciente,
          data: INITIAL_STATE.listByPaciente.data,
        }
      }
    case RECEIVE_EXAMES_BY_PACIENTE.type:
      return {
        ...state,
        listByPaciente: {
          isLoading: action.isLoading,
          idPaciente: action.idPaciente,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_EXAME.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case REQUEST_SAVE_EXAME.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case SHOW_CONFIRM_LAUDAR.type:
      return {
        ...state,
        laudar: {
          showConfirmDialog: true,
          confirmMessage: action.message,
          idExame: action.idExame
        }
      }
    case HIDE_CONFIRM_LAUDAR.type:
      return {
        ...state,
        laudar: INITIAL_STATE.laudar,
      }
    case START_LAUDAR.type:
      return {
        ...state,
        launderingExameId: action.idExame
      }
    case FINISH_LAUDAR.type:
      return {
        ...state,
        launderingExameId: INITIAL_STATE.launderingExameId,
      }
    case REQUEST_CHANGE_ESPECIALIDADE_EXAME.type:
      return {
        ...state,
        changeEspecialidade: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_CHANGE_ESPECIALIDADE_EXAME.type:
      return {
        ...state,
        changeEspecialidade: {
          isLoading: action.isLoading
        }
      }
    case REQUEST_ORIENTAR_EXAME.type:
      return {
        ...state,
        orientar: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_ORIENTAR_EXAME.type:
      return {
        ...state,
        orientar: {
          isLoading: action.isLoading
        }
      }
    case RECEIVE_SAVE_EXAME.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case UPDATE_MEDICO_AVAILABLE_EXAMES.type:
      return {
        ...state,
        medicoAvailableExames: action.medicoAvailableExames,
      }
    case REQUEST_REMOVE_EXAME.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_EXAME.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PARTES_CORPO:
      return {
        ...state,
        partesCorpo: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.partesCorpo.data,
          receivedAt: INITIAL_STATE.partesCorpo.receivedAt,
        }
      }
    case RECEIVE_PARTES_CORPO:
      return {
        ...state,
        partesCorpo: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ASO_LABELS_CLINICA:
      return {
        ...state,
        asoLabelsClinica: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.asoLabelsClinica.data,
          receivedAt: INITIAL_STATE.asoLabelsClinica.receivedAt,
        }
      }
    case RECEIVE_ASO_LABELS_CLINICA:
      return {
        ...state,
        asoLabelsClinica: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PDF_HEADER_FOOTER:
      return {
        ...state,
        pdfHeaderFoooter: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.pdfHeaderFoooter.data,
          receivedAt: INITIAL_STATE.pdfHeaderFoooter.receivedAt,
        }
      }
    case RECEIVE_PDF_HEADER_FOOTER:
      return {
        ...state,
        pdfHeaderFoooter: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_DICOM_RESOURCES:
      return {
        ...state,
        dicomResources: {
          idExame: null,
          isLoading: action.isLoading,
          data: INITIAL_STATE.dicomResources.data,
          study: INITIAL_STATE.dicomResources.study,
          receivedAt: INITIAL_STATE.dicomResources.receivedAt,
        }
      }
    case RECEIVE_DICOM_RESOURCES:
      return {
        ...state,
        dicomResources: {
          idExame: action.idExame,
          isLoading: action.isLoading,
          data: action.data,
          study: action.study,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_DICOM_RESOURCES:
      return {
        ...state,
        dicomResources: INITIAL_STATE.dicomResources,
      }
    case REQUEST_SOLICITAR_REVISAO:
    case RECEIVE_SOLICITAR_REVISAO:
      return {
        ...state,
        solicitarRevisao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ENCAMINHAR_REVISAO:
    case RECEIVE_ENCAMINHAR_REVISAO:
      return {
        ...state,
        encaminharRevisao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_JUSTIFICAR_REVISAO:
    case RECEIVE_JUSTIFICAR_REVISAO:
      return {
        ...state,
        justificarRevisao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVER_REVISAO:
    case RECEIVE_REMOVER_REVISAO:
      return {
        ...state,
        removerRevisao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_LAUDOS_ANTERIORES:
      return {
        ...state,
        laudosAnteriores: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.laudosAnteriores.data,
          receivedAt: INITIAL_STATE.laudosAnteriores.receivedAt,
        }
      }
    case RECEIVE_LAUDOS_ANTERIORES:
      return {
        ...state,
        laudosAnteriores: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_EXAME_ANEXO:
    case RECEIVE_REMOVE_EXAME_ANEXO:
      return {
        ...state,
        removeAnexo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ADD_EXAME_ANEXOS:
    case RECEIVE_ADD_EXAME_ANEXOS:
    case REQUEST_REPLACE_EXAME_ANEXO:
    case RECEIVE_REPLACE_EXAME_ANEXO:
    case REQUEST_ACCEPT_EXAME_ANEXO:
    case RECEIVE_ACCEPT_EXAME_ANEXO:
      return {
        ...state,
        saveAnexo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_EXAME_ANEXOS_ANALISE:
      return {
        ...state,
        listAnexosAnalise: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listAnexosAnalise.data,
          receivedAt: state.listAnexosAnalise.receivedAt,
        }
      }
    case RECEIVE_EXAME_ANEXOS_ANALISE:
      return {
        ...state,
        listAnexosAnalise: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_EXAME_ANEXO_ANALISE:
    case RECEIVE_SAVE_EXAME_ANEXO_ANALISE:
      return {
        ...state,
        saveAnexoAnalise: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_EXAME_ANEXOS_ESTATISTICAS:
    case RECEIVE_EXAME_ANEXOS_ESTATISTICAS:
      return {
        ...state,
        exameAnexosEstatisticas: {
          isLoading: action.isLoading,
          data: action.data,
        }
      }
    case REQUEST_SEARCH_PCMSO_AUTOCOMPLETE:
      return {
        ...state,
        searchPcmsoAutocomplete: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.searchPcmsoAutocomplete.data,
          receivedAt: state.searchPcmsoAutocomplete.receivedAt,
        }
      }
    case RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE:
      return {
        ...state,
        searchPcmsoAutocomplete: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CARGOS_APROVACAO:
      return {
        ...state,
        cargosAprovacao: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.cargosAprovacao.data,
          receivedAt: state.cargosAprovacao.receivedAt,
        }
      }
    case RECEIVE_CARGOS_APROVACAO:
      return {
        ...state,
        cargosAprovacao: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_EXAME.type:
      return INITIAL_STATE
    default:
      return state
  }
}