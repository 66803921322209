import { REQUEST_ESTADOS, RECEIVE_ESTADOS, REQUEST_CIDADES, RECEIVE_CIDADES,
  RESET_ALL_ENDERECO  } from '../constants/ActionTypes'

const INITIAL_STATE = {
  estados: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  cidades: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
}

export default function EnderecoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ESTADOS:
      return {
        ...state,
        estados: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.estados.data,
        }
      }
    case RECEIVE_ESTADOS:
      return {
        ...state,
        estados: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CIDADES:
      return {
        ...state,
        cidades: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.cidades.data,
        }
      }
    case RECEIVE_CIDADES:
      return {
        ...state,
        cidades: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_ENDERECO:
      return INITIAL_STATE
    default:
      return state
  }
}