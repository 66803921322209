import {
  REQUEST_ALL_PACIENTES, RECEIVE_ALL_PACIENTES, REQUEST_PACIENTES, RECEIVE_PACIENTES, REQUEST_PACIENTE,
  RECEIVE_PACIENTE, REQUEST_PACIENTE_CALL_STATUS, RECEIVE_PACIENTE_CALL_STATUS, REQUEST_PACIENTE_PRONTUARIO,
  RECEIVE_PACIENTE_PRONTUARIO, RESET_PACIENTE, REQUEST_VALIDATE_PACIENTE, RECEIVE_VALIDATE_PACIENTE,
  REQUEST_SAVE_PACIENTE, REQUEST_SAVE_PACIENTE_ATENDIMENTO, RECEIVE_SAVE_PACIENTE_ATENDIMENTO, RECEIVE_SAVE_PACIENTE,
  REQUEST_CHANGE_PASSWD_PACIENTE, RECEIVE_CHANGE_PASSWD_PACIENTE, REQUEST_SAVE_ANEXOS_PACIENTE,
  RECEIVE_SAVE_ANEXOS_PACIENTE, REQUEST_REMOVE_ANEXOS_PACIENTE, RECEIVE_REMOVE_ANEXOS_PACIENTE,
  REQUEST_CERT_SIGN_ANEXO_PACIENTE, RECEIVE_CERT_SIGN_ANEXO_PACIENTE,
  REQUEST_PACIENTE_CANCEL_AGENDAMENTO, RECEIVE_PACIENTE_CANCEL_AGENDAMENTO, REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO,
  RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO, REQUEST_PACIENTE_RETORNO_AGENDAMENTO, RECEIVE_PACIENTE_RETORNO_AGENDAMENTO,
  REQUEST_CHECK_TIME_AVAILABILITY, RECEIVE_CHECK_TIME_AVAILABILITY, REQUEST_ACCESS_PROTOCOL, RECEIVE_ACCESS_PROTOCOL,
  REQUEST_ALL_EMPRESAS, RECEIVE_ALL_EMPRESAS, REQUEST_SAVE_SIGNATURE_PACIENTE, RECEIVE_SAVE_SIGNATURE_PACIENTE,
  RESET_ALL_PACIENTE
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  validate: {
    isLoading: false,
  },
  item: {
    isLoading: false,
    data: {},
    requestedAt: null,
    receivedAt: null,
    saveAsSuccess: false,
  },
  callStatus: {
    isLoading: false,
    data: {
      online: false
    },
    receivedAt: null,
  },
  prontuario: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveAtendimento: {
    isLoading: false,
  },
  changePasswd: {
    isLoading: false,
  },
  saveAnexos: {
    isLoading: false,
  },
  certSignAnexo: {
    isLoading: false,
  },
  removeAnexos: {
    isLoading: false,
  },
  cancelAgendamento: {
    isLoading: false,
  },
  rescheduleAgendamento: {
    isLoading: false,
  },
  retornoAgendamento: {
    isLoading: false,
  },
  checkTimeAvailability: {
    isLoading: false,
    data: {
      available: null
    },
  },
  accessProtocol: {
    isLoading: false,
    data: null,
  },
  empresas: {
    isLoading: false,
    data: {
      rows: [],
    },
    receivedAt: null,
  },
  signature: {
    isLoading: false,
  },
}

export default function PacienteReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_PACIENTES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_PACIENTES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PACIENTES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_PACIENTES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PACIENTE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_PACIENTE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PACIENTE_CALL_STATUS.type:
      return {
        ...state,
        callStatus: {
          isLoading: action.isLoading,
          data: state.callStatus.data,
          receivedAt: state.callStatus.receivedAt,
        }
      }
    case RECEIVE_PACIENTE_CALL_STATUS.type:
      return {
        ...state,
        callStatus: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_PACIENTE:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case REQUEST_PACIENTE_PRONTUARIO.type:
      return {
        ...state,
        prontuario: {
          isLoading: action.isLoading,
          // data: INITIAL_STATE.prontuario.data,
          data: state.prontuario.data,
        }
      }
    case RECEIVE_PACIENTE_PRONTUARIO.type:
      return {
        ...state,
        prontuario: {
          isLoading: action.isLoading,
          data: (action.data) ? action.data : INITIAL_STATE.prontuario.data,
        }
      }
    case REQUEST_VALIDATE_PACIENTE:
    case RECEIVE_VALIDATE_PACIENTE:
      return {
        ...state,
        validate: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PACIENTE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_PACIENTE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
          saveAsSuccess: action.saveAsSuccess,
        }
      }
    case REQUEST_SAVE_PACIENTE_ATENDIMENTO.type:
    case RECEIVE_SAVE_PACIENTE_ATENDIMENTO.type:
      return {
        ...state,
        saveAtendimento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CHANGE_PASSWD_PACIENTE.type:
    case RECEIVE_CHANGE_PASSWD_PACIENTE.type:
      return {
        ...state,
        changePasswd: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_ANEXOS_PACIENTE.type:
    case RECEIVE_SAVE_ANEXOS_PACIENTE.type:
      return {
        ...state,
        saveAnexos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_ANEXOS_PACIENTE.type:
    case RECEIVE_REMOVE_ANEXOS_PACIENTE.type:
      return {
        ...state,
        removeAnexos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CERT_SIGN_ANEXO_PACIENTE:
    case RECEIVE_CERT_SIGN_ANEXO_PACIENTE:
      return {
        ...state,
        certSignAnexo: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PACIENTE_CANCEL_AGENDAMENTO.type:
    case RECEIVE_PACIENTE_CANCEL_AGENDAMENTO.type:
      return {
        ...state,
        cancelAgendamento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PACIENTE_RESCHEDULE_AGENDAMENTO.type:
    case RECEIVE_PACIENTE_RESCHEDULE_AGENDAMENTO.type:
      return {
        ...state,
        rescheduleAgendamento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PACIENTE_RETORNO_AGENDAMENTO.type:
    case RECEIVE_PACIENTE_RETORNO_AGENDAMENTO.type:
      return {
        ...state,
        retornoAgendamento: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CHECK_TIME_AVAILABILITY.type:
    case RECEIVE_CHECK_TIME_AVAILABILITY.type:
      return {
        ...state,
        checkTimeAvailability: {
          isLoading: action.isLoading,
          data: action.data,
        }
      }
    case REQUEST_ACCESS_PROTOCOL:
    case RECEIVE_ACCESS_PROTOCOL:
      return {
        ...state,
        accessProtocol: {
          isLoading: action.isLoading,
          data: action.data,
        }
      }
    case REQUEST_ALL_EMPRESAS:
      return {
        ...state,
        empresas: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.empresas.data,
        }
      }
    case RECEIVE_ALL_EMPRESAS:
      return {
        ...state,
        empresas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_SIGNATURE_PACIENTE:
    case RECEIVE_SAVE_SIGNATURE_PACIENTE:
      return {
        ...state,
        signature: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_PACIENTE:
      return INITIAL_STATE
    default:
      return state
  }
}
