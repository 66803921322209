import {
  REQUEST_QUESTIONARIO, RECEIVE_QUESTIONARIO, REQUEST_SAVE_QUESTIONARIO, RECEIVE_SAVE_QUESTIONARIO,
  RESET_QUESTIONARIO, REQUEST_QUESTIONARIO_RESPONDER, RECEIVE_QUESTIONARIO_RESPONDER, RESET_ALL_QUESTIONARIO,
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
  responder: {
    isLoading: false,
  },
}

export default function QuestionarioReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_QUESTIONARIO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_QUESTIONARIO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_QUESTIONARIO.type:
    case RECEIVE_SAVE_QUESTIONARIO.type:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_QUESTIONARIO_RESPONDER.type:
    case RECEIVE_QUESTIONARIO_RESPONDER.type:
      return {
        ...state,
        responder: {
          isLoading: action.isLoading,
        }
      }
    case RESET_QUESTIONARIO.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_QUESTIONARIO.type:
      return INITIAL_STATE
    default:
      return state
  }
}
