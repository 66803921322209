// third party
import fetch from 'cross-fetch'
// utils
import RequestUtils from '../utils/RequestUtils'
// cosntants
import { API_HOST } from '../constants/EnvTypes'
import { REQUEST_NOTAS, RECEIVE_NOTAS, REQUEST_NOTA, RECEIVE_NOTA, RESET_ALL_NOTA } from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'


function requestNotas() {
  return {
    type: REQUEST_NOTAS,
    isLoading: true,
  }
}

function receiveNotas(data) {
  return {
    type: RECEIVE_NOTAS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchNotas(params = { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestNotas())
    const endPoint = `${API_HOST}/nfse`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar notas!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar notas!',
          err500: 'Erro ao consultar notas!',
          errDefault: 'Erro desconhecido ao consultar notas!'
        })
      })
      .then(json => {
        dispatch(receiveNotas(json))
        callback(true)
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(false)
      })
  }
}

function requestNota() {
  return {
    type: REQUEST_NOTA,
    isLoading: true,
  }
}

function receiveNota(data) {
  return {
    type: RECEIVE_NOTA,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchNota(id) {
  return (dispatch, getState) => {
    dispatch(requestNota())
    const url = `${API_HOST}/nfse/${id}`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar nota!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar nota!',
          err500: 'Erro ao consultar nota!',
          errDefault: 'Erro desconhecido ao consultar nota!'
        })
      })
      .then(json => dispatch(receiveNota(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

/**
 * Marca nota como impressa.
 *
 * @param {Number} idNfse
 */
 export function fetchCheckPrintNfse(idNfse) {
  return (dispatch, getState) => {
    dispatch(requestNota())
    const url = `${API_HOST}/nfse/${idNfse}/check-print`
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao marcar impressão de nota!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar nota!',
          err500: 'Erro ao consultar nota!',
          errDefault: 'Erro desconhecido ao consultar nota!'
        })
      })
      .then(json => dispatch(receiveNota(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

/**
 * Abre nova guia com nota para impressão.
 * Não altera estado da aplicação.
 *
 * @param {Number} idNfse
 */
export function fetchPrintNfse(idNfse) {
  return (dispatch, getState) => {
    let state = getState()
    let jwt = state.auth.jwt
    let url = `${API_HOST}/nfse/${idNfse}/pdf?jwt=${jwt}`
    window.open(url, '_blank')
    dispatch(addToast('success', 'NFS-e aberta em nova guia.', null, true))
  }
}

export function resetAllNota() {
  return {
    type: RESET_ALL_NOTA,
  }
}
