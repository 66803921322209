// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_PUBLIC_CLINICA_CONTEXT, RECEIVE_PUBLIC_CLINICA_CONTEXT, RESET_ALL_CONTEXT
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'

function requestPublicClinicaContext() {
  return {
    type: REQUEST_PUBLIC_CLINICA_CONTEXT.type,
    isLoading: true,
  }
}

function receivePublicClinicaContext(data) {
  return {
    type: RECEIVE_PUBLIC_CLINICA_CONTEXT.type,
    isLoading: false,
    context: data && data.context ? data.context : null,
    contextMedico: data && data.contextMedico ? data.contextMedico : null,
  }
}

export function fetchPublicClinicaContext(idClinica, additionals) {
  return dispatch => {
    dispatch(requestPublicClinicaContext())
    let query = ''
    if (additionals) query = `?additionals=${encodeURIComponent(JSON.stringify(additionals))}`
    request.get(`${API_HOST}/context/clinica/${idClinica}/public${query}`).then(json => {
      const res = additionals ? { contextMedico: json.context } : { context: json.context }
      dispatch(receivePublicClinicaContext(res))
    }).catch(err => {
      console.error(err)
      dispatch(addToast('error', 'Erro ao obter URL pública da clínica', null, true))
      dispatch(receivePublicClinicaContext(null))
    })
  }
}

export function resetAllContext() {
  return {
    type: RESET_ALL_CONTEXT.type,
  }
}
