import { REQUEST_BANCOS, RECEIVE_BANCOS, RESET_ALL_BANCO } from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
}

export default function BancoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_BANCOS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_BANCOS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_BANCO.type:
      return INITIAL_STATE
    default:
      return state
  }
}
