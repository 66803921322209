import superagent from 'superagent'
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_EXAMES, RECEIVE_EXAMES, REQUEST_EXAME, RECEIVE_EXAME, REQUEST_EXAMES_BY_PACIENTE,
  RECEIVE_EXAMES_BY_PACIENTE, REQUEST_SAVE_EXAME, RECEIVE_SAVE_EXAME, REQUEST_SAVE_PRE_LAUDO, RECEIVE_SAVE_PRE_LAUDO,
  SHOW_CONFIRM_LAUDAR, HIDE_CONFIRM_LAUDAR, REQUEST_CHANGE_ESPECIALIDADE_EXAME, RECEIVE_CHANGE_ESPECIALIDADE_EXAME,
  REQUEST_ORIENTAR_EXAME, RECEIVE_ORIENTAR_EXAME, START_LAUDAR, FINISH_LAUDAR, UPDATE_MEDICO_AVAILABLE_EXAMES,
  RESET_EXAME, RESET_ALL_EXAME, REQUEST_VERIFICA_PODE_LAUDAR, REQUEST_INICIAR_PRE_LAUDO, REQUEST_INICIAR_LAUDO,
  REQUEST_REMOVE_EXAME, RECEIVE_REMOVE_EXAME, REQUEST_DEVOLVER_FILA, REQUEST_SAVE_ANEXOS_ADICIONAIS,
  RECEIVE_SAVE_ANEXOS_ADICIONAIS, REQUEST_PARTES_CORPO, RECEIVE_PARTES_CORPO, REQUEST_PDF_HEADER_FOOTER,
  RECEIVE_PDF_HEADER_FOOTER, REQUEST_DICOM_RESOURCES, RECEIVE_DICOM_RESOURCES, RESET_DICOM_RESOURCES,
  REQUEST_SOLICITAR_REVISAO, RECEIVE_SOLICITAR_REVISAO, REQUEST_ENCAMINHAR_REVISAO, RECEIVE_ENCAMINHAR_REVISAO,
  REQUEST_JUSTIFICAR_REVISAO, RECEIVE_JUSTIFICAR_REVISAO, REQUEST_REMOVER_REVISAO, RECEIVE_REMOVER_REVISAO,
  REQUEST_LAUDOS_ANTERIORES, RECEIVE_LAUDOS_ANTERIORES, REQUEST_REMOVE_EXAME_ANEXO, RECEIVE_REMOVE_EXAME_ANEXO,
  REQUEST_ACCEPT_EXAME_ANEXO, RECEIVE_ACCEPT_EXAME_ANEXO, REQUEST_ADD_EXAME_ANEXOS, RECEIVE_ADD_EXAME_ANEXOS,
  REQUEST_REPLACE_EXAME_ANEXO, RECEIVE_REPLACE_EXAME_ANEXO, REQUEST_EXAME_ANEXOS_ANALISE, RECEIVE_EXAME_ANEXOS_ANALISE,
  REQUEST_SAVE_EXAME_ANEXO_ANALISE, RECEIVE_SAVE_EXAME_ANEXO_ANALISE, REQUEST_EXAME_ANEXOS_ESTATISTICAS,
  RECEIVE_EXAME_ANEXOS_ESTATISTICAS, REQUEST_SEARCH_PCMSO_AUTOCOMPLETE, RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE,
  REQUEST_CARGOS_APROVACAO, RECEIVE_CARGOS_APROVACAO, REQUEST_ASO_LABELS_CLINICA, RECEIVE_ASO_LABELS_CLINICA
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { resetAllLaudo } from './LaudoActions'
import { fetchClinica } from './ClinicaActions'
import { fetchEntityInformacoesAdicionais } from './AuthActions'
import { showNotification } from './NotificationActions'
import { showRequestError } from './AppActions'
// utils
import { query2Object } from '../utils/URLUtils'
import { encodeLower } from '../utils/Base64Utils'

export function updateMedicoAvailableExames(exameIds) {
  return (dispatch, getState) => {
    let availableExames = getState().exame.medicoAvailableExames
    availableExames.ids = exameIds
    // veriifica se ja passou o intervalo de tempo mínimo desde a ultima notificação
    let path = window.location.pathname
    if (exameIds.length > 0 && (path !== '/exame/meus_disponiveis' && path !== '/exame/disponiveis')) {
      availableExames.lastNotification = Date.now() // atualiza timestamp da ultima notificação
      // show notification
      let count = availableExames.ids.length
      let title = (count > 1) ? 'Novos exames na fila' : 'Novo exame na fila'
      let body = (count > 1)
        ? `Exstem ${count} novos exames na fila diponíveis para laudar`
        : `Existe ${count} novo exame na fila diponível para laudar`
      dispatch(showNotification(title, body, () => {
        if (typeof window != 'undefined') { // em ambiente de teste nao existe
          window.router.push('/exame/disponiveis')
        }
      }))
    }
    dispatch(changeMedicoAvailableExames(availableExames))
  }
}

function changeMedicoAvailableExames(availableExames) {
  return {
    type: UPDATE_MEDICO_AVAILABLE_EXAMES.type,
    medicoAvailableExames: availableExames
  }
}

function requestExames(silent) {
  return {
    type: REQUEST_EXAMES.type,
    isLoading: !silent,
    isSilent: silent,
  }
}

function receiveExames(data) {
  return {
    type: RECEIVE_EXAMES.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega listagem de exames.
 *
 * @param {Object} params Parâmetros de busca e ordenação. (page, limit, orderBy, orderDirection, criteria)
 * @param {Boolean} silent Indica se o carregamento deve ser silencioso (não exibir carregando).
 */
export function fetchExames(params = { page: 1, limit: 50, orderBy: 'id', orderDirection: 'DESC' }, silent = false,  callback = () => {}) {
  return (dispatch, getState) => {
    let state = getState()
    let appOnline = state.app.appOnline
    if (appOnline) {
      dispatch(requestExames(silent))
      const requestId = REQUEST_EXAMES.id
      request.get(`${API_HOST}/exame`, params, { requestId: requestId }).then(json => {
        dispatch(receiveExames(json))
        callback(true)
      }).catch(err => {
        if (!silent) dispatch(showRequestError(err, requestId))
        callback(false)
      })
    } else {
      console.log('DISCARD fetchExames')
    }
  }
}

function requestExameAnexosEstatisticas() {
  return {
    type: REQUEST_EXAME_ANEXOS_ESTATISTICAS,
    isLoading: true,
  }
}

function receiveExameAnexosEstatisticas(data) {
  return {
    type: RECEIVE_EXAME_ANEXOS_ESTATISTICAS,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Obtem estatísticas de anexos de exames.
 */
export function fetchExameAnexosEstatisticas(callback = () => {}) {
  return dispatch => {
    dispatch(requestExameAnexosEstatisticas())
    request.get(`${API_HOST}/exame/anexos-analise/estatisticas`).then(json => {
      dispatch(receiveExameAnexosEstatisticas(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
      dispatch(receiveExameAnexosEstatisticas(null))
    })
  }
}

function requestExameAnexosAnalise() {
  return {
    type: REQUEST_EXAME_ANEXOS_ANALISE,
    isLoading: true,
  }
}

function receiveExameAnexosAnalise(data) {
  return {
    type: RECEIVE_EXAME_ANEXOS_ANALISE,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega listagem de anexos de exames para analise.
 *
 * @param {Object} params Parâmetros de busca e ordenação. (page, limit, orderBy, orderDirection, criteria)
 */
export function fetchExameAnexosAnalise(params = { page: 1, limit: 30, orderBy: 'created_at', orderDirection: 'ASC' },  callback = () => {}) {
  return dispatch => {
    dispatch(requestExameAnexosAnalise())
    request.get(`${API_HOST}/exame/anexos-analise`, params).then(json => {
      dispatch(receiveExameAnexosAnalise(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
      dispatch(receiveExameAnexosAnalise(null))
    })
  }
}

function requestSaveExameAnexoAnalise() {
  return {
    type: REQUEST_SAVE_EXAME_ANEXO_ANALISE,
    isLoading: true,
  }
}

function receiveSaveExameAnexoAnalise() {
  return {
    type: RECEIVE_SAVE_EXAME_ANEXO_ANALISE,
    isLoading: false,
  }
}

/**
 * Persiste analise de anexo do exame.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveExameAnexoAnalise(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveExameAnexoAnalise())
    const url = `${API_HOST}/exame/anexo-analise/${id}`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      // dispatch(addToast('success', 'Anexo salvo com sucesso.', null, true))
      dispatch(receiveSaveExameAnexoAnalise())
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receiveSaveExameAnexoAnalise())
      callback(err)
    })
  }
}

export function resetExame() {
  return {
    type: RESET_EXAME.type,
  }
}

function requestExame(silent) {
  return {
    type: REQUEST_EXAME.type,
    isLoading: (silent) ? false : true,
    isSilent: (silent) ? true : false,
  }
}

function receiveExame(data) {
  return {
    type: RECEIVE_EXAME.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Obtem um exame por ID.
 *
 * @param {Number} params ID do exame.
 * @param {Boolean} silent Indica se o carregamento deve ser silencioso (não exibir carregando).
 */
export function fetchExame(id, silent = false, callback = () => {}) {
  return dispatch => {
    dispatch(requestExame(silent))
    const requestId = REQUEST_EXAME.id
    request.get(`${API_HOST}/exame/${id}`, {}, { requestId: requestId }).then(json => {
      dispatch(receiveExame(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(err)
    })
  }
}

function requestExamesByPaciente(idPaciente) {
  return {
    type: REQUEST_EXAMES_BY_PACIENTE.type,
    isLoading: true,
    idPaciente: idPaciente,
  }
}

function receiveExamesByPaciente(data) {
  return {
    type: RECEIVE_EXAMES_BY_PACIENTE.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchExamesByPaciente(idPaciente, params = { page: 1, limit: 200 }, callback = () => {}) {
  return dispatch => {
    dispatch(requestExamesByPaciente(idPaciente))
    const requestId = REQUEST_EXAMES_BY_PACIENTE.id
    const url = `${API_HOST}/exame/paciente/${idPaciente}`
    request.get(url, params, { requestId: requestId }).then(json => {
      dispatch(receiveExamesByPaciente(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestLaudosAnteriores() {
  return {
    type: REQUEST_LAUDOS_ANTERIORES,
    isLoading: true,
  }
}

function receiveLaudosAnteriores(data = []) {
  return {
    type: RECEIVE_LAUDOS_ANTERIORES,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchLaudosAnteriores(idExame) {
  return dispatch => {
    dispatch(requestLaudosAnteriores())
    const url = `${API_HOST}/exame/${idExame}/laudos-anteriores`
    request.get(url).then(json => {
      dispatch(receiveLaudosAnteriores(json))
    }).catch(err => {
      dispatch(receiveLaudosAnteriores())
      dispatch(showRequestError(err))
    })
  }
}

function requestSaveExame() {
  return {
    type: REQUEST_SAVE_EXAME.type,
    isLoading: true,
  }
}

function receiveSaveExame(data) {
  return {
    type: RECEIVE_SAVE_EXAME.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados do exame. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveExame(id, data, callback = () => {}) {
  return (dispatch, getState) => {
    let userTipo = getState().auth.jwtPayload.user.type
    // preserva para mergear com dados atualizados, pois algumas informações carregadas anteriormente não são
    // recarregadas como laudos
    let currentExame = getState().exame.item.data
    dispatch(requestSaveExame())
    const requestId = REQUEST_SAVE_EXAME.id
    const url = (id) ? `${API_HOST}/exame/${id}` :`${API_HOST}/exame`
    const method = (id) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data), { requestId: requestId }).then(json => {
      let mergedExame = {}
      if (userTipo === 'clinica' || userTipo === 'admin') {
        dispatch(addToast('success', 'Exame enviado com sucesso.', null, true))
        mergedExame = {...currentExame, ...json}
        dispatch(receiveSaveExame(mergedExame))
        if (userTipo === 'clinica') dispatch(fetchClinica(getState().auth.jwtPayload.user.entityId))
      }
      dispatch(fetchEntityInformacoesAdicionais())
      callback(null, mergedExame)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      dispatch(receiveSaveExame(currentExame))
      callback(err)
    })
  }
}

/**
 * Persiste os dados complementares de risco cirurgico do exame.
 *
 * @param {Number} id
 * @param {Object} data
 */
export function saveExameRiscoCirurgicoComplemento(id, data, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/exame/${id}/risco-cirurgico-complemento`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(fetchExame(id, false, callback))
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

/**
 * Persiste os dados complementares de psicologia do trabalho do exame.
 *
 * @param {Number} id
 * @param {Object} data
 */
 export function saveExamePsicoComplemento(id, data, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/exame/${id}/psico-complemento`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(fetchExame(id, false, callback))
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestSavePreLaudo() {
  return {
    type: REQUEST_SAVE_PRE_LAUDO.type,
    isLoading: true,
  }
}

function receiveSavePreLaudo(data) {
  return {
    type: RECEIVE_SAVE_PRE_LAUDO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste os dados do pre-laudo do técnico,
 *
 * @param {Object} data
 */
export function savePreLaudo(idExame, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSavePreLaudo())
    const requestId = REQUEST_SAVE_PRE_LAUDO.id
    const url = `${API_HOST}/exame/${idExame}/pre-laudo-tecnico`
    request.put(url, {}, JSON.stringify(data), { requestId: requestId }).then(json => {
      dispatch(addToast('success', 'Pré-laudo enviado com sucesso.', null, true))
      dispatch(receiveSavePreLaudo(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestChangeEspecialidadeExame() {
  return {
    type: REQUEST_CHANGE_ESPECIALIDADE_EXAME.type,
    isLoading: true,
  }
}

function receiveChangeEspecialidadeExame(data) {
  return {
    type: RECEIVE_CHANGE_ESPECIALIDADE_EXAME.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

/**
 * Altera especialidade do exame.
 *
 * @param {Number} idExame
 * @param {Number} idEspecialidade
 */
export function changeEspecialidadeExame(idExame, idEspecialidade, callback = () => {}) {
  const data = {
    id_especialidade: idEspecialidade
  }
  return dispatch => {
    dispatch(requestChangeEspecialidadeExame())
    const requestId = REQUEST_CHANGE_ESPECIALIDADE_EXAME.id
    const url = `${API_HOST}/exame/${idExame}/change-especialidade`
    request.put(url, {}, JSON.stringify(data), { requestId: requestId }).then(json => {
      dispatch(addToast('success', 'Especialidade do exame alterada com sucesso.', null, true))
      dispatch(receiveChangeEspecialidadeExame())
      dispatch(fetchExame(idExame))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestOrientar() {
  return {
    type: REQUEST_ORIENTAR_EXAME.type,
    isLoading: true,
  }
}

function receiveOrientarExame() {
  return {
    type: RECEIVE_ORIENTAR_EXAME.type,
    isLoading: false,
  }
}

/**
 * Orientar exame mal feito..
 *
 * @param {Number} idExame
 * @param {String} orientacao
 */
export function fetchOrientarExame(idExame, data_orientacao, callback = () => {}) {
  const data = {
    orientacao: data_orientacao.orientacao,
    id_texto_orientacao: data_orientacao.id_texto_orientacao
  }
  return dispatch => {
    dispatch(requestOrientar())
    const requestId = REQUEST_ORIENTAR_EXAME.id
    const url = `${API_HOST}/exame/${idExame}/orientar`
    request.put(url, {}, JSON.stringify(data), { requestId: requestId }).then(json => {
      dispatch(receiveOrientarExame())
      dispatch(fetchEntityInformacoesAdicionais())
      dispatch(addToast('success', 'Exame orientado com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

/**
 * Abre uma imagem com ferramentas de manipulação (briloho, contraste, zoom, etc).
 *
 * @param {String} file
 */
export function fetchImgTools(file) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/image-tools?file=${file}&entity=exame&jwt=${jwt}`
    window.open(url, '_blank')
  }
}
/**
 * Abre uma imagem normal com funcao de girar somente.
 * @param {String} file
 */
export function fetchImgNormal(file) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/image-normal?file=${file}&entity=exame&jwt=${jwt}`
    window.open(url, '_blank')
  }
}

export function fetchPrintLaudo(idLaudo, params) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/laudo/${idLaudo}/pdf?jwt=${jwt}${params ? params : ''}`
    window.open(url, '_blank')
  }
}

export function fetchPrintSolicitacaoRiscoCirurgico(idExame) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/exame/${idExame}/pdf-risco-cirurgico?jwt=${jwt}`
    window.open(url, '_blank')
  }
}

export function fetchPrintLaudoPcmso(idLaudo, params) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/laudo/${idLaudo}/pdf-pcmso?jwt=${jwt}${params ? params : ''}`
    window.open(url, '_blank')
  }
}

export function fetchPrintLaudoTranslate(idLaudo, translate) {
  return (dispatch, getState) => {
    let jwt = getState().auth.jwt
    let url = `${API_HOST}/laudo/${idLaudo}/pdf-translate?jwt=${jwt}`
    superagent
      .post(url)
      .set('content-type', 'application/x-www-form-urlencoded')
      .send({'translate': translate})
      .responseType('blob')
      .end((err, res) => {
        if (err) {
          dispatch(addToast('error', 'Não foi possível imprimir PDF', null, true))
          return
        }
        if (res.body) {
          var blob = new Blob([res.body], { type: 'application/pdf' })

          var fileURL = URL.createObjectURL(blob)
          window.open(fileURL, '_blank')
        } else {
          dispatch(addToast('error', 'Não foi possível imprimir PDF', null, true))
        }
      })
  }
}

function showConfirmLaudar(idExame, data) {
  return {
    type: SHOW_CONFIRM_LAUDAR.type,
    message: data.userMessage,
    idExame: idExame
  }
}

export function hideConfirmLaudar() {
  return {
    type: HIDE_CONFIRM_LAUDAR.type
  }
}

/**
 * Verifica se exame está disponível para laudar.
 *
 * @param {Number} idExame
 * @param {Boolean} retry Indica que é retentativa do próximo da fila. Evitar loop infinito
 */
function fetchVerificaPodeLaudar(idExame, retry = false) {
  return (dispatch, getState) => {
    let isMedicoChefe = (getState().auth.loggedEntity.data.chefe == 'Sim') ? true : false
    const requestId = REQUEST_VERIFICA_PODE_LAUDAR.id
    const url = `${API_HOST}/exame/${idExame}/pode-laudar`
    request.get(url, { retry }, { requestId: requestId }).then(json => {
      if (json.result === 'OK') {
        dispatch(fetchIniciarLaudo(idExame))
      } else if (json.id_exame) {
        if (retry) {
          // já foi retentativa! Exibe mensagem de erro e termina aqui para evitar loop infinito
          dispatch(addToast('info', json.userMessage, 'ENTENDI', false))
        } else {
          dispatch(fetchVerificaPodeLaudar(json.id_exame, true))
        }
      } else if (json.result === 'NOK' && json.toast) {
        dispatch(addToast('info', json.userMessage, 'ENTENDI', false))
      } else if (json.result === 'NOK' && isMedicoChefe) {
        dispatch(showConfirmLaudar(idExame, json))
      } else if (json.result === 'NOK' && !isMedicoChefe) {
        dispatch(addToast('error', json.userMessage, null, true))
        dispatch(fetchEntityInformacoesAdicionais())
      }
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      dispatch(fetchEntityInformacoesAdicionais())
    })
  }
}

/**
 * Verifica se exame está disponível para clinica adicionar anexos antes do médico iniciar laudo. Regra:
 * ( status === 10 || ((status === 1 || status === 4) && especialidade === 67) ) )
 *
 * @param {Number} idExame
 */
export function fetchVerificaPodeAnexosAdicionais(idExame, callback = () => {}) {
  return (dispatch) => {
    const url = `${API_HOST}/exame/${idExame}/pode-anexos-adicionais`
    request.get(url).then(json => {
      if (json.result === 'OK') {
        dispatch(fetchExame(idExame, true))
        callback()
      } else {
        callback(new Error('Não permitido'))
      }
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

/**
 * Tecnico inicia preparação do exame.
 */
export function fetchIniciarPreLaudo(idExame, callback = () => {}) {
  return dispatch => {
    dispatch(resetAllExame())
    dispatch(resetAllLaudo())
    const requestId = REQUEST_INICIAR_PRE_LAUDO.id
    const url = `${API_HOST}/exame/${idExame}/iniciar-pre-laudo`
    request.put(url, {}, '', { requestId: requestId }).then(json => {
      dispatch(startLaudar(idExame))
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        setTimeout(() => {
          window.router.push(`/exame/${idExame}/laudar`)
        }, 200)
      }
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      dispatch(fetchEntityInformacoesAdicionais())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push('/')
      }
      callback(false)
    })
  }
}

/**
 * Seta estado do exame para sendo laudado e redireciona para a página de laudo.
 *
 * @param {Number} idExame ID do exame a ser laudado.
 * @param {Boolean} changeStatus Indica que o status deve ser alterado para "sendo laudado"
 */
export function fetchLaudar(idExame) {
  return (dispatch) => {
    dispatch(fetchVerificaPodeLaudar(idExame))
  }
}

function startLaudar(idExame) {
  return {
    type: START_LAUDAR.type,
    idExame: idExame,
  }
}

export function finishLaudar() {
  return {
    type: FINISH_LAUDAR.type,
  }
}

export function fetchIniciarLaudo(idExame, callback = () => {}) {
  return dispatch => {
    dispatch(resetAllExame())
    dispatch(resetAllLaudo())
    const requestId = REQUEST_INICIAR_LAUDO.id
    const url = `${API_HOST}/exame/${idExame}/iniciar-laudo`
    request.put(url, {}, '', { requestId: requestId }).then(json => {
      dispatch(startLaudar(idExame))
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        setTimeout(() => {
          window.router.push(`/exame/${idExame}/laudar`)
        }, 200)
      }
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      dispatch(fetchEntityInformacoesAdicionais())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push('/')
      }
      callback(false)
    })
  }
}

/**
 * Devolve um exame para a fila.
 *
 * @param {Number} idExame ID do exame a ser laudado.
 */
export function fetchDevolverFila(idExame, callback = () => {}) {
  return dispatch => {
    const requestId = REQUEST_DEVOLVER_FILA.id
    const url = `${API_HOST}/exame/${idExame}/devolver-fila`
    request.put(url, {}, '', { requestId: requestId }).then(json => {
      dispatch(addToast('success', 'Exame devolvido para fila com sucesso.', null, true))
      dispatch(fetchEntityInformacoesAdicionais())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

/**
 * Prioriza exame da fila.
 *
 * @param {Number} idExame ID do exame a ser laudado.
 */
export function fetchPriorizarExame(idExame, callback = () => {}) {
  return dispatch => {
    request.put(`${API_HOST}/exame/${idExame}/priorizar`).then(json => {
      dispatch(addToast('success', 'Exame priorizado com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Reseta médico.
 *
 * @param {Number} idExame ID do exame a ser resetado.
 */
export function fetchResetarMedico(idExame, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/exame/${idExame}/reset-medico`
    request.put(url).then(json => {
      dispatch(addToast('success', 'Médico desassociado com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

/**
 * Reseta imagem_md5 do exame para viabilizar envio de exame com mesma(a) imagens.
 *
 * @param {Number} idExame ID do exame a ser resetado.
 */
export function fetchResetarMD5(idExame, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/exame/${idExame}/reset-md5`
    request.put(url).then(json => {
      dispatch(addToast('success', 'Liberado envio de imagens do exame.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSolicitarRevisao() {
  return {
    type: REQUEST_SOLICITAR_REVISAO,
    isLoading: true,
  }
}

function receiveSolicitarRevisao() {
  return {
    type: RECEIVE_SOLICITAR_REVISAO,
    isLoading: false,
  }
}

export function fetchSolicitarRevisao(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSolicitarRevisao())
    const url = `${API_HOST}/exame/${id}/solicitar-revisao`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Revisão solicitada.', null, true))
      dispatch(receiveSolicitarRevisao())
      callback(true)
    }).catch(err => {
      dispatch(receiveSolicitarRevisao())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestEncaminharRevisao() {
  return {
    type: REQUEST_ENCAMINHAR_REVISAO,
    isLoading: true,
  }
}

function receiveEncaminharRevisao() {
  return {
    type: RECEIVE_ENCAMINHAR_REVISAO,
    isLoading: false,
  }
}

export function fetchEncaminharRevisao(id, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestEncaminharRevisao())
    const url = `${API_HOST}/exame/${id}/encaminhar-revisao`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Revisão encaminhada.', null, true))
      dispatch(receiveEncaminharRevisao())
      callback(true)
    }).catch(err => {
      dispatch(receiveEncaminharRevisao())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestJustificarRevisao() {
  return {
    type: REQUEST_JUSTIFICAR_REVISAO,
    isLoading: true,
  }
}

function receiveJustificarRevisao() {
  return {
    type: RECEIVE_JUSTIFICAR_REVISAO,
    isLoading: false,
  }
}

export function fetchJustificarRevisao(idExame, idRevisao, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestJustificarRevisao())
    const url = `${API_HOST}/exame/${idExame}/justificar-revisao/${idRevisao}`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Revisão encaminhada.', null, true))
      dispatch(receiveJustificarRevisao())
      callback(true)
    }).catch(err => {
      dispatch(receiveJustificarRevisao())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}


function requestRemoverRevisao() {
  return {
    type: REQUEST_REMOVER_REVISAO,
    isLoading: true,
  }
}

function receiveRemoverRevisao() {
  return {
    type: RECEIVE_REMOVER_REVISAO,
    isLoading: false,
  }
}

export function fetchRemoverRevisao(idExame, idRevisao, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoverRevisao())
    const url = `${API_HOST}/exame/${idExame}/revisao/${idRevisao}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Revisão removida.', null, true))
      dispatch(receiveRemoverRevisao())
      callback(true)
    }).catch(err => {
      dispatch(receiveRemoverRevisao())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveExame() {
  return {
    type: REQUEST_REMOVE_EXAME.type,
    isLoading: true,
  }
}

function receiveRemoveExame() {
  return {
    type: RECEIVE_REMOVE_EXAME.type,
    isLoading: false,
  }
}

export function fetchRemoveExame(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveExame())
    const requestId = REQUEST_REMOVE_EXAME.id
    const url = `${API_HOST}/exame/${id}`
    request.delete(url, {}, '', { requestId: requestId }).then(json => {
      dispatch(addToast('success', 'Exame removido com sucesso.', null, true))
      dispatch(receiveRemoveExame(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, requestId))
      callback(false)
    })
  }
}

function requestSaveAnexosAdicionais() {
  return {
    type: REQUEST_SAVE_ANEXOS_ADICIONAIS,
    isLoading: true,
  }
}

function receiveSaveAnexosAdicionais() {
  return {
    type: RECEIVE_SAVE_ANEXOS_ADICIONAIS,
    isLoading: false,
    receivedAt: Date.now(),
  }
}

/**
 * Persiste anexos adicionais enviados para o exame.
 *
 * @param {Number} idExame
 * @param {Object} data
 */
export function fetchSaveAnexosAdicionais(idExame, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestSaveAnexosAdicionais())
    const url = `${API_HOST}/exame/${idExame}/anexos-adicionais`
    request.put(url, {}, data).then(json => {
      dispatch(receiveSaveAnexosAdicionais(json))
      dispatch(fetchExame(idExame, true))
      // dispatch(addToast('success', 'Anexo(s) enviados com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err, REQUEST_SAVE_EXAME.id))
      callback(false)
    })
  }
}

function requestPartesCorpo() {
  return {
    type: REQUEST_PARTES_CORPO,
    isLoading: true,
  }
}

function receivePartesCorpo(data) {
  return {
    type: RECEIVE_PARTES_CORPO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Busca partes do corpo.
 */
export function fetchPartesCorpo(fromCache = true) {
  return (dispatch, getState) => {
    const isLoaded = (getState().exame.partesCorpo.receivedAt !== null)
    // carrega apenas uma vez
    if (! fromCache || ! isLoaded) {
      dispatch(requestPartesCorpo())
      const url = `${API_HOST}/exame/partes-corpo`
      request.get(url).then(json => {
        dispatch(receivePartesCorpo(json))
      }).catch(err => {
        dispatch(showRequestError(err))
      })
    }
  }
}

function requestAsoLabelsClinica() {
  return {
    type: REQUEST_ASO_LABELS_CLINICA,
    isLoading: true,
  }
}

function receiveAsoLabelsClinica(data) {
  return {
    type: RECEIVE_ASO_LABELS_CLINICA,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Busca labels personalizados por clínica para ASO.
 */
export function fetchAsoLabelsClinica(fromCache = true) {
  return (dispatch, getState) => {
    const isLoaded = (getState().exame.asoLabelsClinica.receivedAt !== null)
    // carrega apenas uma vez
    if (! fromCache || ! isLoaded) {
      dispatch(requestAsoLabelsClinica())
      const url = `${API_HOST}/exame/aso-labels-clinica`
      request.get(url).then(json => {
        dispatch(receiveAsoLabelsClinica(json))
      }).catch(err => {
        dispatch(showRequestError(err))
      })
    }
  }
}


function requestPdfHeaderFooter() {
  return {
    type: REQUEST_PDF_HEADER_FOOTER,
    isLoading: true,
  }
}

function receivePdfHeaderFooter(data) {
  return {
    type: RECEIVE_PDF_HEADER_FOOTER,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}


/**
 * Busca HTML de header e footer para doc PDF de impressão de imagens.
 */
export function fetchPdfHeaderFooter(idExame, callback = () => {}) {
  return dispatch => {
    dispatch(requestPdfHeaderFooter())
    const url = `${API_HOST}/exame/${idExame}/pdf-header-footer`
    request.get(url).then(json => {
      dispatch(receivePdfHeaderFooter(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(receivePdfHeaderFooter())
      callback(err)
    })
  }
}

function requestDicomResources() {
  return {
    type: REQUEST_DICOM_RESOURCES,
    isLoading: true,
  }
}

function receiveDicomResources(data, study) {
  return {
    type: RECEIVE_DICOM_RESOURCES,
    isLoading: false,
    data,
    study,
    receivedAt: Date.now(),
  }
}


/**
 * Busca informações do dicom de um determinado exame.
 *
 * @see https://book.orthanc-server.com/users/rest.html
 */
export function fetchDicomResources(exame, callback = () => {}) {
  console.log('fetchDicomResources', exame)
  return async (dispatch, getState) => {
    try {
      dispatch(requestDicomResources())
      const pacsServer = (process.env.REACT_APP_SERVER === 'ms') ? 'pacs.medicsystems.com.br' : '.telemedicinamorsch.com.br'
      const link = (exame.link_file && exame.link_file.indexOf(pacsServer) !== -1 && exame.link_file.indexOf('study') !== -1)
        ? exame.link_file
        : null

      if (!link) {
        dispatch(receiveDicomResources([]))
        callback(null, null, null)
        return
      }
      const url = new URL(link)
      const pacUrl = url.origin
      const search = url.search
      const searchObj = query2Object(search)
      const study = searchObj.study
      let jwt = encodeLower(getState().auth.jwt)

      console.log('study', study)

      // busca study
      // -------------------------------------------------------------------------
      console.log('--------------- STUDYS')
      const resStudy = await superagent.get(`${pacUrl}/studies/${study}?token=${jwt}`)
      const objStudy = JSON.parse(resStudy.text)
      console.log(objStudy)

      // consulta informações de series / instances
      // -------------------------------------------------------------------------
      const series = [] // {SeriesNumber: '1', ProtocolName: 'TORAX ROTINA (AR)/Thorax', instances: [...]}
      for (const idSerie of objStudy.Series) {
        const resSerie = await superagent.get(`${pacUrl}/series/${idSerie}?token=${jwt}`)
        const objSerie = JSON.parse(resSerie.text)
        console.log('SERIE', objSerie)
        series.push(objSerie)
      }
      // ordena por "MainDicomTags.SeriesNumber"
      series.sort((a, b) => (a.MainDicomTags.SeriesNumber - b.MainDicomTags.SeriesNumber))

      // percorre series (ordena e adiciona link)
      // -------------------------------------------------------------------------
      console.log('--------------- SERIES')
      for (let i = 0; i < series.length; i++) {
        const serie = series[i]
        const idSerie = serie.ID
        const resInstances = await superagent.get(`${pacUrl}/series/${idSerie}/instances?token=${jwt}`)
        const arrInstances = JSON.parse(resInstances.text)
        // ordena por "IndexInSeries"
        arrInstances.sort((a, b) => (a.IndexInSeries - b.IndexInSeries))

        const arrInstancesWithLink = arrInstances.map(item => ({ ...item, link: `${pacUrl}/instances/${item.ID}/preview?token=${jwt}`}))
        serie['instances'] = arrInstancesWithLink
      }

      console.log('--------------- PREVIEWS')
      console.log(series)
      console.log('---------------')

      dispatch(receiveDicomResources(series, objStudy))
      callback(null, series, objStudy)
    } catch(err) {
      dispatch(receiveDicomResources([], {}))
      callback(null, [], {})
    }
  }
}

export function resetDicomResources() {
  return {
    type: RESET_DICOM_RESOURCES,
  }
}

function requestRemoveExameAnexo() {
  return {
    type: REQUEST_REMOVE_EXAME_ANEXO,
    isLoading: true,
  }
}

function receiveRemoveExameAnexo() {
  return {
    type: RECEIVE_REMOVE_EXAME_ANEXO,
    isLoading: false,
  }
}

export function fetchRemoveExameAnexo(idExame, idExameAnexo, callback = () => {}) {
  return dispatch => {
    dispatch(requestRemoveExameAnexo())
    const url = `${API_HOST}/exame/${idExame}/anexos/${idExameAnexo}`
    request.delete(url).then(json => {
      dispatch(addToast('success', 'Anexo removido com sucesso.', null, true))
      dispatch(receiveRemoveExameAnexo())
      callback(true)
    }).catch(err => {
      dispatch(receiveRemoveExameAnexo())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAcceptExameAnexo() {
  return {
    type: REQUEST_ACCEPT_EXAME_ANEXO,
    isLoading: true,
  }
}

function receiveAcceptExameAnexo() {
  return {
    type: RECEIVE_ACCEPT_EXAME_ANEXO,
    isLoading: false,
  }
}

export function fetchAcceptExameAnexo(idExame, idExameAnexo, callback = () => {}) {
  return dispatch => {
    dispatch(requestAcceptExameAnexo())
    const url = `${API_HOST}/exame/${idExame}/anexos/${idExameAnexo}/accept`
    request.put(url).then(json => {
      dispatch(addToast('success', 'Anexo verificado com sucesso.', null, true))
      dispatch(receiveAcceptExameAnexo(json))
      callback(true)
    }).catch(err => {
      dispatch(receiveAcceptExameAnexo())
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestReplaceExameAnexo() {
  return {
    type: REQUEST_REPLACE_EXAME_ANEXO,
    isLoading: true,
  }
}

function receiveReplaceExameAnexo() {
  return {
    type: RECEIVE_REPLACE_EXAME_ANEXO,
    isLoading: false,
  }
}

export function fetchReplaceExameAnexo(idExame, fileName, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestReplaceExameAnexo())
    const url = `${API_HOST}/exame/${idExame}/anexos/${fileName}`
    request.put(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Anexo substituido com sucesso.', null, true))
      dispatch(receiveReplaceExameAnexo(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestAddExameAnexos() {
  return {
    type: REQUEST_ADD_EXAME_ANEXOS,
    isLoading: true,
  }
}

function receiveAddExameAnexos() {
  return {
    type: RECEIVE_ADD_EXAME_ANEXOS,
    isLoading: false,
  }
}

export function fetchAddExameAnexos(idExame, data, callback = () => {}) {
  return dispatch => {
    dispatch(requestAddExameAnexos())
    const url = `${API_HOST}/exame/${idExame}/anexos`
    request.post(url, {}, JSON.stringify(data)).then(json => {
      dispatch(addToast('success', 'Anexo(s) adicionado(s) com sucesso.', null, true))
      dispatch(receiveAddExameAnexos(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSearchPcmsoAutocomplete() {
  return {
    type: REQUEST_SEARCH_PCMSO_AUTOCOMPLETE,
    isLoading: true,
  }
}

function receiveSearchPcmsoAutocomplete(data) {
  return {
    type: RECEIVE_SEARCH_PCMSO_AUTOCOMPLETE,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega listagem de anexos de exames para analise.
 *
 * @param {Object} params Parâmetros de busca e ordenação. (page, limit, orderBy, orderDirection, criteria)
 */
export function fetchSearchPcmsoAutocomplete(table, field, search, callback = () => {}) {
  return dispatch => {
    dispatch(requestSearchPcmsoAutocomplete())
    request.get(`${API_HOST}/exame/pcmso/autocomplete/${table}/${field}?search=${search}`).then(json => {
      dispatch(receiveSearchPcmsoAutocomplete(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
      dispatch(receiveSearchPcmsoAutocomplete(null))
    })
  }
}

function requestCargosAprovacao() {
  return {
    type: REQUEST_CARGOS_APROVACAO,
    isLoading: true
  }
}

function receiveCargosAprovacao(data) {
  return {
    type: RECEIVE_CARGOS_APROVACAO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

/**
 * Carrega listagem de cargos para aprovação do admin para disponibilizar para todos.
 */
export function fetchCargosAprovacao(params = { page: 1, limit: 50 }, callback = () => {}) {
  return (dispatch) => {
    dispatch(requestCargosAprovacao())
    request.get(`${API_HOST}/exame/cargos-aprovacao`, params).then(json => {
      dispatch(receiveCargosAprovacao(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

export function fetchSaveCargoAprovacao(nome, isPublic, callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/exame/cargos-aprovacao`
    request.put(url, {}, JSON.stringify({ nome, isPublic })).then(json => {
      dispatch(addToast('success', 'Permissão alterada com sucesso.', null, true))
      callback()
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

export function fetchPsicoLinkPaciente(idExame, callback = () => {}) {
  return dispatch => {
    let url = `${API_HOST}/exame/${idExame}/questionarios-psico/link-paciente`
    request.get(url).then(json => {
      callback(null, json.link)
    }).catch(err => {
      dispatch(addToast('error', err.message, null, true))
      callback(err)
    })
  }
}

export function resetAllExame() {
  return {
    type: RESET_ALL_EXAME.type,
  }
}
