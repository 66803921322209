// third party
// import Cookies from 'js-cookie' // https://github.com/js-cookie/js-cookie
// actions
import { receiveSignIn, requestSignOut } from '../actions/AuthActions'
// import { initWebsocket } from 'actions/WebsocketActions'
import { getLocalStorage, getSessionStorage } from '../actions/StorageActions'

// cosntants
import { REQUEST_SIGN_IN, RECEIVE_SIGN_IN, RECEIVE_SIGN_OUT } from '../constants/ActionTypes'


/**
 * Middleware responsável por revalidar JWT da sessionStorage e deslogar caso não possua o token.
 */
export const verifyLogged = ({dispatch, getState}) => next => action => {
  // se esta inicializando uma session via chamada de URL (acessando: /init-session?jwt=abc...) pula verificação
  if (typeof window != 'undefined' && (
    window.location.pathname.indexOf('init-session') != -1 ||
    window.location.pathname.indexOf('public/') != -1
  )) {
    next(action)
    return
  }
  let state = getState()
  let isLogged = state.auth.isLogged
  // se estado logado nao faz nada
  if (isLogged || action.type == REQUEST_SIGN_IN.type || action.type == RECEIVE_SIGN_IN.type || action.type == RECEIVE_SIGN_OUT.type) {
    next(action)
  } else {
    let jwtLocal = getLocalStorage('jwt')
    let expiresInactivityLocal = getLocalStorage('expires_inactivity')

    let jwtSession = getSessionStorage('jwt')
    let expiresInactivitySession = getSessionStorage('expires_inactivity')

    if (jwtSession) {
      // se ja esta no sessionStorage
      dispatch(receiveSignIn(jwtSession, expiresInactivitySession))
      // dispatch(initWebsocket())
      next(action)
    } else if (jwtLocal) {
      // se nao esta na sessionStorage e tem o cookie
      dispatch(receiveSignIn(jwtLocal, expiresInactivityLocal))
      next(action)
    } else {
      dispatch(requestSignOut())
      next(action)
    }
  }
}