/**
 * Ações que interagem diretamente com o localStorage e sessionStorage.
 *
 *  - localStorage: é persistido mesmo com o fechamento do navegador
 *  - sessionStorage: é persistido apenas na guia aberta
 *
 * Obs: Em ambiente de teste nao existe storage
 */

const STORAGE_PREFIX = 'tmm_ui_'

export function setLocalStorage(field, value) {
    if (typeof localStorage == 'object') {
        localStorage.setItem(`${STORAGE_PREFIX}${field}`, value)
    }
}

export function getLocalStorage(field) {
    return localStorage[`${STORAGE_PREFIX}${field}`]
}

export function setSessionStorage(field, value) {
    if (typeof sessionStorage == 'object') {
        sessionStorage.setItem(`${STORAGE_PREFIX}${field}`, value)
    }
}

export function getSessionStorage(field) {
    return sessionStorage[`${STORAGE_PREFIX}${field}`]
}

/**
 * Remove todos intens do localStorage com o prefixo do app.
 */
export function clearLocalStorage() {
    if (typeof localStorage == 'object') {
        for (const key in localStorage) {
            if (Object.hasOwnProperty.call(localStorage, key) && key.startsWith(STORAGE_PREFIX)) {
                if (key.indexOf('DataTable') !== -1) continue
                delete localStorage[key]
            }
        }
    }

    // exceções
    // let chatWidth = getLocalStorage('chat_width')
    // let chatHeight = getLocalStorage('chat_height')
    // if (typeof localStorage == 'object') {
    //     localStorage.clear()
    // }
    // // restaura exceções
    // if (chatWidth && chatHeight) {
    //     setLocalStorage('chat_width', chatWidth)
    //     setLocalStorage('chat_height', chatHeight)
    // }
}

export function clearSessionStorage() {
    if (typeof sessionStorage == 'object') {
        sessionStorage.clear()
    }
}
