import {
  REQUEST_MEDICOS, RECEIVE_MEDICOS, REQUEST_ALL_MEDICOS, RECEIVE_ALL_MEDICOS, REQUEST_MEDICO, RECEIVE_MEDICO,
  REQUEST_MEDICO_ESPECIALIDADES,  RECEIVE_MEDICO_ESPECIALIDADES, REQUEST_MEDICO_DOCUMENTOS, RECEIVE_MEDICO_DOCUMENTOS,
  REQUEST_ENVIAR_DOCUMENTO, RECEIVE_ENVIAR_DOCUMENTO, REQUEST_REMOVE_DOCUMENTO, RECEIVE_REMOVE_DOCUMENTO,
  REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA, RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA, REQUEST_NOTAS_SERVICOS,
  RECEIVE_NOTAS_SERVICOS, REQUEST_SAVE_NOTA_SERVICOS, RECEIVE_SAVE_NOTA_SERVICOS, REQUEST_REMOVE_NOTA_SERVICOS,
  RECEIVE_REMOVE_NOTA_SERVICOS, RESET_ALL_MEDICO, RESET_MEDICO, REQUEST_SAVE_MEDICO, RECEIVE_SAVE_MEDICO,
  REQUEST_SAVE_MEDICO_ATENDIMENTO, RECEIVE_SAVE_MEDICO_ATENDIMENTO, REQUEST_CHANGE_NOTIFICATION_OPTIONS,
  RECEIVE_CHANGE_NOTIFICATION_OPTIONS, REQUEST_SAVE_PROFILE_MEDICO, RECEIVE_SAVE_PROFILE_MEDICO,
  REQUEST_SAVE_MEDICO_ENDERECOS, RECEIVE_SAVE_MEDICO_ENDERECOS, REQUEST_SAVE_AUTENTICAR_MEDICO, REQUEST_SAVE_SIGNATURE,
  RECEIVE_SAVE_SIGNATURE, RECEIVE_SAVE_AUTENTICAR_MEDICO, REQUEST_REMOVE_MEDICO, RECEIVE_REMOVE_MEDICO,
  REQUEST_SAVE_CONFIG_PLANTAO, RECEIVE_SAVE_CONFIG_PLANTAO, REQUEST_AVALIACOES,
  RECEIVE_AVALIACOES, REQUEST_AVALIACAO, RECEIVE_AVALIACAO, REQUEST_SAVE_AVALIACAO, RECEIVE_SAVE_AVALIACAO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  avaliacoes: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    params: { page: 1, limit: 50 },
    receivedAt: null,
  },
  avaliacao: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveAvaliacao: {
    isLoading: false,
  },
  configAtendimento: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  configEnderecos: {
    isLoading: false,
    receivedAt: null,
  },
  documentos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  enviarDocumento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  removeDocumento: {
    isLoading: false,
    receivedAt: null,
  },
  documentosByClinica: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  medicoEspecialidades: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  notasServicos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
  removeNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
  saveNotificationOptions: {
    isLoading: false,
    receivedAt: null,
  },
  saveProfile: {
    isLoading: false,
  },
  autenticar: {
    isLoading: false,
  },
  signature: {
    isLoading: false,
  },
  configPlantao: {
    isLoading: false,
  },
  remove: {
    isLoading: false,
  }
}

export default function MedicoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_MEDICOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_MEDICOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ALL_MEDICOS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_MEDICOS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MEDICO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
          receivedAt: state.item.receivedAt,
        }
      }
    case RECEIVE_MEDICO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_SAVE_MEDICO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_MEDICO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data ? action.data : state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_AVALIACOES:
      return {
        ...state,
        avaliacoes: {
          isLoading: action.isLoading,
          data: action.silent ? state.avaliacoes.data : INITIAL_STATE.avaliacoes.data,

        }
      }
    case RECEIVE_AVALIACOES:
      return {
        ...state,
        avaliacoes: {
          isLoading: action.isLoading,
          data: action.data,
          params: action.params,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_AVALIACAO:
      return {
        ...state,
        avaliacao: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.avaliacao.data,
          receivedAt: state.avaliacao.receivedAt,
        }
      }
    case RECEIVE_AVALIACAO:
      return {
        ...state,
        avaliacao: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_AVALIACAO:
    case RECEIVE_SAVE_AVALIACAO:
      return {
        ...state,
        saveAvaliacao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_MEDICO_ATENDIMENTO:
      return {
        ...state,
        configAtendimento: {
          isLoading: action.isLoading,
          data: state.configAtendimento.data,
          receivedAt: state.configAtendimento.receivedAt,
        }
      }
    case RECEIVE_SAVE_MEDICO_ATENDIMENTO:
      return {
        ...state,
        configAtendimento: {
          isLoading: action.isLoading,
          data: action.data ? action.data : state.configAtendimento.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_MEDICO_ENDERECOS:
      return {
        ...state,
        configEnderecos: {
          isLoading: action.isLoading,
          receivedAt: state.configEnderecos.receivedAt,
        }
      }
    case RECEIVE_SAVE_MEDICO_ENDERECOS:
      return {
        ...state,
        configEnderecos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MEDICO_DOCUMENTOS:
      return {
        ...state,
        documentos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.documentos.data,
        }
      }
    case RECEIVE_MEDICO_DOCUMENTOS:
      return {
        ...state,
        documentos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENVIAR_DOCUMENTO.type:
      return {
        ...state,
        enviarDocumento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.enviarDocumento.data,
        }
      }
    case RECEIVE_ENVIAR_DOCUMENTO.type:
      return {
        ...state,
        enviarDocumento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_DOCUMENTO.type:
      return {
        ...state,
        removeDocumento: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.removeDocumento.receivedAt,
        }
      }
    case RECEIVE_REMOVE_DOCUMENTO.type:
      return {
        ...state,
        removeDocumento: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_DOCUMENTOS_MEDICOS_BY_CLINICA:
      return {
        ...state,
        documentosByClinica: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.documentosByClinica.data,
        }
      }
    case RECEIVE_DOCUMENTOS_MEDICOS_BY_CLINICA:
      return {
        ...state,
        documentosByClinica: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_MEDICO_ESPECIALIDADES:
      return {
        ...state,
        medicoEspecialidades: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.medicoEspecialidades.data,
          receivedAt: INITIAL_STATE.medicoEspecialidades.receivedAt,
        }
      }
    case RECEIVE_MEDICO_ESPECIALIDADES:
      return {
        ...state,
        medicoEspecialidades: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_NOTAS_SERVICOS:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.notasServicos.data,
          receivedAt: INITIAL_STATE.notasServicos.receivedAt,
        }
      }
    case RECEIVE_NOTAS_SERVICOS:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_NOTA_SERVICOS:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.saveNotaServicos.receivedAt,
        }
      }
    case RECEIVE_SAVE_NOTA_SERVICOS:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_NOTA_SERVICOS:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.removeNotaServicos.receivedAt,
        }
      }
    case RECEIVE_REMOVE_NOTA_SERVICOS:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CHANGE_NOTIFICATION_OPTIONS:
      return {
        ...state,
        saveNotificationOptions: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.saveNotificationOptions.receivedAt,
        }
      }
    case RECEIVE_CHANGE_NOTIFICATION_OPTIONS:
      return {
        ...state,
        saveNotificationOptions: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_PROFILE_MEDICO:
    case RECEIVE_SAVE_PROFILE_MEDICO:
      return {
        ...state,
        saveProfile: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_AUTENTICAR_MEDICO:
    case RECEIVE_SAVE_AUTENTICAR_MEDICO:
      return {
        ...state,
        autenticar: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_SIGNATURE:
    case RECEIVE_SAVE_SIGNATURE:
      return {
        ...state,
        signature: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_CONFIG_PLANTAO:
    case RECEIVE_SAVE_CONFIG_PLANTAO:
      return {
        ...state,
        configPlantao: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_MEDICO:
    case RECEIVE_REMOVE_MEDICO:
      return {
        ...state,
        remove: {
          isLoading: action.isLoading,
        }
      }
    case RESET_MEDICO:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_MEDICO:
      return INITIAL_STATE
    default:
      return state
  }
}
