// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_AVISOS, RECEIVE_AVISOS, REQUEST_AVISO, RECEIVE_AVISO, REQUEST_SAVE_AVISO, RECEIVE_SAVE_AVISO,
  REQUEST_REMOVE_AVISO, RECEIVE_REMOVE_AVISO, REQUEST_READ_AVISO, RECEIVE_READ_AVISO, RESET_ALL_AVISO,
} from '../constants/ActionTypes'
// actions
import { showRequestError } from './AppActions'
import { addToast } from './ToastActions'


function requestAvisos(params, silent) {
  return {
    type: REQUEST_AVISOS.type,
    isLoading: !silent,
    params: params
  }
}

function receiveAvisos(data) {
  return {
    type: RECEIVE_AVISOS.type,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchAvisos(params = {}, callback = () => {}, silent = false) {
  return dispatch => {
    dispatch(requestAvisos(params, silent))
    const url = `${API_HOST}/aviso`
    request.get(url, params, { requestId: REQUEST_AVISOS.id }).then(json => {
      dispatch(receiveAvisos(json))
      callback(null, json)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(err)
    })
  }
}

function requestAviso() {
  return {
    type: REQUEST_AVISO.type,
    isLoading: true,
  }
}

function receiveAviso(data) {
  return {
    type: RECEIVE_AVISO.type,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchAviso(id, callback = () => {}) {
  return dispatch => {
    dispatch(requestAviso())
    const url = `${API_HOST}/aviso/${id}`
    request.get(url, {}, { requestId: REQUEST_AVISO.id }).then(json => {
      dispatch(receiveAviso(json))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestSaveAviso() {
  return {
    type: REQUEST_SAVE_AVISO.type,
    isLoading: true,
  }
}

function receiveSaveAviso() {
  return {
    type: RECEIVE_SAVE_AVISO.type,
    isLoading: false,
  }
}

/**
 * Persiste os dados de laudo texto. Se existir id atualiza(PUT),
 * caso contrário cria(POST).
 *
 * @param {Number} id
 * @param {Object} data
 * @param {Function} callback
 */
export function fetchSaveAviso(id, data, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestSaveAviso())
    const url = (id) ? `${API_HOST}/aviso/${id}` : `${API_HOST}/aviso`
    const method = (id) ? 'put' : 'post'
    request[method](url, {}, JSON.stringify(data), { requestId: REQUEST_SAVE_AVISO.id }).then(json => {
      dispatch(receiveSaveAviso(json))
      dispatch(addToast('success', 'Aviso salvo com sucesso.', null, true))
      dispatch(fetchAvisos(getState().aviso.avisos.params)) // recarrega listagem
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestRemoveAviso() {
  return {
    type: REQUEST_REMOVE_AVISO.type,
    isLoading: true,
  }
}

function receiveRemoveAviso() {
  return {
    type: RECEIVE_REMOVE_AVISO.type,
    isLoading: false,
  }
}

export function fetchRemoveAviso(id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestRemoveAviso())
    const url = `${API_HOST}/aviso/${id}`
    request.delete(url, {}, { requestId: REQUEST_REMOVE_AVISO.id }).then(json => {
      dispatch(receiveRemoveAviso(json))
      dispatch(fetchAvisos(getState().aviso.avisos.params)) // recarrega listagem
      dispatch(addToast('success', 'Aviso removido com sucesso.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

function requestReadAviso() {
  return {
    type: REQUEST_READ_AVISO,
    isLoading: true,
  }
}

function receiveReadAviso() {
  return {
    type: RECEIVE_READ_AVISO,
    isLoading: false,
  }
}

export function fetchReadAviso(id, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestReadAviso())
    const url = `${API_HOST}/aviso/${id}/read`
    request.put(url).then(json => {
      dispatch(receiveReadAviso(json))
      dispatch(fetchAvisos(getState().aviso.avisos.params, () => {}, true)) // recarrega listagem
      dispatch(addToast('success', 'Aviso marcado como lido.', null, true))
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      callback(false)
    })
  }
}

export function resetAllAviso() {
  return {
    type: RESET_ALL_AVISO,
  }
}
