import {
  REQUEST_ESTATISTICA_ESPECIALIDADE_ES, RECEIVE_ESTATISTICA_ESPECIALIDADE_ES,
  REQUEST_ESTATISTICA_ESPECIALIDADES, RECEIVE_ESTATISTICA_ESPECIALIDADES, REQUEST_ESTATISTICA_EXAMES,
  RECEIVE_ESTATISTICA_EXAMES, REQUEST_ESTATISTICA_MEDICOS, RECEIVE_ESTATISTICA_MEDICOS, REQUEST_ESTATISTICA_CLINICAS_ES,
  RECEIVE_ESTATISTICA_CLINICAS_ES, REQUEST_ESTATISTICA_CLINICAS_OCIOSIDADE, RECEIVE_ESTATISTICA_CLINICAS_OCIOSIDADE,
  REQUEST_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO, RECEIVE_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO,
  REQUEST_ESTATISTICA_ESPECIALIDADES_CLINICA, RECEIVE_ESTATISTICA_ESPECIALIDADES_CLINICA,
  REQUEST_ESTATISTICA_ESPECIALIDADES_MEDICO, RECEIVE_ESTATISTICA_ESPECIALIDADES_MEDICO,
  REQUEST_ESTATISTICA_ESPECIALIDADES_GRAFICO, RECEIVE_ESTATISTICA_ESPECIALIDADES_GRAFICO,
  REQUEST_ESTATISTICA_MEDICOS_GRAFICO, RECEIVE_ESTATISTICA_MEDICOS_GRAFICO,
  REQUEST_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO, RECEIVE_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO,
  REQUEST_ESTATISTICA_TOTAL_GRAFICO, RECEIVE_ESTATISTICA_TOTAL_GRAFICO, REQUEST_ESTATISTICA_TOTAL_ES_GRAFICO,
  RECEIVE_ESTATISTICA_TOTAL_ES_GRAFICO, REQUEST_ESTATISTICA_CID10, RECEIVE_ESTATISTICA_CID10,
  REQUEST_PACIENTES_BY_CID10, RECEIVE_PACIENTES_BY_CID10, REQUEST_ESTATISTICA_ENCAMINHAMENTO,
  RECEIVE_ESTATISTICA_ENCAMINHAMENTO, REQUEST_ESTATISTICA_TELECONSULTA_GRAFICO, RECEIVE_ESTATISTICA_TELECONSULTA_GRAFICO,
  REQUEST_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO, RECEIVE_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO,
  REQUEST_ESTATISTICA_TELECONSULTA_ESPECIALIDADES, RECEIVE_ESTATISTICA_TELECONSULTA_ESPECIALIDADES,
  REQUEST_ESTATISTICA_RECEITA_TOTAL_GRAFICO, RECEIVE_ESTATISTICA_RECEITA_TOTAL_GRAFICO,
  REQUEST_ESTATISTICA_CADASTRO_USUARIO_GRAFICO, RECEIVE_ESTATISTICA_CADASTRO_USUARIO_GRAFICO,
  RESET_ALL_ESTATISTICA
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  especialidadesES: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  especialidades: {
    isLoading: false,
    data: {},
    mes: null,
    ano: null,
    receivedAt: null,
  },
  especialidadesTeleconsulta: {
    isLoading: false,
    data: {},
    tipo: 'marketplace',
    mes: null,
    ano: null,
    receivedAt: null,
  },
  especialidadesGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  especialidadesTeleconsultaGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  medicosGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  especialidadeTiposGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    receivedAt: null,
  },
  totalGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  totalTeleconsultaGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  totalCadastroUsuarioGrafico: {
    isLoading: false,
    data: [],
    max: 0,
    receivedAt: null,
  },
  totalReceitaGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    receivedAt: null,
  },
  totalESGrafico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    receivedAt: null,
  },
  especialidadesClinica: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    idClinica: null,
    mes: null,
    ano: null,
    receivedAt: null,
  },
  especialidadesMedico: {
    isLoading: false,
    data: [],
    template: [],
    max: 0,
    maxValor: 0,
    idMedico: null,
    mes: null,
    ano: null,
    receivedAt: null,
  },
  exames: {
    isLoading: false,
    data: [],
    mes: null,
    ano: null,
    receivedAt: null,
  },
  medicos: {
    isLoading: false,
    data: [],
    mes: null,
    ano: null,
    receivedAt: null,
  },
  clinicasES: {
    isLoading: false,
    data: [],
    mes: null,
    ano: null,
    receivedAt: null,
  },
  clinicasOciosidade: {
    isLoading: false,
    data: [],
    mes: null,
    ano: null,
    receivedAt: null,
  },
  clinicasAtivacaoDesativacao: {
    isLoading: false,
    data: [],
    mes: null,
    ano: null,
    receivedAt: null,
  },
  cid10: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  pacientesByCid10: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  encaminhamento: {
    isLoading: false,
    data: {
      dataEsecialidade: [],
      dataMedico: [],
    },
    receivedAt: null,
  },
}

export default function EstatisticaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ESTATISTICA_ESPECIALIDADE_ES:
      return {
        ...state,
        especialidadesES: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesES.data,
          receivedAt: INITIAL_STATE.especialidadesES.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADE_ES:
      return {
        ...state,
        especialidadesES: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ESPECIALIDADES:
      return {
        ...state,
        especialidades: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidades.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.especialidades.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADES:
      return {
        ...state,
        especialidades: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_TELECONSULTA_ESPECIALIDADES:
      return {
        ...state,
        especialidadesTeleconsulta: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesTeleconsulta.data,
          tipo: 'marketplace',
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.especialidadesTeleconsulta.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_TELECONSULTA_ESPECIALIDADES:
      return {
        ...state,
        especialidadesTeleconsulta: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.tipo,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ESPECIALIDADES_GRAFICO:
      return {
        ...state,
        especialidadesGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesGrafico.data,
          template: INITIAL_STATE.especialidadesGrafico.template,
          max:  INITIAL_STATE.especialidadesGrafico.max,
          maxValor:  INITIAL_STATE.especialidadesGrafico.maxValor,
          receivedAt: INITIAL_STATE.especialidadesGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADES_GRAFICO:
      return {
        ...state,
        especialidadesGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_TELECONSULTA_GRAFICO:
      return {
        ...state,
        especialidadesTeleconsultaGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesTeleconsultaGrafico.data,
          template: INITIAL_STATE.especialidadesTeleconsultaGrafico.template,
          max:  INITIAL_STATE.especialidadesTeleconsultaGrafico.max,
          maxValor:  INITIAL_STATE.especialidadesTeleconsultaGrafico.maxValor,
          receivedAt: INITIAL_STATE.especialidadesTeleconsultaGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_TELECONSULTA_GRAFICO:
      return {
        ...state,
        especialidadesTeleconsultaGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_MEDICOS_GRAFICO:
      return {
        ...state,
        medicosGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.medicosGrafico.data,
          template: INITIAL_STATE.medicosGrafico.template,
          max:  INITIAL_STATE.medicosGrafico.max,
          maxValor:  INITIAL_STATE.medicosGrafico.maxValor,
          receivedAt: INITIAL_STATE.medicosGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_MEDICOS_GRAFICO:
      return {
        ...state,
        medicosGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO:
      return {
        ...state,
        especialidadeTiposGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadeTiposGrafico.data,
          template: INITIAL_STATE.especialidadeTiposGrafico.template,
          max:  INITIAL_STATE.especialidadeTiposGrafico.max,
          maxValor:  INITIAL_STATE.especialidadeTiposGrafico.maxValor,
          receivedAt: INITIAL_STATE.especialidadeTiposGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADE_TIPOS_GRAFICO:
      return {
        ...state,
        especialidadeTiposGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_TOTAL_GRAFICO:
      return {
        ...state,
        totalGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.totalGrafico.data,
          template: INITIAL_STATE.totalGrafico.template,
          max:  INITIAL_STATE.totalGrafico.max,
          maxValor:  INITIAL_STATE.totalGrafico.maxValor,
          receivedAt: INITIAL_STATE.totalGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_TOTAL_GRAFICO:
      return {
        ...state,
        totalGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO:
      return {
        ...state,
        totalTeleconsultaGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.totalTeleconsultaGrafico.data,
          template: INITIAL_STATE.totalTeleconsultaGrafico.template,
          max:  INITIAL_STATE.totalTeleconsultaGrafico.max,
          maxValor:  INITIAL_STATE.totalTeleconsultaGrafico.maxValor,
          receivedAt: INITIAL_STATE.totalTeleconsultaGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_TELECONSULTA_TOTAL_GRAFICO:
      return {
        ...state,
        totalTeleconsultaGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_CADASTRO_USUARIO_GRAFICO:
      return {
        ...state,
        totalCadastroUsuarioGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.totalCadastroUsuarioGrafico.data,
          max:  INITIAL_STATE.totalCadastroUsuarioGrafico.max,
          receivedAt: INITIAL_STATE.totalCadastroUsuarioGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_CADASTRO_USUARIO_GRAFICO:
      return {
        ...state,
        totalCadastroUsuarioGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          max: action.max,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_RECEITA_TOTAL_GRAFICO:
      return {
        ...state,
        totalReceitaGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.totalReceitaGrafico.data,
          template: INITIAL_STATE.totalReceitaGrafico.template,
          max:  INITIAL_STATE.totalReceitaGrafico.max,
          maxValor:  INITIAL_STATE.totalReceitaGrafico.maxValor,
          receivedAt: INITIAL_STATE.totalReceitaGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_RECEITA_TOTAL_GRAFICO:
      return {
        ...state,
        totalReceitaGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_TOTAL_ES_GRAFICO:
      return {
        ...state,
        totalESGrafico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.totalESGrafico.data,
          template: INITIAL_STATE.totalESGrafico.template,
          max:  INITIAL_STATE.totalESGrafico.max,
          receivedAt: INITIAL_STATE.totalESGrafico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_TOTAL_ES_GRAFICO:
      return {
        ...state,
        totalESGrafico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ESPECIALIDADES_CLINICA:
      return {
        ...state,
        especialidadesClinica: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesClinica.data,
          template: INITIAL_STATE.especialidadesClinica.template,
          max:  INITIAL_STATE.especialidadesClinica.max,
          maxValor:  INITIAL_STATE.especialidadesClinica.maxValor,
          mes: null,
          ano: null,
          idClinica: null,
          receivedAt: INITIAL_STATE.especialidadesClinica.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADES_CLINICA:
      return {
        ...state,
        especialidadesClinica: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          mes: action.mes,
          ano: action.ano,
          idClinica: action.idClinica,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ESPECIALIDADES_MEDICO:
      return {
        ...state,
        especialidadesMedico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.especialidadesMedico.data,
          template: INITIAL_STATE.especialidadesMedico.template,
          max:  INITIAL_STATE.especialidadesMedico.max,
          maxValor:  INITIAL_STATE.especialidadesMedico.maxValor,
          mes: null,
          ano: null,
          idMedico: null,
          receivedAt: INITIAL_STATE.especialidadesMedico.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ESPECIALIDADES_MEDICO:
      return {
        ...state,
        especialidadesMedico: {
          isLoading: action.isLoading,
          data: action.data,
          template: action.template,
          max: action.max,
          maxValor: action.maxValor,
          mes: action.mes,
          ano: action.ano,
          idMedico: action.idMedico,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_EXAMES:
      return {
        ...state,
        exames: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.exames.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.exames.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_EXAMES:
      return {
        ...state,
        exames: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_MEDICOS:
      return {
        ...state,
        medicos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.medicos.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.medicos.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_MEDICOS:
      return {
        ...state,
        medicos: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_CLINICAS_ES:
      return {
        ...state,
        clinicasES: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.clinicasES.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.clinicasES.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_CLINICAS_ES:
      return {
        ...state,
        clinicasES: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_CLINICAS_OCIOSIDADE:
      return {
        ...state,
        clinicasOciosidade: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.clinicasOciosidade.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.clinicasOciosidade.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_CLINICAS_OCIOSIDADE:
      return {
        ...state,
        clinicasOciosidade: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO:
      return {
        ...state,
        clinicasAtivacaoDesativacao: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.clinicasAtivacaoDesativacao.data,
          mes: null,
          ano: null,
          receivedAt: INITIAL_STATE.clinicasAtivacaoDesativacao.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_CLINICAS_ATIVACAO_DESATIVACAO:
      return {
        ...state,
        clinicasAtivacaoDesativacao: {
          isLoading: action.isLoading,
          data: action.data,
          mes: action.mes,
          ano: action.ano,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_CID10:
      return {
        ...state,
        cid10: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.cid10.data,
          receivedAt: INITIAL_STATE.cid10.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_CID10:
      return {
        ...state,
        cid10: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PACIENTES_BY_CID10:
      return {
        ...state,
        pacientesByCid10: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.pacientesByCid10.data,
          receivedAt: state.pacientesByCid10.receivedAt,
        }
      }
    case RECEIVE_PACIENTES_BY_CID10:
      return {
        ...state,
        pacientesByCid10: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.pacientesByCid10.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESTATISTICA_ENCAMINHAMENTO:
      return {
        ...state,
        encaminhamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.encaminhamento.data,
          receivedAt: INITIAL_STATE.encaminhamento.receivedAt,
        }
      }
    case RECEIVE_ESTATISTICA_ENCAMINHAMENTO:
      return {
        ...state,
        encaminhamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_ESTATISTICA:
      return INITIAL_STATE
    default:
      return state
  }
}
