import {
  REQUEST_SIGN_IN, RECEIVE_SIGN_IN, RECEIVE_SIGN_IN_FAIL, RECEIVE_SIGN_OUT, REQUEST_LOGGED_ENTITY,
  RECEIVE_LOGGED_ENTITY, REQUEST_ENTITY_INFORMACOES_ADICIONAIS, RECEIVE_ENTITY_INFORMACOES_ADICIONAIS,
  REQUEST_CREDENTIALS, REQUEST_TELEMEDICINA, RECEIVE_TELEMEDICINA, REQUEST_NOTIFY_ONLINE, RECEIVE_NOTIFY_ONLINE,
  REQUEST_VERIFY_USER_SESSION, RECEIVE_VERIFY_USER_SESSION, RESET_ALL_AUTH
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  isFetching: false,
  isLogged: false,
  jwt: '',
  expires_inactivity: null,
  jwtPayload: {
    user: {
      tipo: '',
      nome: '',
      email: '',
      usuario: '',
      profile: '',
      type: '',
    }
  },
  expiration: null,
  receivedAt: null,
  loggedEntity: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  telemedicina: {
    isLoading: false,
    data: {
      // id: '',
      // id_str: '',
      // nome: '',
      // dominio: '',
      // cnpj: '',
      // email: '',
      // telefone1: '',
      // telefone2: '',
      // whatsapp: '',
      // theme_color_primary: '',
      // theme_color_secondary: '',
      // theme_border_radius: '',
      // theme_logo: '',
      // created_at: '',
      // updated_at: '',
    },
    receivedAt: null,
  },
  informacoesAdicionais: {
    isLoading: false,
    receivedAt: null,
    email_confirmed: 0,
    phone_confirmed: 0,
    clinica: {
      imprimiuFatura: null,
      imprimiuNfse: null,
      possuiFaturasEmAberto: null,
      valorExamesNaFila: null, // apenas prepago
      clinicasFiliais: {
        count: 0,
        rows: []
      }
    },
    medico: {
      laudoInProgress: {},
      hasLaudoInProgress: false
    },
    tecnico: {
      laudoInProgress: {},
      hasLaudoInProgress: false
    },
    vendedor: { },
    admin: {
      // countAvisosPagarMedicos: 0,
      // countAvisosPagarVendedores: 0,
      // countComprovantesPagamentoClinicaPrepagoAguardando: 0,
      // confIgpmAguardando: false,
      // email_confirmed: 0,
      // phone_confirmed: 0,
    }
  },
  notifyOnline: {
    isLoading: false,
    receivedAt: null,
  },
  verifyUserSession: {
    isLoading: false,
    receivedAt: null,
  },
}

export default function PacienteReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_SIGN_IN.type:
      return {
        ...state,
        ...{
          isFetching: action.isFetching,
          isLogged: action.isLogged,
          jwt: action.jwt,
          expires_inactivity: action.expires_inactivity,
          jwtPayload: action.jwtPayload,
          expiration: action.expiration,
          receivedAt: action.receivedAt
        }
      }
    case RECEIVE_SIGN_IN.type:
      return {
        ...state,
        ...{
          isFetching: action.isFetching,
          isLogged: action.isLogged,
          jwt: action.jwt,
          expires_inactivity: action.expires_inactivity,
          jwtPayload: action.jwtPayload,
          expiration: action.expiration,
          receivedAt: action.receivedAt
        }
      }
    case RECEIVE_SIGN_IN_FAIL.type:
      return {
        ...state,
        ...{
          isFetching: action.isFetching,
          isLogged: action.isLogged,
        }
      }
    case RECEIVE_SIGN_OUT.type:
      return {
        ...state,
        ...{
          isFetching: action.isFetching,
          isLogged: action.isLogged,
          jwt: action.jwt,
          jwtPayload: action.jwtPayload,
          expiration: action.expiration,
          receivedAt: action.receivedAt
        }
      }
    case REQUEST_LOGGED_ENTITY.type:
      return {
        ...state,
        loggedEntity: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.loggedEntity.data : INITIAL_STATE.loggedEntity.data,
        }
      }
    case RECEIVE_LOGGED_ENTITY.type:
      return {
        ...state,
        loggedEntity: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TELEMEDICINA:
      return {
        ...state,
        telemedicina: {
          isLoading: action.isLoading,
          data: (action.isSilent) ? state.telemedicina.data : INITIAL_STATE.telemedicina.data,
        }
      }
    case RECEIVE_TELEMEDICINA:
      return {
        ...state,
        telemedicina: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENTITY_INFORMACOES_ADICIONAIS.type:
      return {
        ...state,
        informacoesAdicionais: {
          isLoading: (action.isSilent) ? false : action.isLoading,
          email_confirmed: INITIAL_STATE.informacoesAdicionais.email_confirmed,
          phone_confirmed: INITIAL_STATE.informacoesAdicionais.phone_confirmed,
          clinica: (action.isSilent) ? state.informacoesAdicionais.clinica : INITIAL_STATE.informacoesAdicionais.clinica,
          medico: (action.isSilent) ? state.informacoesAdicionais.medico : INITIAL_STATE.informacoesAdicionais.medico,
          tecnico: (action.isSilent) ? state.informacoesAdicionais.tecnico : INITIAL_STATE.informacoesAdicionais.tecnico,
          vendedor: (action.isSilent) ? state.informacoesAdicionais.vendedor : INITIAL_STATE.informacoesAdicionais.vendedor,
          admin: (action.isSilent) ? state.informacoesAdicionais.admin : INITIAL_STATE.informacoesAdicionais.admin
        }
      }
    case RECEIVE_ENTITY_INFORMACOES_ADICIONAIS.type:
      let userType = action.userType
      if (userType === 'clinica') {
        return {
          ...state,
          informacoesAdicionais: {
            isLoading: action.isLoading,
            receivedAt: action.receivedAt,
            email_confirmed: action.data.email_confirmed,
            phone_confirmed: action.data.phone_confirmed,
            clinica: action.data,
            medico: INITIAL_STATE.informacoesAdicionais.medico,
            tecnico: INITIAL_STATE.informacoesAdicionais.tecnico,
            vendedor: INITIAL_STATE.informacoesAdicionais.vendedor,
            admin: INITIAL_STATE.informacoesAdicionais.admin,
          }
        }
      } else if (userType === 'medico') {
        return {
          ...state,
          informacoesAdicionais: {
            isLoading: action.isLoading,
            receivedAt: action.receivedAt,
            email_confirmed: action.data.email_confirmed,
            phone_confirmed: action.data.phone_confirmed,
            clinica: INITIAL_STATE.informacoesAdicionais.clinica,
            medico: action.data,
            tecnico: INITIAL_STATE.informacoesAdicionais.tecnico,
            vendedor: INITIAL_STATE.informacoesAdicionais.vendedor,
            admin: INITIAL_STATE.informacoesAdicionais.admin,
          }
        }
      } else if (userType === 'tecnico') {
        return {
          ...state,
          informacoesAdicionais: {
            isLoading: action.isLoading,
            receivedAt: action.receivedAt,
            email_confirmed: action.data.email_confirmed,
            phone_confirmed: action.data.phone_confirmed,
            clinica: INITIAL_STATE.informacoesAdicionais.clinica,
            medico: INITIAL_STATE.informacoesAdicionais.medico,
            vendedor: INITIAL_STATE.informacoesAdicionais.vendedor,
            admin: INITIAL_STATE.informacoesAdicionais.admin,
            tecnico: action.data,
          }
        }
      } else if (userType === 'vendedor') {
        return {
          ...state,
          informacoesAdicionais: {
            isLoading: action.isLoading,
            receivedAt: action.receivedAt,
            email_confirmed: action.data.email_confirmed,
            phone_confirmed: action.data.phone_confirmed,
            clinica: INITIAL_STATE.informacoesAdicionais.clinica,
            medico: INITIAL_STATE.informacoesAdicionais.medico,
            tecnico: INITIAL_STATE.informacoesAdicionais.tecnico,
            admin: INITIAL_STATE.informacoesAdicionais.admin,
            vendedor: action.vendedor,
          }
        }
      } else if (userType === 'admin') {
        return {
          ...state,
          informacoesAdicionais: {
            isLoading: action.isLoading,
            receivedAt: action.receivedAt,
            email_confirmed: action.data.email_confirmed,
            phone_confirmed: action.data.phone_confirmed,
            clinica: INITIAL_STATE.informacoesAdicionais.clinica,
            medico: INITIAL_STATE.informacoesAdicionais.medico,
            tecnico: INITIAL_STATE.informacoesAdicionais.tecnico,
            vendedor: INITIAL_STATE.informacoesAdicionais.vendedor,
            admin: action.data,
          }
        }
      }
    case REQUEST_NOTIFY_ONLINE:
    case RECEIVE_NOTIFY_ONLINE: {
      return {
        ...state,
        notifyOnline: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt || null,
        }
      }
    }
    case REQUEST_VERIFY_USER_SESSION:
    case RECEIVE_VERIFY_USER_SESSION: {
      return {
        ...state,
        verifyUserSession: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt || null,
        }
      }
    }
    case RESET_ALL_AUTH:
      return INITIAL_STATE
    default:
      return state
  }
}
