import {
  REQUEST_CLINICAS, RECEIVE_CLINICAS, REQUEST_CLINICAS_MATRIZ, RECEIVE_CLINICAS_MATRIZ, REQUEST_CLINICA_MATRIZ,
  RECEIVE_CLINICA_MATRIZ, REQUEST_SAVE_CLINICA_MATRIZ, RECEIVE_SAVE_CLINICA_MATRIZ, REQUEST_ALL_CLINICAS,
  RECEIVE_ALL_CLINICAS, REQUEST_CLINICA, RECEIVE_CLINICA, REQUEST_SAVE_CLINICA, RECEIVE_SAVE_CLINICA,
  REQUEST_REMOVE_CLINICA, RECEIVE_REMOVE_CLINICA, REQUEST_CLINICA_ESPECIALIDADES, RECEIVE_CLINICA_ESPECIALIDADES,
  REQUEST_CLINICA_IDIOMAS, RECEIVE_CLINICA_IDIOMAS, REQUEST_ADD_CREDIT, RECEIVE_ADD_CREDIT,
  /*REQUEST_SAVE_CLINICA_ESPECIALIDADE, RECEIVE_SAVE_CLINICA_ESPECIALIDADE,*/ REQUEST_REMOVE_CLINICA_ESPECIALIDADE,
  RECEIVE_REMOVE_CLINICA_ESPECIALIDADE, REQUEST_CLINICA_DOCUMENTOS, RECEIVE_CLINICA_DOCUMENTOS,
  REQUEST_ENVIAR_DOCUMENTO_CLINICA, RECEIVE_ENVIAR_DOCUMENTO_CLINICA, REQUEST_REMOVE_DOCUMENTO_CLINICA,
  RECEIVE_REMOVE_DOCUMENTO_CLINICA, REQUEST_SAVE_CUSTOMER_SUCCESS, RECEIVE_SAVE_CUSTOMER_SUCCESS,
  REQUEST_CLINICA_VALORES_RECEBER, RECEIVE_CLINICA_VALORES_RECEBER, REQUEST_CLINICA_RECIBOS_DE_TAXAS,
  RECEIVE_CLINICA_RECIBOS_DE_TAXAS, REQUEST_SAVE_PROFILE_CLINICA, RECEIVE_SAVE_PROFILE_CLINICA,
  REQUEST_SAVE_AUTENTICAR_CLINICA, RECEIVE_SAVE_AUTENTICAR_CLINICA, REQUEST_SAVE_CLINICA_LAYOUT,
  RECEIVE_SAVE_CLINICA_LAYOUT, REQUEST_SAVE_CLINICA_PAGAMENTO, RECEIVE_SAVE_CLINICA_PAGAMENTO,
  REQUEST_CONFIG_NOTIFICACAO_CREDITO, RECEIVE_CONFIG_NOTIFICACAO_CREDITO, REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO,
  RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO, RESET_CLINICA, RESET_ALL_CLINICA
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  listMatriz: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemMatriz: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveClinicaMatriz: {
    isLoading: false,
  },
  documentos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  enviarDocumento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  removeDocumento: {
    isLoading: false,
    receivedAt: null,
  },
  saveCustomerSuccess: {
    isLoading: false,
  },
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  removeItem: {
    isLoading: false,
  },
  clinicaEspecialidades: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  idiomas: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  addCredit: {
    isLoading: false,
  },
  removeClinicaEspecialidadeItem: {
    isLoading: false,
  },
  valoresReceber: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  recibosDeTaxas: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveProfile: {
    isLoading: false,
  },
  autenticar: {
    isLoading: false,
  },
  layout: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  pagamento: {
    isLoading: false,
    receivedAt: null,
  },
  configNotificacaoCredit: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveConfigNotificacaoCredit: {
    isLoading: false,
    receivedAt: null,
  },
}

export default function ClinicaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_CLINICAS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
          receivedAt: state.list.receivedAt,
        }
      }
    case RECEIVE_CLINICAS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICAS_MATRIZ.type:
      return {
        ...state,
        listMatriz: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listMatriz.data,
        }
      }
    case RECEIVE_CLINICAS_MATRIZ.type:
      return {
        ...state,
        listMatriz: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICA_DOCUMENTOS.type:
      return {
        ...state,
        documentos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.documentos.data,
        }
      }
    case RECEIVE_CLINICA_DOCUMENTOS.type:
      return {
        ...state,
        documentos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENVIAR_DOCUMENTO_CLINICA.type:
      return {
        ...state,
        enviarDocumento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.enviarDocumento.data,
        }
      }
    case RECEIVE_ENVIAR_DOCUMENTO_CLINICA.type:
      return {
        ...state,
        enviarDocumento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_DOCUMENTO_CLINICA.type:
      return {
        ...state,
        removeDocumento: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.removeDocumento.receivedAt,
        }
      }
    case RECEIVE_REMOVE_DOCUMENTO_CLINICA.type:
      return {
        ...state,
        removeDocumento: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CUSTOMER_SUCCESS.type:
    case RECEIVE_SAVE_CUSTOMER_SUCCESS.type:
      return {
        ...state,
        saveCustomerSuccess: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ALL_CLINICAS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_CLINICAS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_CLINICA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICA_MATRIZ.type:
      return {
        ...state,
        itemMatriz: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_CLINICA_MATRIZ.type:
      return {
        ...state,
        itemMatriz: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CLINICA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_CLINICA.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CLINICA_MATRIZ.type:
      return {
        ...state,
        saveClinicaMatriz: {
          isLoading: action.isLoading
        }
      }
    case RECEIVE_SAVE_CLINICA_MATRIZ.type:
      return {
        ...state,
        saveClinicaMatriz: {
          isLoading: action.isLoading
        }
      }
    case REQUEST_REMOVE_CLINICA.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_CLINICA.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CLINICA_ESPECIALIDADES.type:
      return {
        ...state,
        clinicaEspecialidades: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_CLINICA_ESPECIALIDADES.type:
      return {
        ...state,
        clinicaEspecialidades: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICA_IDIOMAS.type:
      return {
        ...state,
        idiomas: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.idiomas.data,
        }
      }
    case RECEIVE_CLINICA_IDIOMAS.type:
      return {
        ...state,
        idiomas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ADD_CREDIT.type:
      return {
        ...state,
        addCredit: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_ADD_CREDIT.type:
      return {
        ...state,
        addCredit: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_CLINICA_ESPECIALIDADE.type:
      return {
        ...state,
        removeClinicaEspecialidadeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_CLINICA_ESPECIALIDADE.type:
      return {
        ...state,
        removeClinicaEspecialidadeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CLINICA_VALORES_RECEBER:
      return {
        ...state,
        valoresReceber: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.valoresReceber.data,
          receivedAt: INITIAL_STATE.valoresReceber.receivedAt,
        }
      }
    case RECEIVE_CLINICA_VALORES_RECEBER:
      return {
        ...state,
        valoresReceber: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CLINICA_RECIBOS_DE_TAXAS:
      return {
        ...state,
        recibosDeTaxas: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.recibosDeTaxas.data,
          receivedAt: INITIAL_STATE.recibosDeTaxas.receivedAt,
        }
      }
    case RECEIVE_CLINICA_RECIBOS_DE_TAXAS:
      return {
        ...state,
        recibosDeTaxas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_PROFILE_CLINICA:
    case RECEIVE_SAVE_PROFILE_CLINICA:
      return {
        ...state,
        saveProfile: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_AUTENTICAR_CLINICA:
    case RECEIVE_SAVE_AUTENTICAR_CLINICA:
      return {
        ...state,
        autenticar: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_CLINICA_LAYOUT:
      return {
        ...state,
        layout: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.layout.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_CLINICA_LAYOUT:
      return {
        ...state,
        layout: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.layout.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CLINICA_PAGAMENTO:
    case RECEIVE_SAVE_CLINICA_PAGAMENTO:
      return {
        ...state,
        pagamento: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CONFIG_NOTIFICACAO_CREDITO:
      return {
        ...state,
        configNotificacaoCredit: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.configNotificacaoCredit.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_CONFIG_NOTIFICACAO_CREDITO:
      return {
        ...state,
        configNotificacaoCredit: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_CONFIG_NOTIFICACAO_CREDITO:
    case RECEIVE_SAVE_CONFIG_NOTIFICACAO_CREDITO:
      return {
        ...state,
        saveConfigNotificacaoCredit: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_CLINICA.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_CLINICA.type:
      return INITIAL_STATE
    default:
      return state
  }
}
