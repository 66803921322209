import {
  REQUEST_USERS_STATUS, RECEIVE_USERS_STATUS, REQUEST_USERS, RECEIVE_USERS, REQUEST_USER, RECEIVE_USER,
  REQUEST_REMOVE_USER, RECEIVE_REMOVE_USER, REQUEST_SAVE_USER, RECEIVE_SAVE_USER, REQUEST_CHANGE_PASSWD,
  RECEIVE_CHANGE_PASSWD, REQUEST_RECOVERY_PASSWORD, RECEIVE_RECOVERY_PASSWORD, REQUEST_CHANGE_EMAIL,
  RECEIVE_CHANGE_EMAIL, REQUEST_CHANGE_PHONE, RECEIVE_CHANGE_PHONE, REQUEST_RESET_PASSWD, RECEIVE_RESET_PASSWD,
  REQUEST_ADMIN_RESEND_EMAIL, RECEIVE_ADMIN_RESEND_EMAIL, REQUEST_USER_RESEND_EMAIL, RECEIVE_USER_RESEND_EMAIL,
  REQUEST_ACCEPT_TERMS, RECEIVE_ACCEPT_TERMS, RESET_USER, RESET_ALL_USERS
 } from '../constants/ActionTypes'

const INITIAL_STATE = {
  status: {
    isLoading: false,
    data: {
      rows: [],
      count: 0
    },
    receivedAt: null,
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItem: {
    isLoading: false,
  },
  removeItem: {
    isLoading: false,
  },
  changePasswd: {
    isLoading: false,
  },
  changeEmail: {
    isLoading: false,
  },
  changePhone: {
    isLoading: false,
  },
  recoveryPassword: {
    isLoading: false,
  },
  resetPassword: {
    isLoading: false,
  },
  resendAdminEmail: {
    isLoading: false,
  },
  resendUserEmail: {
    isLoading: false,
  },
  acceptTerms: {
    isLoading: false,
  }
}

export default function UsersReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_USERS_STATUS.type:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.status.data,
        }
      }
    case RECEIVE_USERS_STATUS.type:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_USERS.type:
        return {
          ...state,
          list: {
            isLoading: action.isLoading,
            data: INITIAL_STATE.list.data,
          }
        }
    case RECEIVE_USERS.type:
        return {
          ...state,
          list: {
            isLoading: action.isLoading,
            data: action.data,
            receivedAt: action.receivedAt,
          }
        }
    case REQUEST_USER.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_USER.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_USER.type:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_USER.type:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_USER.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_USER.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CHANGE_PASSWD.type:
      return {
        ...state,
        changePasswd: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_CHANGE_PASSWD.type:
      return {
        ...state,
        changePasswd: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CHANGE_EMAIL.type:
      return {
        ...state,
        changeEmail: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_CHANGE_EMAIL.type:
      return {
        ...state,
        changeEmail: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CHANGE_PHONE.type:
      return {
        ...state,
        changePhone: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_CHANGE_PHONE.type:
      return {
        ...state,
        changePhone: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_RECOVERY_PASSWORD.type:
      return {
        ...state,
        recoveryPassword: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_RECOVERY_PASSWORD.type:
      return {
        ...state,
        recoveryPassword: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_RESET_PASSWD.type:
      return {
        ...state,
        resetPassword: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_RESET_PASSWD.type:
      return {
        ...state,
        resetPassword: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ADMIN_RESEND_EMAIL.type:
      return {
        ...state,
        resendAdminEmail: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_ADMIN_RESEND_EMAIL.type:
      return {
        ...state,
        resendAdminEmail: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_USER_RESEND_EMAIL.type:
      return {
        ...state,
        resendUserEmail: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_USER_RESEND_EMAIL.type:
      return {
        ...state,
        resendUserEmail: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_ACCEPT_TERMS.type:
    case RECEIVE_ACCEPT_TERMS.type:
      return {
        ...state,
        acceptTerms: {
          isLoading: action.isLoading,
        }
      }
    case RESET_USER.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_USERS.type:
      return INITIAL_STATE
    default:
      return state
  }
}
