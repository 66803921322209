import {
  REQUEST_ALL_PLANTOES, RECEIVE_ALL_PLANTOES, REQUEST_PLANTOES, RECEIVE_PLANTOES, REQUEST_PLANTAO, RECEIVE_PLANTAO,
  REQUEST_PLANTAO_FILA, RECEIVE_PLANTAO_FILA, REQUEST_PLANTAO_FILA_MEDICO, RECEIVE_PLANTAO_FILA_MEDICO,
  REQUEST_PLANTAO_FILA_ITEM, RECEIVE_PLANTAO_FILA_ITEM, REQUEST_CANCEL_PLANTAO_FILA_ITEM,
  RECEIVE_CANCEL_PLANTAO_FILA_ITEM, REQUEST_SAVE_PLANTAO, RECEIVE_SAVE_PLANTAO, RESET_PLANTAO, RESET_ALL_PLANTAO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  fila: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  filaMedico: {
    isLoading: false,
    data: {
      // 1: {
      //   id: 1,
      //   titulo: 'Plantao COVID19',
      //   fila: [{'id', 'id_paciente', ...}]
      // }
    },
    receivedAt: null,
  },
  filaItem: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  cancelFilaItem: {
    isLoading: false,
  },
  save: {
    isLoading: false,
  },
}

export default function PlantaoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_PLANTOES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_ALL_PLANTOES.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PLANTOES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_PLANTOES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PLANTAO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_PLANTAO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PLANTAO_FILA.type:
      return {
        ...state,
        fila: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.fila.data,
        }
      }
    case RECEIVE_PLANTAO_FILA.type:
      return {
        ...state,
        fila: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PLANTAO_FILA_MEDICO.type:
      return {
        ...state,
        filaMedico: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.filaMedico.data,
        }
      }
    case RECEIVE_PLANTAO_FILA_MEDICO.type:
      return {
        ...state,
        filaMedico: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PLANTAO_FILA_ITEM.type:
      return {
        ...state,
        filaItem: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.filaItem.data,
        }
      }
    case RECEIVE_PLANTAO_FILA_ITEM.type:
      return {
        ...state,
        filaItem: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CANCEL_PLANTAO_FILA_ITEM.type:
    case RECEIVE_CANCEL_PLANTAO_FILA_ITEM.type:
      return {
        ...state,
        cancelFilaItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_SAVE_PLANTAO.type:
    case RECEIVE_SAVE_PLANTAO.type:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case RESET_PLANTAO.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_PLANTAO.type:
      return INITIAL_STATE
    default:
      return state
  }
}