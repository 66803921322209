// third party
import fetch from 'cross-fetch'
// utils
import RequestUtils from '../utils/RequestUtils'
import request from '../utils/request/Request' // novo
// constants
import { API_HOST } from '../constants/EnvTypes'
import { REQUEST_ESTADOS, RECEIVE_ESTADOS, REQUEST_CIDADES, RECEIVE_CIDADES,
  RESET_ALL_ENDERECO } from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'


function requestEstados() {
  return {
    type: REQUEST_ESTADOS,
    isLoading: true,
  }
}

function receiveEstados(data) {
  return {
    type: RECEIVE_ESTADOS,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchEstados(params = { page: 1, limit: 50, search: '' }) {
  return (dispatch, getState) => {
    dispatch(requestEstados())
    const endPoint = `${API_HOST}/endereco/estado-ibge`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar estados!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar estados!',
          err500: 'Erro ao consultar estados!',
          errDefault: 'Erro desconhecido ao consultar estados!'
        })
      })
      .then(json => dispatch(receiveEstados(json)))
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
      })
  }
}

function requestCidades() {
  return {
    type: REQUEST_CIDADES,
    isLoading: true,
  }
}

function receiveCidades(data) {
  return {
    type: RECEIVE_CIDADES,
    isLoading: false,
    data: data,
    receivedAt: Date.now(),
  }
}

export function fetchCidades(params = { page: 1, limit: 50, search: '' }, callback = () => {}) {
  return (dispatch, getState) => {
    dispatch(requestCidades())
    const endPoint = `${API_HOST}/endereco/cidade-ibge`
    const url = RequestUtils.fetchURL(endPoint, params)
    const headers = RequestUtils.defaultHeaders()
    const options = {
      method: 'GET',
      headers: headers,
    }
    let responseCode = 0
    return fetch(url, options)
      .then(res => {
        responseCode = res.status
        return res.json()
      })
      .then(json => {
        return RequestUtils.responseHandler(dispatch, responseCode, json, {
          err400: 'Erro ao consultar cidades!',
          err401: 'Sessão expirada!',
          err403: 'Sem permisão para consultar cidades!',
          err500: 'Erro ao consultar cidades!',
          errDefault: 'Erro desconhecido ao consultar cidades!'
        })
      })
      .then(json => {
        dispatch(receiveCidades(json))
        callback()
      })
      .catch(err => {
        dispatch(addToast('error', err.message, null, true))
        callback(err)
      })
  }
}

export function fetchEnderecoByCep(cep, callback = () => {}) {
  return dispatch => {
    const options = {
      timeout: {
        response: 5 * 1000,  // Wait 5s for the server to start sending,
        deadline: 5 * 1000, // but allow 5s for the file to finish loading.
      },
    }
    request.get(`${API_HOST}/endereco/cep/${cep}`, {}, options).then(json => {
      callback(null, json)
    }).catch(err => {
      console.error(err)
      dispatch(addToast('error', 'Endereço não encontrado. Preencha manualmente.', null, true))
      callback(err)
    })
  }
}

export function resetAllEndereco() {
  return {
    type: RESET_ALL_ENDERECO,
  }
}
