import {
  REQUEST_COUPONS, RECEIVE_COUPONS, REQUEST_COUPON, RECEIVE_COUPON, RESET_ALL_COUPON, RESET_COUPON, REQUEST_SAVE_COUPON,
  RECEIVE_SAVE_COUPON, REQUEST_REMOVE_COUPON, RECEIVE_REMOVE_COUPON, REQUEST_APPLY_COUPON, RECEIVE_APPLY_COUPON
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    data: {},
    isLoading: false,
    receivedAt: null,
  },
  itemSave: {
    isLoading: false,
  },
  itemRemove: {
    isLoading: false,
  },
  apply: {
    isLoading: false,
    data: {},
  },
}

export default function CouponReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_COUPONS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_COUPONS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_COUPON:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_COUPON:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_COUPON:
      return {
        ...state,
        item: INITIAL_STATE.item
      }
    case REQUEST_SAVE_COUPON:
    case RECEIVE_SAVE_COUPON:
      return {
        ...state,
        itemSave: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_COUPON:
    case RECEIVE_REMOVE_COUPON:
      return {
        ...state,
        itemRemove: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_APPLY_COUPON:
    case RECEIVE_APPLY_COUPON:
      return {
        ...state,
        apply: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.apply.data,
        }
      }
    case RESET_ALL_COUPON:
      return INITIAL_STATE
    default:
      return state
  }
}
