import {
  REQUEST_EQUIPAMENTOS, RECEIVE_EQUIPAMENTOS, REQUEST_EQUIPAMENTOS_LIVRES, RECEIVE_EQUIPAMENTOS_LIVRES,
  REQUEST_EQUIPAMENTOS_UTILIZADOS, RECEIVE_EQUIPAMENTOS_UTILIZADOS, REQUEST_EQUIPAMENTO, RECEIVE_EQUIPAMENTO,
  REQUEST_SAVE_EQUIPAMENTO, RECEIVE_SAVE_EQUIPAMENTO, REQUEST_REMOVE_EQUIPAMENTO, RECEIVE_REMOVE_EQUIPAMENTO,
  RESET_EQUIPAMENTO, REQUEST_EQUIPAMENTO_STATUS, RECEIVE_EQUIPAMENTO_STATUS, REQUEST_EQUIPAMENTO_DISPONIBILIDADE,
  RECEIVE_EQUIPAMENTO_DISPONIBILIDADE, REQUEST_ASSOCIATE_CLINICA_IN_BATCH, RECEIVE_ASSOCIATE_CLINICA_IN_BATCH,
  RESET_ALL_EQUIPAMENTO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  livres: {
    isLoading: false,
    data: {
      rows: [],
      count: 0
    },
    receivedAt: null,
  },
  utilizados: {
    isLoading: false,
    data: {
      rows: [],
      count: 0
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItem: {
    isLoading: false,
  },
  removeItem: {
    isLoading: false,
  },
  status: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  disponibilidade: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  associateClinicaInBatch: {
    isLoading: false,
  }
}

export default function EquipamentoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_EQUIPAMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_EQUIPAMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPAMENTOS_LIVRES:
      return {
        ...state,
        livres: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.livres.data,
        }
      }
    case RECEIVE_EQUIPAMENTOS_LIVRES:
      return {
        ...state,
        livres: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPAMENTOS_UTILIZADOS:
      return {
        ...state,
        utilizados: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.utilizados.data,
        }
      }
    case RECEIVE_EQUIPAMENTOS_UTILIZADOS:
      return {
        ...state,
        utilizados: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPAMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_EQUIPAMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_EQUIPAMENTO:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_EQUIPAMENTO:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_EQUIPAMENTO:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_EQUIPAMENTO:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RESET_EQUIPAMENTO:
      return {
        ...state,
        item: INITIAL_STATE.item
      }
    case REQUEST_EQUIPAMENTO_STATUS:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.status.data,
        }
      }
    case RECEIVE_EQUIPAMENTO_STATUS:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPAMENTO_DISPONIBILIDADE:
      return {
        ...state,
        disponibilidade: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.status.data,
        }
      }
    case RECEIVE_EQUIPAMENTO_DISPONIBILIDADE:
      return {
        ...state,
        disponibilidade: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ASSOCIATE_CLINICA_IN_BATCH:
      return {
        ...state,
        associateClinicaInBatch: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_ASSOCIATE_CLINICA_IN_BATCH:
      return {
        ...state,
        associateClinicaInBatch: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_EQUIPAMENTO:
      return INITIAL_STATE
    default:
      return state
  }
}
