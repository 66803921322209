import {
  REQUEST_PUBLIC_CLINICA_CONTEXT, RECEIVE_PUBLIC_CLINICA_CONTEXT, RESET_ALL_CONTEXT
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  clinicaPublicContext: {
    isLoading: false,
    context: null,
    contextMedico: null
  },
}

export default function ContextReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_PUBLIC_CLINICA_CONTEXT.type:
      return {
        ...state,
        clinicaPublicContext: {
          isLoading: action.isLoading,
          context: state.clinicaPublicContext.context,
          contextMedico: state.clinicaPublicContext.contextMedico,
        }
      }
    case RECEIVE_PUBLIC_CLINICA_CONTEXT.type:
      return {
        ...state,
        clinicaPublicContext: {
          isLoading: action.isLoading,
          context: action.context || state.clinicaPublicContext.context,
          contextMedico: action.contextMedico || state.clinicaPublicContext.contextMedico,
        }
      }
    case RESET_ALL_CONTEXT.type:
      return INITIAL_STATE
    default:
      return state
  }
}
