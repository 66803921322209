import { OPEN_IMAGE_VIEWER, CLOSE_IMAGE_VIEWER } from '../constants/ActionTypes'

const INITIAL_STATE = {
  images: [],
  visible: false,
  isImageTools: false,
  showPrint: true,
  forceIndex: null,
}

export default function ImageViewerReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case OPEN_IMAGE_VIEWER:
      return {
        ...state,
        images: action.images,
        isImageTools: action.isImageTools,
        showPrint: action.showPrint,
        forceIndex: action.forceIndex,
        visible: true
      }
    case CLOSE_IMAGE_VIEWER:
      return {
        ...state,
        images: INITIAL_STATE.images,
        isImageTools: INITIAL_STATE.isImageTools,
        showPrint: INITIAL_STATE.showPrint,
        forceIndex: INITIAL_STATE.forceIndex,
        visible: INITIAL_STATE.visible
      }
    default:
      return state
  }
}