import {
  REQUEST_TECNICOS, RECEIVE_TECNICOS, REQUEST_ALL_TECNICOS, RECEIVE_ALL_TECNICOS, REQUEST_TECNICO, RECEIVE_TECNICO,
  RESET_ALL_TECNICO, REQUEST_NOTAS_SERVICOS_TECNICO, RECEIVE_NOTAS_SERVICOS_TECNICO, REQUEST_SAVE_NOTA_SERVICOS_TECNICO,
  RECEIVE_SAVE_NOTA_SERVICOS_TECNICO, REQUEST_REMOVE_NOTA_SERVICOS_TECNICO, RECEIVE_REMOVE_NOTA_SERVICOS_TECNICO,
  RESET_TECNICO, REQUEST_SAVE_TECNICO, RECEIVE_SAVE_TECNICO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  notasServicos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
  removeNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
}

export default function TecnicoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_TECNICOS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_TECNICOS.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ALL_TECNICOS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_TECNICOS.type:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_TECNICO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_TECNICO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_SAVE_TECNICO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_TECNICO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_NOTAS_SERVICOS_TECNICO.type:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.notasServicos.data,
          receivedAt: INITIAL_STATE.notasServicos.receivedAt,
        }
      }
    case RECEIVE_NOTAS_SERVICOS_TECNICO.type:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_NOTA_SERVICOS_TECNICO.type:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.saveNotaServicos.receivedAt,
        }
      }
    case RECEIVE_SAVE_NOTA_SERVICOS_TECNICO.type:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_NOTA_SERVICOS_TECNICO.type:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.removeNotaServicos.receivedAt,
        }
      }
    case RECEIVE_REMOVE_NOTA_SERVICOS_TECNICO.type:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_TECNICO.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_TECNICO.type:
      return INITIAL_STATE
    default:
      return state
  }
}
