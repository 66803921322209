import {
  REQUEST_WHATSAPP_INSTANCES, RECEIVE_WHATSAPP_INSTANCES, REQUEST_WHATSAPP_INSTANCE, RECEIVE_WHATSAPP_INSTANCE,
  REQUEST_SAVE_WHATSAPP_INSTANCE, RECEIVE_SAVE_WHATSAPP_INSTANCE, REQUEST_WHATSAPP_INSTANCE_QRCODE,
  RECEIVE_WHATSAPP_INSTANCE_QRCODE, REQUEST_WHATSAPP_INSTANCE_STATUS, RECEIVE_WHATSAPP_INSTANCE_STATUS,
  REQUEST_WHATSAPP_INSTANCE_DEVICE, RECEIVE_WHATSAPP_INSTANCE_DEVICE, REQUEST_WHATSAPP_INSTANCE_ACTION,
  RECEIVE_WHATSAPP_INSTANCE_ACTION, REQUEST_WHATSAPP_MESSAGES, RECEIVE_WHATSAPP_MESSAGES, REQUEST_WHATSAPP_MESSAGE,
  RECEIVE_WHATSAPP_MESSAGE, REQUEST_RELATORIO_WHATSAPP_CLINICA, RECEIVE_RELATORIO_WHATSAPP_CLINICA, RESSET_ALL_WHATSAPP
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  instances: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  instance: {
    data: {},
    isLoading: false,
  },
  saveInstance: {
    isLoading: false,
  },
  instanceQrcode: {
    data: {},
    isLoading: false,
    receivedAt: null,
  },
  instanceStatus: {
    data: {},
    isLoading: false,
    receivedAt: null,
  },
  instanceDevice: {
    data: {},
    isLoading: false,
    receivedAt: null,
  },
  applyAction: {
    isLoading: false,
  },
  messages: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  message: {
    data: {},
    isLoading: false,
  },
  relatorioClinica: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
      sumResult: {
        amount: 0,
      },
    },
    receivedAt: null,
  },
}

export default function WhatsappReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_WHATSAPP_INSTANCES:
      return {
        ...state,
        instances: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.instances.data,
        }
      }
    case RECEIVE_WHATSAPP_INSTANCES:
      return {
        ...state,
        instances: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.instances.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_WHATSAPP_INSTANCE:
      return {
        ...state,
        instance: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.instance.data,
        }
      }
    case RECEIVE_WHATSAPP_INSTANCE:
      return {
        ...state,
        instance: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.instance.data,
        }
      }
    case REQUEST_SAVE_WHATSAPP_INSTANCE:
    case RECEIVE_SAVE_WHATSAPP_INSTANCE:
      return {
        ...state,
        saveInstance: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_WHATSAPP_INSTANCE_QRCODE:
      return {
        ...state,
        instanceQrcode: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.instanceQrcode.data,
        }
      }
    case RECEIVE_WHATSAPP_INSTANCE_QRCODE:
      return {
        ...state,
        instanceQrcode: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.instanceQrcode.data,
        }
      }
    case REQUEST_WHATSAPP_INSTANCE_STATUS:
      return {
        ...state,
        instanceStatus: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.instanceStatus.data,
        }
      }
    case RECEIVE_WHATSAPP_INSTANCE_STATUS:
      return {
        ...state,
        instanceStatus: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.instanceStatus.data,
        }
      }
    case REQUEST_WHATSAPP_INSTANCE_DEVICE:
      return {
        ...state,
        instanceDevice: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.instanceDevice.data,
        }
      }
    case RECEIVE_WHATSAPP_INSTANCE_DEVICE:
      return {
        ...state,
        instanceDevice: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.instanceDevice.data,
        }
      }
    case REQUEST_WHATSAPP_INSTANCE_ACTION:
    case RECEIVE_WHATSAPP_INSTANCE_ACTION:
      return {
        ...state,
        applyAction: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_WHATSAPP_MESSAGES:
      return {
        ...state,
        messages: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.messages.data,
        }
      }
    case RECEIVE_WHATSAPP_MESSAGES:
      return {
        ...state,
        messages: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.messages.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_WHATSAPP_MESSAGE:
      return {
        ...state,
        message: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.message.data,
        }
      }
    case RECEIVE_WHATSAPP_MESSAGE:
      return {
        ...state,
        message: {
          isLoading: action.isLoading,
          data: action.data ? action.data : INITIAL_STATE.message.data,
        }
      }
    case REQUEST_RELATORIO_WHATSAPP_CLINICA:
      return {
        ...state,
        relatorioClinica: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.relatorioClinica.data,
          receivedAt: state.relatorioClinica.receivedAt,
        }
      }
    case RECEIVE_RELATORIO_WHATSAPP_CLINICA:
      return {
        ...state,
        relatorioClinica: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESSET_ALL_WHATSAPP:
      return INITIAL_STATE
    default:
      return state
  }
}
