import { CHANGE_WINDOW_RESIZABLE } from '../constants/ActionTypes'

const INITIAL_STATE = {
  visible: false,
  state: 'floating', // maximized, minimized, floating
  title: '',
  content: null,
  top: 0,
  left: 0,
  width: 0,
  height: 0,
  minWidth: null,
  minHeight: null,
  minimizable: true,
}

export default function WindowResizableReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case CHANGE_WINDOW_RESIZABLE:
      return {
        ...state,
        ...action.payload
      }
    default:
      return state
  }
}