import {
  REQUEST_LAUDOS, RECEIVE_LAUDOS, REQUEST_LAUDO, RECEIVE_LAUDO, REQUEST_LAUDOS_BY_PACIENTE, RECEIVE_LAUDOS_BY_PACIENTE,
  REQUEST_SAVE_LAUDO, RECEIVE_SAVE_LAUDO, REQUEST_LAUDO_TEXTOS, RECEIVE_LAUDO_TEXTOS, REQUEST_LAUDO_TEXTO,
  RECEIVE_LAUDO_TEXTO, RESET_LAUDO_TEXTO, REQUEST_SAVE_LAUDO_TEXTO, RECEIVE_SAVE_LAUDO_TEXTO, REQUEST_DELETE_LAUDO_TEXTO,
  RECEIVE_DELETE_LAUDO_TEXTO, REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE, RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE,
  REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS, RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS, REQUEST_REMOVE_LAUDO,
  RECEIVE_REMOVE_LAUDO, RESET_ALL_LAUDO, REQUEST_LAUDO_TRANSLATE, RECEIVE_LAUDO_TRANSLATE, REQUEST_LABELS_BONUS,
  RECEIVE_LABELS_BONUS, REQUEST_LAUDO_TEXTO_TAGS, RECEIVE_LAUDO_TEXTO_TAGS, REQUEST_SAVE_LAUDO_TEXTO_TAG,
  RECEIVE_SAVE_LAUDO_TEXTO_TAG
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  translate: {
    isLoading: false,
    data: '',
    receivedAt: null,
  },
  listByPaciente: {
    pacienteId: null,
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  laudoTextos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  laudoTexto: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveLaudoTexto: {
    isLoading: false,
  },
  deleteLaudoTexto: {
    isLoading: false,
  },
  laudoTextosByEspecialidade: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  orientacaoTextosByEspecialidade: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  laudoTextosOitAdicionais: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  removeItem: {
    isLoading: false,
  },
  labelsBonus: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  laudoTextoTags: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 200,
    },
    receivedAt: null,
  },
  saveLaudoTextoTag: {
    isLoading: false,
  },
}

export default function LaudoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_LAUDOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_LAUDOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_LAUDO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDO_TRANSLATE:
      return {
        ...state,
        translate: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.translate.data,
          receivedAt: INITIAL_STATE.translate.receivedAt,
        }
      }
    case RECEIVE_LAUDO_TRANSLATE:
      return {
        ...state,
        translate: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDOS_BY_PACIENTE:
      return {
        ...state,
        listByPaciente: {
          isLoading: action.isLoading,
          idPaciente: action.idPaciente,
          data: INITIAL_STATE.listByPaciente.data,
        }
      }
    case RECEIVE_LAUDOS_BY_PACIENTE:
      return {
        ...state,
        listByPaciente: {
          isLoading: action.isLoading,
          idPaciente: action.idPaciente,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_LAUDO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_LAUDO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDO_TEXTOS:
      return {
        ...state,
        laudoTextos: {
          isLoading: action.isLoading,
          params: action.params,
          data: INITIAL_STATE.laudoTextos.data,
        }
      }
    case RECEIVE_LAUDO_TEXTOS:
      return {
        ...state,
        laudoTextos: {
          isLoading: action.isLoading,
          params: state.laudoTextos.params,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDO_TEXTO:
      return {
        ...state,
        laudoTexto: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.laudoTexto.data,
        }
      }
    case RECEIVE_LAUDO_TEXTO:
      return {
        ...state,
        laudoTexto: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_LAUDO_TEXTO:
      return {
        ...state,
        laudoTexto: INITIAL_STATE.laudoTexto
      }
    case REQUEST_SAVE_LAUDO_TEXTO:
      return {
        ...state,
        saveLaudoTexto: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_LAUDO_TEXTO:
      return {
        ...state,
        saveLaudoTexto: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_DELETE_LAUDO_TEXTO:
      return {
        ...state,
        deleteLaudoTextos: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_DELETE_LAUDO_TEXTO:
      return {
        ...state,
        deleteLaudoTextos: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_LAUDO_TEXTOS_BY_ESPECIALIDADE:
      if (action.isOrientacao) {
        return {
          ...state,
          orientacaoTextosByEspecialidade: {
            isLoading: action.isLoading,
            data: INITIAL_STATE.orientacaoTextosByEspecialidade.data,
          }
        }
      } else {
        return {
          ...state,
          laudoTextosByEspecialidade: {
            isLoading: action.isLoading,
            data: INITIAL_STATE.laudoTextosByEspecialidade.data,
          }
        }
      }
    case RECEIVE_LAUDO_TEXTOS_BY_ESPECIALIDADE:
      if (action.isOrientacao) {
        return {
          ...state,
          orientacaoTextosByEspecialidade: {
            isLoading: action.isLoading,
            data: action.data,
            receivedAt: action.receivedAt,
          }
        }
      } else {
        return {
          ...state,
          laudoTextosByEspecialidade: {
            isLoading: action.isLoading,
            data: action.data,
            receivedAt: action.receivedAt,
          }
        }
      }
    case REQUEST_LAUDO_TEXTOS_OIT_ADICIONAIS:
      return {
        ...state,
        laudoTextosOitAdicionais: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.laudoTextosByEspecialidade.data,
        }
      }
    case RECEIVE_LAUDO_TEXTOS_OIT_ADICIONAIS:
      return {
        ...state,
        laudoTextosOitAdicionais: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_LAUDO.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_LAUDO.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_LABELS_BONUS:
      return {
        ...state,
        labelsBonus: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.labelsBonus.data,
        }
      }
    case RECEIVE_LABELS_BONUS:
      return {
        ...state,
        labelsBonus: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_LAUDO_TEXTO_TAGS:
      return {
        ...state,
        laudoTextoTags: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.laudoTextoTags.data,
        }
      }
    case RECEIVE_LAUDO_TEXTO_TAGS:
      return {
        ...state,
        laudoTextoTags: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_LAUDO_TEXTO_TAG:
      return {
        ...state,
        saveLaudoTextoTag: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_LAUDO_TEXTO_TAG:
      return {
        ...state,
        saveLaudoTextoTag: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_LAUDO:
      return INITIAL_STATE
    default:
      return state
  }
}
