/**
 * Escuta eventos da página e despacha para a action responsável por tratar.
 */

import {
  onWindowLoad, navigatorOnLine, toggleChatVisible, openChat, updateNewChatMessages, receiveChatBroadcastMessage,
  windowActive, windowVisible,
} from '../actions/AppActions'

function onWindowPostMessage (e, dispatch) {
  // console.log('onWindowPostMessage: ', e.data, ' from: ', e.origin)
  if (typeof e.data === 'object' && e.data && 'type' in e.data && e.data.type === 'UPDATE_NEW_CHAT_MESSAGES') {
    dispatch(updateNewChatMessages(e.data.qtdMessages))
  } else if (typeof e.data === 'object' && e.data && 'type' in e.data && e.data.type === 'MINIMIZE_CHAT') {
    dispatch(toggleChatVisible())
  } else if (typeof e.data === 'object' && e.data && 'type' in e.data && e.data.type === 'OPEN_CHAT') {
    dispatch(openChat())
  } else if (typeof e.data === 'object' && e.data && 'type' in e.data && e.data.type === 'FINISH_BROADCAST_MESSAGE') {
    dispatch(receiveChatBroadcastMessage())
  } else {
    return {type: ''} // é necessário passar um type vazio para n quebrar outras camadas
  }
}

/**
 * Fnção de inicialização do listner.
 *
 * @param {Object} dispatch
 * @param {Object} getState
 */
export default function(dispatch) {
  window.addEventListener('load', (e) => dispatch(onWindowLoad()))
  window.addEventListener('offline', (e) => dispatch(navigatorOnLine()))
  window.addEventListener('online',(e) => dispatch(navigatorOnLine()))
  window.addEventListener('focus', (e) => dispatch(windowActive(true)))
  window.addEventListener('blur', (e) => dispatch(windowActive(false)))
  window.addEventListener('visibilitychange', (e) => dispatch(windowVisible(document.visibilityState))) // hidden | visible

  if (window.addEventListener) {
    window.addEventListener('message', (e) => onWindowPostMessage(e, dispatch), false)
  } else {
    window.attachEvent('onmessage', (e) => onWindowPostMessage(e, dispatch))
  }
}
