// third party
import React from 'react'
import { render } from 'react-dom'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { createStore, applyMiddleware, compose } from 'redux'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { responsiveStoreEnhancer } from 'redux-responsive'
// Firebase
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// import ReactGA from 'react-ga'
import ReactGA from 'react-ga4'
// events
import WindowEvents from './events/WindowEvents'
// reducer
import RootReducer from './reducers/RootReducer'
// componets
import MainRouter from './components/MainRouter'
// middlewares
import { verifyLogged } from './middlewares/VerifyLogged'
import { bootstrap } from './middlewares/Bootstrap'
// import { offlineRequestsDiscard } from 'middlewares/OfflineRequestsDiscard'
import * as serviceWorker from './serviceWorker'

let history = createBrowserHistory()

// expoe globalemente
window.router = history

// Ga
// -----------------------------------------------------------------------------
// inicializa google analytics
ReactGA.initialize('G-K56FBBV3FZ') // TODO: dinamizar no build

// atualiza rota no google analytics
history.listen(location =>  {
  // ReactGA.pageview(location.pathname)
  ReactGA.send('pageview')
  ReactGA.send({ hitType: "pageview", page: location.pathname })
})

// Firebase
// -----------------------------------------------------------------------------
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
/*const firebaseConfig = {
  apiKey: "AIzaSyBNYEWXZbCKTfQhZCGFHwpnZScVuyhNJx8",
  authDomain: "medic-systems.firebaseapp.com",
  projectId: "medic-systems",
  storageBucket: "medic-systems.appspot.com",
  messagingSenderId: "689299263317",
  appId: "1:689299263317:web:89bfcc15bd601b600169dc",
  measurementId: "G-K56FBBV3FZ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)*/
// -----------------------------------------------------------------------------

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

const store = createStore(
  RootReducer,
  composeEnhancer(
    applyMiddleware(
      thunk,
      verifyLogged,
      bootstrap,
    ),
    responsiveStoreEnhancer
  ),
)

WindowEvents(store.dispatch, store.getState)

render(
  <Provider store={store}>
    <Router history={history}>
      <MainRouter />
    </Router>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister()
serviceWorker.register()
