import {
  REQUEST_BOLETOS, RECEIVE_BOLETOS, REQUEST_BOLETO, RECEIVE_BOLETO, REQUEST_BALANCO_ANUAL, RECEIVE_BALANCO_ANUAL,
  REQUEST_ATUALIZAR_VENCIMENTO, RECEIVE_ATUALIZAR_VENCIMENTO, REQUEST_INFORMAR_PAGAMENTO, RECEIVE_INFORMAR_PAGAMENTO,
  REQUEST_REMESSAS, RECEIVE_REMESSAS, REQUEST_RETORNOS, RECEIVE_RETORNOS, REQUEST_CAUSAS_REJEICAO_BANCO,
  RECEIVE_CAUSAS_REJEICAO_BANCO, REQUEST_STATUS_BANCO, RECEIVE_STATUS_BANCO, REQUEST_GENERATE_REMESSA,
  RECEIVE_GENERATE_REMESSA, REQUEST_PROCESS_RETORNO, RECEIVE_PROCESS_RETORNO, REQUEST_STATUS, RECEIVE_STATUS,
  RESET_ALL_FATURA, REQUEST_CONFIRMAR_PAGAMENTO, RECEIVE_CONFIRMAR_PAGAMENTO, REQUEST_REJEITAR_PAGAMENTO,
  RECEIVE_REJEITAR_PAGAMENTO, REQUEST_UPDATE_FATURA, RECEIVE_UPDATE_FATURA, REQUEST_FATURA_PIX, RECEIVE_FATURA_PIX
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  boletos: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  balancoAnual: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  atualizarVencimento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  informarPagamento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  confirmarPagamento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  rejeitarPagamento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  remessas: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  generateRemessa: {
    isLoading: false,
  },
  retornos: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  processRetorno: {
    isLoading: false,
    receivedAt: null,
  },
  causasRejeicaoBanco: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  statusBanco: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  status: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  updateFatura: {
    isLoading: false,
  },
  pix: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
}

export default function FaturaReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_BOLETOS.type:
      return {
        ...state,
        boletos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.boletos.data,
          receivedAt: state.boletos.receivedAt,
        }
      }
    case RECEIVE_BOLETOS.type:
      return {
        ...state,
        boletos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_BOLETO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          // data: INITIAL_STATE.item.data,
          data: (action.isSilent) ? state.item.data : INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_BOLETO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_BALANCO_ANUAL.type:
      return {
        ...state,
        balancoAnual: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.balancoAnual.data,
        }
      }
    case RECEIVE_BALANCO_ANUAL.type:
      return {
        ...state,
        balancoAnual: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ATUALIZAR_VENCIMENTO.type:
      return {
        ...state,
        atualizarVencimento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.balancoAnual.data,
        }
      }
    case RECEIVE_ATUALIZAR_VENCIMENTO.type:
      return {
        ...state,
        atualizarVencimento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_INFORMAR_PAGAMENTO.type:
      return {
        ...state,
        informarPagamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.balancoAnual.data,
        }
      }
    case RECEIVE_INFORMAR_PAGAMENTO.type:
      return {
        ...state,
        informarPagamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMESSAS.type:
      return {
        ...state,
        remessas: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.remessas.data,
        }
      }
    case RECEIVE_REMESSAS.type:
      return {
        ...state,
        remessas: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_RETORNOS.type:
      return {
        ...state,
        retornos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.retornos.data,
        }
      }
    case RECEIVE_RETORNOS.type:
      return {
        ...state,
        retornos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_CAUSAS_REJEICAO_BANCO.type:
      return {
        ...state,
        causasRejeicaoBanco: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.causasRejeicaoBanco.data,
        }
      }
    case RECEIVE_CAUSAS_REJEICAO_BANCO.type:
      return {
        ...state,
        causasRejeicaoBanco: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_STATUS.type:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.status.data,
        }
      }
    case RECEIVE_STATUS.type:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_STATUS_BANCO.type:
      return {
        ...state,
        statusBanco: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.statusBanco.data,
        }
      }
    case RECEIVE_STATUS_BANCO.type:
      return {
        ...state,
        statusBanco: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_GENERATE_REMESSA.type:
      return {
        ...state,
        generateRemessa: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_GENERATE_REMESSA.type:
      return {
        ...state,
        generateRemessa: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_PROCESS_RETORNO.type:
      return {
        ...state,
        processRetorno: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_PROCESS_RETORNO.type:
      return {
        ...state,
        processRetorno: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_UPDATE_FATURA.type:
      return {
        ...state,
        updateFatura: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_UPDATE_FATURA.type:
      return {
        ...state,
        updateFatura: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_CONFIRMAR_PAGAMENTO.type:
      return {
        ...state,
        confirmarPagamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.confirmarPagamento.data,
        }
      }
    case RECEIVE_CONFIRMAR_PAGAMENTO.type:
      return {
        ...state,
        confirmarPagamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REJEITAR_PAGAMENTO.type:
      return {
        ...state,
        rejeitarPagamento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.rejeitarPagamento.data,
        }
      }
    case RECEIVE_REJEITAR_PAGAMENTO.type:
      return {
        ...state,
        rejeitarPagamento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_FATURA_PIX:
      return {
        ...state,
        pix: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.pix.data,
          receivedAt: INITIAL_STATE.pix.receivedAt,
        }
      }
    case RECEIVE_FATURA_PIX:
      return {
        ...state,
        pix: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_FATURA.type:
      return INITIAL_STATE
    default:
      return state
  }
}
