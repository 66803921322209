import {
  REQUEST_AVISOS, RECEIVE_AVISOS, REQUEST_AVISO, RECEIVE_AVISO, REQUEST_SAVE_AVISO, RECEIVE_SAVE_AVISO,
  REQUEST_REMOVE_AVISO, RECEIVE_REMOVE_AVISO, REQUEST_READ_AVISO, RECEIVE_READ_AVISO, RESET_ALL_AVISO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  avisos: {
    isLoading: false,
    params: null,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItem: {
    isLoading: false,
  },
  removeItem: {
    isLoading: false,
  },
  readItem: {
    isLoading: false,
  }
}

export default function AvisoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_AVISOS.type:
      return {
        ...state,
        avisos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.avisos.data,
          params: action.params,
        }
      }
    case RECEIVE_AVISOS.type:
      return {
        ...state,
        avisos: {
          isLoading: action.isLoading,
          data: action.data,
          params: state.avisos.params,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_AVISO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_AVISO.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_AVISO.type:
    case RECEIVE_SAVE_AVISO.type:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_AVISO.type:
    case RECEIVE_REMOVE_AVISO.type:
      return {
        ...state,
        removeItem: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_READ_AVISO:
    case RECEIVE_READ_AVISO:
      return {
        ...state,
        readItem: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_AVISO.type:
      return INITIAL_STATE
    default:
      return state
  }
}