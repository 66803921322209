import superagent from 'superagent'
import { getSessionStorage } from '../../actions/StorageActions'
import { EXPIRED_SESSION_ERROR, EXPIRED_APP_VERSION_ERROR } from '../../constants/ErrorTypes'

const inspectResponseHandler = (res, opt) => {
  if (Number(res.statusCode) !== 200) {
    // se 401 e dispatch presente realiza logout
    if (Number(res.statusCode) === 401 && opt.logoutOn401) {
      throw new Error(EXPIRED_SESSION_ERROR)
    }
    // se 401 e dispatch presente realiza logout
    if (Number(res.statusCode) === 419) {
      throw new Error(EXPIRED_APP_VERSION_ERROR)
    }
    // monta ID do erro
    const errorID = (opt.requestId) ? `${opt.requestId} - ` : ''
    // retorna erro para o usuario final vinda da API caso exista
    if (res && 'body' in res && 'userMessage' in res.body) {
      // throw new Error(errorID + res.body.userMessage)
      throw new Error(res.body.userMessage)
    }
    // retorna erro de acordo com o status code
    if (res.statusCode in opt.errorMessages) {
      throw new Error(errorID + opt.errorMessages[res.statusCode])
    } else {
      throw new Error(errorID + 'Erro desconhecido')
    }
  }
  return res
}

const parseResponseHandler = (res, opt) => {
  return res.body
}

const headersPlugin = req => {
  req.set('Content-Type', 'application/json')
  req.set('Cache-Control', 'no-cache,no-store,must-revalidate,max-age=-1')
  const token = getSessionStorage('jwt')
  if (token) req.set('Authorization', `Bearer ${token}`)
  var timestamp = Date.now().toString()
  if (req._query !== undefined && req._query[0]) {
    req._query[0] += '&' + timestamp
  } else {
    req._query = [timestamp]
  }
}

let defaultOptions = {
    requestId: null,
    errorMessages: {
      400: 'Erro nos parâmetros da requisição',
      401: 'Sessão expirada',
      403: 'Sem permisão para acessar este recurso',
      404: 'Recurso não encontrado',
      500: 'Requisição retornou erro.'
    },
    timeout: {
      response: 3 * 60 * 1000,  // Wait 3 minutes for the server to start sending,
      deadline: 3 * 60 * 1000, // but allow 3 minute for the file to finish loading.
    },
    logoutOn401: true
}

const Requests = {
  delete: (url, params, options = {}) => {
    const opt = {...defaultOptions, ...options}
    return superagent
      .del(url)
      .query(params)
      .timeout(opt.timeout)
      .use(headersPlugin)
      .ok(res => inspectResponseHandler(res, opt))
      .then(res => parseResponseHandler(res, opt))
      // .catch(errorHandler)
  },
  get: (url, params, options = {}) => {
    const opt = {...defaultOptions, ...options}
    return superagent
      .get(url)
      .query(params)
      .timeout(opt.timeout)
      .use(headersPlugin)
      .ok(res => inspectResponseHandler(res, opt))
      .then(res => parseResponseHandler(res, opt))
      // .catch(err => errorHandler(err))
  },
  put: (url, params, body, options = {}) => {
    const opt = {...defaultOptions, ...options}
    return superagent
      .put(url, body)
      .query(params)
      .timeout(opt.timeout)
      .use(headersPlugin)
      .ok(res => inspectResponseHandler(res, opt))
      .then(res => parseResponseHandler(res, opt))
      // .catch(errorHandler)
  },
  post: (url, params, body, options = {}) => {
    const opt = {...defaultOptions, ...options}
    return superagent
      .post(url, body)
      .query(params)
      .timeout(opt.timeout)
      .use(headersPlugin)
      .ok(res => inspectResponseHandler(res, opt))
      .then(res => parseResponseHandler(res, opt))
      // .catch(errorHandler)
  }
}

export default Requests
