import {
  REQUEST_FERRAMENTAS_APOIO, RECEIVE_FERRAMENTAS_APOIO, REQUEST_FERRAMENTA_APOIO, RECEIVE_FERRAMENTA_APOIO,
  REQUEST_SAVE_FERRAMENTA_APOIO, RECEIVE_SAVE_FERRAMENTA_APOIO, REQUEST_REMOVE_FERRAMENTA_APOIO,
  RECEIVE_REMOVE_FERRAMENTA_APOIO, RESET_FERRAMENTA_APOIO, REQUEST_APOIO_DOCUMENTOS, RECEIVE_APOIO_DOCUMENTOS,
  REQUEST_ENVIAR_APOIO_DOCUMENTO, RECEIVE_ENVIAR_APOIO_DOCUMENTO, REQUEST_REMOVE_APOIO_DOCUMENTO,
  RECEIVE_REMOVE_APOIO_DOCUMENTO, REQUEST_EMPRESAS_CONCORRENTES, RECEIVE_EMPRESAS_CONCORRENTES,
  REQUEST_EMPRESA_CONCORRENTE, RECEIVE_EMPRESA_CONCORRENTE, REQUEST_SAVE_EMPRESA_CONCORRENTE,
  RECEIVE_SAVE_EMPRESA_CONCORRENTE, REQUEST_REMOVE_EMPRESA_CONCORRENTE, RECEIVE_REMOVE_EMPRESA_CONCORRENTE,
  RESET_EMPRESA_CONCORRENTE, REQUEST_ESPECIALIDADES_CONCORRENTES, RECEIVE_ESPECIALIDADES_CONCORRENTES,
  REQUEST_ESPECIALIDADE_CONCORRENTE, RECEIVE_ESPECIALIDADE_CONCORRENTE, REQUEST_SAVE_ESPECIALIDADE_CONCORRENTE,
  RECEIVE_SAVE_ESPECIALIDADE_CONCORRENTE, REQUEST_REMOVE_ESPECIALIDADE_CONCORRENTE,
  RECEIVE_REMOVE_ESPECIALIDADE_CONCORRENTE, RESET_ESPECIALIDADE_CONCORRENTE, RESET_ALL_FERRAMENTAS
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  // apoio
  // ---------------------------------------------------------------------------
  listApoio: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemApoio: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItemApoio: {
    isLoading: false,
  },
  removeItemApoio: {
    isLoading: false,
  },
  apoioDocumentos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  itemApoioEnviarDocumento: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  itemApoioRemoveDocumento: {
    isLoading: false,
    receivedAt: null,
  },
  // empresas concorrentes
  // ---------------------------------------------------------------------------
  listEmpresaConcorrente: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemEmpresaConcorrente: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItemEmpresaConcorrente: {
    isLoading: false,
  },
  removeItemEmpresaConcorrente: {
    isLoading: false,
  },
  // especialidades concorrentes
  // ---------------------------------------------------------------------------
  listEspecialidadeConcorrente: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  itemEspecialidadeConcorrente: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItemEspecialidadeConcorrente: {
    isLoading: false,
  },
  removeItemEspecialidadeConcorrente: {
    isLoading: false,
  },
}

export default function FerramentasReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_FERRAMENTAS_APOIO.type:
      return {
        ...state,
        listApoio: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listApoio.data,
        }
      }
    case RECEIVE_FERRAMENTAS_APOIO.type:
      return {
        ...state,
        listApoio: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_FERRAMENTA_APOIO.type:
      return {
        ...state,
        itemApoio: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemApoio.data,
        }
      }
    case RECEIVE_FERRAMENTA_APOIO.type:
      return {
        ...state,
        itemApoio: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_FERRAMENTA_APOIO.type:
      return {
        ...state,
        saveItemApoio: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_FERRAMENTA_APOIO.type:
      return {
        ...state,
        saveItemApoio: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_FERRAMENTA_APOIO.type:
      return {
        ...state,
        removeItemApoio: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_FERRAMENTA_APOIO.type:
      return {
        ...state,
        removeItemApoio: {
          isLoading: action.isLoading,
        }
      }
    case RESET_FERRAMENTA_APOIO.type:
      return {
        ...state,
        itemApoio: INITIAL_STATE.itemApoio
      }
    case REQUEST_APOIO_DOCUMENTOS.type:
      return {
        ...state,
        apoioDocumentos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.apoioDocumentos.data,
        }
      }
    case RECEIVE_APOIO_DOCUMENTOS.type:
      return {
        ...state,
        apoioDocumentos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENVIAR_APOIO_DOCUMENTO.type:
      return {
        ...state,
        itemApoioEnviarDocumento: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemApoioEnviarDocumento.data,
        }
      }
    case RECEIVE_ENVIAR_APOIO_DOCUMENTO.type:
      return {
        ...state,
        itemApoioEnviarDocumento: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_APOIO_DOCUMENTO.type:
      return {
        ...state,
        itemApoioRemoveDocumento: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.itemApoioRemoveDocumento.receivedAt,
        }
      }
    case RECEIVE_REMOVE_APOIO_DOCUMENTO.type:
      return {
        ...state,
        itemApoioRemoveDocumento: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    // empreas concorrentes
    // -------------------------------------------------------------------------
    case REQUEST_EMPRESAS_CONCORRENTES.type:
      return {
        ...state,
        listEmpresaConcorrente: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listEmpresaConcorrente.data,
        }
      }
    case RECEIVE_EMPRESAS_CONCORRENTES.type:
      return {
        ...state,
        listEmpresaConcorrente: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        itemEmpresaConcorrente: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemEmpresaConcorrente.data,
        }
      }
    case RECEIVE_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        itemEmpresaConcorrente: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        saveItemEmpresaConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        saveItemEmpresaConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        removeItemEmpresaConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        removeItemEmpresaConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RESET_EMPRESA_CONCORRENTE.type:
      return {
        ...state,
        itemEmpresaConcorrente: INITIAL_STATE.itemEmpresaConcorrente
      }
    // especialidades concorrentes
    // -------------------------------------------------------------------------
    case REQUEST_ESPECIALIDADES_CONCORRENTES.type:
      return {
        ...state,
        listEspecialidadeConcorrente: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.listEspecialidadeConcorrente.data,
        }
      }
    case RECEIVE_ESPECIALIDADES_CONCORRENTES.type:
      return {
        ...state,
        listEspecialidadeConcorrente: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        itemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.itemEspecialidadeConcorrente.data,
        }
      }
    case RECEIVE_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        itemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        saveItemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        saveItemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        removeItemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_REMOVE_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        removeItemEspecialidadeConcorrente: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ESPECIALIDADE_CONCORRENTE.type:
      return {
        ...state,
        itemEspecialidadeConcorrente: INITIAL_STATE.itemEspecialidadeConcorrente
      }
    // -------------------------------------------------------------------------
    case RESET_ALL_FERRAMENTAS.type:
      return INITIAL_STATE
    default:
      return state
  }
}
