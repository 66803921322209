// utils
import request from '../utils/request/Request'
// constants
import { API_HOST } from '../constants/EnvTypes'
import {
  REQUEST_PLANTAO_QUEUE_INFO, RECEIVE_PLANTAO_QUEUE_INFO, REQUEST_CAPTURAR_PLANTAO_UNICO, RECEIVE_CAPTURAR_PLANTAO_UNICO,
  RESET_ALL_PLANTAO_UNICO
} from '../constants/ActionTypes'
// actions
import { addToast } from './ToastActions'
import { showRequestError } from './AppActions'
import { fetchEntityInformacoesAdicionais } from './AuthActions'
import { fetchIniciarTeleconsulta } from './TeleconsultaActions'

function requestPlantaoQueueInfo(silent) {
  return {
    type: REQUEST_PLANTAO_QUEUE_INFO,
    isLoading: (silent) ? false : true,
    isSilent: silent,
    receivedAt: null,
  }
}

function receivePlantaoQueueInfo(data) {
  return {
    type: RECEIVE_PLANTAO_QUEUE_INFO,
    isLoading: false,
    data,
    receivedAt: Date.now(),
  }
}

export function fetchPlantaoQueueInfo(silent = false) {
  return dispatch => {
    dispatch(requestPlantaoQueueInfo(silent))
    const url = `${API_HOST}/teleconsulta/plantao-unico/queue-info`
    request.get(url).then(json => {
      dispatch(receivePlantaoQueueInfo(json))
    }).catch(err => {
      dispatch(receivePlantaoQueueInfo())
      dispatch(showRequestError(err))
    })
  }
}

function requestCapturarPlnataoUnico() {
  return {
    type: REQUEST_CAPTURAR_PLANTAO_UNICO,
    isLoading: true,
  }
}

function receiveCapturarPlnataoUnico() {
  return {
    type: RECEIVE_CAPTURAR_PLANTAO_UNICO,
    isLoading: false,
  }
}

export function fetchCapturarPlantaoUnico(callback = () => {}) {
  return dispatch => {
    const url = `${API_HOST}/teleconsulta/plantao-unico/capturar`
    request.put(url).then(json => {
      dispatch(requestCapturarPlnataoUnico())
      setTimeout(() => {
        // window.router.push(`/teleconsulta/${json.id}/consultar`)
        dispatch(fetchIniciarTeleconsulta(json.id))
      }, 200)
      dispatch(fetchEntityInformacoesAdicionais())
      dispatch(receiveCapturarPlnataoUnico())
      callback(true)
    }).catch(err => {
      dispatch(showRequestError(err))
      dispatch(fetchEntityInformacoesAdicionais())
      dispatch(fetchPlantaoQueueInfo(true))
      dispatch(receiveCapturarPlnataoUnico())
      if (typeof window != 'undefined') { // em ambiente de teste nao existe
        window.router.push('/')
      }
      callback(false)
    })
  }
}

export function resetAllPlantaoUnico() {
  return {
    type: RESET_ALL_PLANTAO_UNICO,
  }
}
