import {
  REQUEST_ENCAMINHAMENTOS, RECEIVE_ENCAMINHAMENTOS, REQUEST_ENCAMINHAMENTO, RECEIVE_ENCAMINHAMENTO,
  REQUEST_SAVE_ENCAMINHAMENTO, RECEIVE_SAVE_ENCAMINHAMENTO, RESET_ENCAMINHAMENTO, REQUEST_ENCAMINHAMENTO_STATUS,
  RECEIVE_ENCAMINHAMENTO_STATUS, RESET_ALL_ENCAMINHAMENTO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  saveItem: {
    isLoading: false,
  },
  status: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
}

export default function EncaminhamentoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ENCAMINHAMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_ENCAMINHAMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ENCAMINHAMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_ENCAMINHAMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_ENCAMINHAMENTO:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case RECEIVE_SAVE_ENCAMINHAMENTO:
      return {
        ...state,
        saveItem: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ENCAMINHAMENTO:
      return {
        ...state,
        item: INITIAL_STATE.item
      }
    case REQUEST_ENCAMINHAMENTO_STATUS:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.status.data,
        }
      }
    case RECEIVE_ENCAMINHAMENTO_STATUS:
      return {
        ...state,
        status: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_ALL_ENCAMINHAMENTO:
      return INITIAL_STATE
    default:
      return state
  }
}
