let ui_host = null
let api_host = null
let api_auth = null
let service_chat = null
let api_memed = null
let memed = null

console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')
console.log(process.env)
console.log('&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&&')

if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SERVER === 'tmm') {
  // PROD MORSCH
  ui_host = 'https://app.telemedicinamorsch.com.br'
  api_host = '//app.telemedicinamorsch.com.br/api-main/v1'
  api_auth = '//app.telemedicinamorsch.com.br/api-auth/v1'
  service_chat = '//app.telemedicinamorsch.com.br/service-chat/v1'
  api_memed = 'https://api.memed.com.br'
  memed = 'https://memed.com.br'

} else if (process.env.NODE_ENV === 'production' && process.env.REACT_APP_SERVER === 'ms') {
  // PROD MS
  ui_host = 'https://app.medicsystems.com.br'
  api_host = '//app.medicsystems.com.br/api-main/v1'
  api_auth = '//app.medicsystems.com.br/api-auth/v1'
  service_chat = '//app.medicsystems.com.br/service-chat/v1'
  api_memed = 'https://api.memed.com.br'
  memed = 'https://memed.com.br'

} else if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_SERVER === 'tmm') {
  // DEV MORSCH
  ui_host = 'https://ui.telemedicinamorsch.com.br'
  api_host = '//ui.telemedicinamorsch.com.br/api-main/v1'
  api_auth = '//ui.telemedicinamorsch.com.br/api-auth/v1'
  service_chat = '//ui.telemedicinamorsch.com.br/service-chat/v1'
  api_memed = 'https://integracao.api.memed.com.br'
  memed = 'https://integracao.memed.com.br'

} else if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_SERVER === 'ms') {
  // DEV MS
  ui_host = 'https://dev.medicsystems.com.br.com.br'
  api_host = '//dev.medicsystems.com.br/api-main/v1'
  api_auth = '//dev.medicsystems.com.br/api-auth/v1'
  service_chat = '//dev.medicsystems.com.br/service-chat/v1'
  api_memed = 'https://integracao.api.memed.com.br'
  memed = 'https://integracao.memed.com.br'
}

export const UI_HOST = ui_host
export const API_HOST = api_host
export const API_AUTH = api_auth
export const SERVICE_CHAT = service_chat
export const API_MEMED = api_memed
export const MEMED = memed
