import { base64decode } from './Base64Utils'

/**
 * Decodifica o payload de um token de autenticação JWT.
 * Atenção, não é verificada a assinatura, então não confiar nas informações, utilize apenas para obter dados
 * não críticos para aplicação.
 */
export const parseJwt = (token) => {
  let base64Url = token.split('.')[1]
  let base64 = base64Url.replace('-', '+').replace('_', '/')
  return JSON.parse(base64decode(base64))
}
