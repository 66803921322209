/**
 * Métodos úteis para manipulação de URLs e parâmetros.
 */


/**
 * Converte query string para um objeto chave / valor.
 *
 * @param {String} str Query string no formato '?parama=1&param2=2'
 * @returns {Object}
 * @public
 */
export const query2Object = (str) => {
  let query = {}
  if (!str) return query
  let pairs = (str[0] === '?' ? str.substr(1) : str).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

/**
 * Converte um objeto chave / valor em query string.
 *
 * @param {Object}
 * @returns {String}
 * @public
 */
export const object2Query = (obj) => {
  let query = []
  for (const key in obj) {
    const value = obj[key]
    query.push(`${key}=${value}`)
  }
  return `?${query.join('&')}`
}

/**
 * Converte uma string para um slug de URL.
 *
 * @param {String} text
 */
export const slugify = (text) => {
  return text
    .toString()                 // Cast to string
    .toLowerCase()              // Convert the string to lowercase letters
    .normalize('NFD')           // The normalize() method returns the Unicode Normalization Form of a given string.
    .trim()                     // Remove whitespace from both sides of a string
    .replace(/\s+/g, '-')       // Replace spaces with -
    .replace(/[^\w\-]+/g, '')   // Remove all non-word chars
    .replace(/\-\-+/g, '-');    // Replace multiple - with single -
}
