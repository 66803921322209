import React, { Component } from 'react'
import { connect } from 'react-redux'
import { compose, bindActionCreators } from 'redux'
import PropTypes from 'prop-types'
import { withRouter } from "react-router-dom"
import Snackbar from 'react-md/lib/Snackbars/SnackbarContainer'

import { dismissToast } from '../../actions/ToastActions'

import '../../styles/layout/toast.scss'

class Toast extends Component {

  state = {
    classType: 'info'
  }

  static propTypes = {
    // id: PropTypes.string.isRequired,
    className: PropTypes.string,
    toasts: PropTypes.array.isRequired,
    autohide: PropTypes.bool.isRequired,
  }

  componentWillReceiveProps(newProps) {
    if (newProps.toasts.length > 0) {
      const classType = newProps.toasts[0].type
      return this.setState({classType})
    }
  }

  handleDismissToast = () => {
    this.props.dismissToast()
  }

  render() {

    const { toasts, autohide } = this.props
    const { classType } = this.state

    return(
      <Snackbar
        id="application-toasts"
        className={classType}
        toasts={toasts}
        autohide={autohide}
        autohideTimeout={3000}
        onDismiss={this.handleDismissToast}
      />
    )

  }

}

const mapStateToProps = state => ({
  toasts: state.toast.toasts,
  autohide: state.toast.autohide,
})

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators({
    dismissToast
  }, dispatch)
})

export default compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(Toast)
