import {
  REQUEST_ALL_VENDEDORES, RECEIVE_ALL_VENDEDORES, REQUEST_VENDEDORES, RECEIVE_VENDEDORES, REQUEST_VENDEDOR,
  RECEIVE_VENDEDOR, REQUEST_SAVE_VENDEDOR, RECEIVE_SAVE_VENDEDOR, REQUEST_NOTAS_SERVICOS_VENDEDOR,
  RECEIVE_NOTAS_SERVICOS_VENDEDOR, REQUEST_SAVE_NOTA_SERVICOS_VENDEDOR, RECEIVE_SAVE_NOTA_SERVICOS_VENDEDOR,
  REQUEST_REMOVE_NOTA_SERVICOS_VENDEDOR, RECEIVE_REMOVE_NOTA_SERVICOS_VENDEDOR, RESET_ALL_VENDEDOR, RESET_VENDEDOR
} from '../constants/ActionTypes'


const INITIAL_STATE = {
  listAll: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  notasServicos: {
    isLoading: false,
    data: [],
    receivedAt: null,
  },
  saveNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
  removeNotaServicos: {
    isLoading: false,
    receivedAt: null,
  },
}

export default function VendedorReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_VENDEDORES.type:
      return {
        ...state,
        listAll: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_ALL_VENDEDORES.type:
      return {
        ...state,
        listAll: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_VENDEDORES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_VENDEDORES.type:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_VENDEDOR.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_VENDEDOR.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
      case REQUEST_SAVE_VENDEDOR.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: state.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case RECEIVE_SAVE_VENDEDOR.type:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          // data: action.data,
          data: INITIAL_STATE.item.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_NOTAS_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.notasServicos.data,
          receivedAt: INITIAL_STATE.notasServicos.receivedAt,
        }
      }
    case RECEIVE_NOTAS_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        notasServicos: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_NOTA_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.saveNotaServicos.receivedAt,
        }
      }
    case RECEIVE_SAVE_NOTA_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        saveNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_REMOVE_NOTA_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: INITIAL_STATE.removeNotaServicos.receivedAt,
        }
      }
    case RECEIVE_REMOVE_NOTA_SERVICOS_VENDEDOR.type:
      return {
        ...state,
        removeNotaServicos: {
          isLoading: action.isLoading,
          receivedAt: action.receivedAt,
        }
      }
    case RESET_VENDEDOR.type:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_VENDEDOR.type:
      return INITIAL_STATE
    default:
      return state
  }
}
