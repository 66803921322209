// firebaseConfig.js
// import firebase from 'firebase/app'
// import 'firebase/analytics'
// @see https://firebase.google.com/docs/cloud-messaging/js/receive?hl=pt-br
// @see https://medium.com/simform-engineering/firebase-cloud-messaging-in-react-a-comprehensive-guide-b5e325452f97

import { initializeApp } from "firebase/app";
import { getMessaging, onMessage } from "firebase/messaging"
// import { onBackgroundMessage } from "firebase/messaging/sw"
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBNYEWXZbCKTfQhZCGFHwpnZScVuyhNJx8",
    authDomain: "medic-systems.firebaseapp.com",
    projectId: "medic-systems",
    storageBucket: "medic-systems.appspot.com",
    messagingSenderId: "689299263317",
    appId: "1:689299263317:web:89bfcc15bd601b600169dc",
    measurementId: "G-K56FBBV3FZ"
}

// inicia Firebase
const app = initializeApp(firebaseConfig)

// Retrieve an instance of Firebase Messaging so that it can handle background
// messages.
// Messaging service
// const messaging = app.messaging()
const messaging = getMessaging(app)

// Está é a versão atiga que não funciona mais
/*messaging.setBackgroundMessageHandler(function(payload) {
  console.log('[firebase] Received background message ', payload)
  // Customize notification here
  var notificationData = JSON.parse(payload.data.notification);
  var notificationOptions = {
    body: notificationData.body,
    icon: 'images/icon-144x144.png',
    vibrate: [200, 100, 200, 100],
    actions: [
      {action: "dismiss", title: "Fechar"},
      {action: "open_url", title: "Ver exames"}
    ]
  };

  return self.registration.showNotification(notificationData.title, notificationOptions)
})*/

// TODO: apenas no contexto de servicevorker (ver como fazer)
/*onBackgroundMessage(messaging, (payload) => {
  console.log('[firebase] Received background message ', payload);
  var notificationData = JSON.parse(payload.data.notification)
  // Customize notification here
  const notificationTitle = 'Telemedicina';
  const notificationOptions = {
    body: notificationData.body,
    icon: 'images/icon-144x144.png',
    vibrate: [200, 100, 200, 100],
    actions: [
      {action: "dismiss", title: "Fechar"},
      {action: "open_url", title: "Ver exames"}
    ]
  }

  self.registration.showNotification(notificationTitle,
    notificationOptions);
})*/

self.addEventListener('notificationclick', function(event) {
  switch(event.action){
    case 'open_url':
      clients.openWindow("https://app.medicsystems.com.br/exame/disponiveis")
      event.notification.close()
      break;
    case 'dismiss':
      event.notification.close()
      break
  }
}, false)

export default app

// Initialize Firebase Analytics
// export const analytics = firebase.analytics()
export const analytics = getAnalytics(app)