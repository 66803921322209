import {
  REQUEST_FILTERS, RECEIVE_FILTERS, REQUEST_ADD_FILTER, RECEIVE_ADD_FILTER, REQUEST_REMOVE_FILTER,
  RECEIVE_REMOVE_FILTER, RESET_ALL_FILTER
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  list: {
    isLoading: false,
    data: [],
    entity: null,
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
  remove: {
    isLoading: false,
  },
}

export default function FilterReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_FILTERS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
          entity: INITIAL_STATE.list.entity,
        }
      }
    case RECEIVE_FILTERS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          entity: action.entity,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_ADD_FILTER:
    case RECEIVE_ADD_FILTER:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case REQUEST_REMOVE_FILTER:
    case RECEIVE_REMOVE_FILTER:
      return {
        ...state,
        remove: {
          isLoading: action.isLoading,
        }
      }
    case RESET_ALL_FILTER:
      return INITIAL_STATE
    default:
      return state
  }
}
