import {
  REQUEST_ALL_EQUIPES, RECEIVE_ALL_EQUIPES, REQUEST_EQUIPES, RECEIVE_EQUIPES, REQUEST_EQUIPE, RECEIVE_EQUIPE,
  REQUEST_SAVE_EQUIPE, RECEIVE_SAVE_EQUIPE, RESET_EQUIPE, RESET_ALL_EQUIPE
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
    },
    receivedAt: null,
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
}

export default function PlantaoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_EQUIPES:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_EQUIPES:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_EQUIPES:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_EQUIPE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_EQUIPE:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_EQUIPE:
    case RECEIVE_SAVE_EQUIPE:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case RESET_EQUIPE:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_EQUIPE:
      return INITIAL_STATE
    default:
      return state
  }
}
