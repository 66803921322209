import {
  REQUEST_ALL_PROCEDIMENTOS, RECEIVE_ALL_PROCEDIMENTOS, REQUEST_PROCEDIMENTOS, RECEIVE_PROCEDIMENTOS,
  REQUEST_PROCEDIMENTO, RECEIVE_PROCEDIMENTO, REQUEST_SAVE_PROCEDIMENTO, RECEIVE_SAVE_PROCEDIMENTO, RESET_PROCEDIMENTO,
  RESET_ALL_PROCEDIMENTO
} from '../constants/ActionTypes'

const INITIAL_STATE = {
  all: {
    isLoading: false,
    data: {
      rows: [],
    },
  },
  list: {
    isLoading: false,
    data: {
      rows: [],
      count: 0,
      page: 1,
      limit: 50,
    },
    receivedAt: null,
  },
  item: {
    isLoading: false,
    data: {},
    receivedAt: null,
  },
  save: {
    isLoading: false,
  },
}

export default function ProcedimentoReducer(state = INITIAL_STATE, action) {
  switch(action.type) {
    case REQUEST_ALL_PROCEDIMENTOS:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.all.data,
        }
      }
    case RECEIVE_ALL_PROCEDIMENTOS:
      return {
        ...state,
        all: {
          isLoading: action.isLoading,
          data: action.data || INITIAL_STATE.all.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PROCEDIMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.list.data,
        }
      }
    case RECEIVE_PROCEDIMENTOS:
      return {
        ...state,
        list: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_PROCEDIMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: INITIAL_STATE.item.data,
        }
      }
    case RECEIVE_PROCEDIMENTO:
      return {
        ...state,
        item: {
          isLoading: action.isLoading,
          data: action.data,
          receivedAt: action.receivedAt,
        }
      }
    case REQUEST_SAVE_PROCEDIMENTO:
    case RECEIVE_SAVE_PROCEDIMENTO:
      return {
        ...state,
        save: {
          isLoading: action.isLoading,
        }
      }
    case RESET_PROCEDIMENTO:
      return {
        ...state,
        item: INITIAL_STATE.item,
      }
    case RESET_ALL_PROCEDIMENTO:
      return INITIAL_STATE
    default:
      return state
  }
}
