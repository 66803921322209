//third party
import React from 'react'
import CircularProgress from 'react-md/lib/Progress/CircularProgress'
import Button from 'react-md/lib/Buttons/Button'
// components
import PageWrapper from '../widgets/PageWrapper'
import { RefreshIcon } from '../icons/IconSet'

window.countReload = 10
window.intervalReload = null

const LoadingPage = (props) => {

  if (props.error) {
    if (window.intervalReload === null) {
      window.intervalReload = setInterval(() => {
        var el = document.getElementById('timeCount')
        window.countReload--
        if (el) el.innerHTML = window.countReload + ''
        if (window.countReload === 0) {
          clearInterval(window.intervalReload)
          window.location.reload(true)
        }
      }, 1000)
    }

    return (
      <div>
        <h2>Ops, ocorreu um erro!</h2>
        <h4>{props.error.message}</h4>
        <div>{props.error.stack}</div>
        <br/>
        <h3 className="md-text-center">A página será recarregada em <span id="timeCount">10</span> para tentar corrigir o erro...</h3>
        <div className="md-text-center">
          <Button
            raised
            primary
            iconEl={<RefreshIcon/>}
            onClick={() => window.location.reload(true)}
          >
            Recarregar agora
          </Button>
        </div>
      </div>
    )
  } else {
    return (
    <PageWrapper id={props.id || 'loading-page'}>
      <div style={styles.wrapper}>
        <CircularProgress id="circular-progress" />
        {props.label && <p>{props.label}</p>}
      </div>
    </PageWrapper>
    )
  }
}

const styles = {
  wrapper: {
    textAlign: 'center',
    padding: '60px 0',
  }
}

export default LoadingPage
